/* eslint-disable @typescript-eslint/no-misused-promises */
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useTransformLayoutData } from '@kpi/pages/Dashboard/hooks';

import { CircularProgress } from '@mui/material';

import {
  CustomDashboard,
  DashboardComponentWrapper,
  RemoveItemModal,
} from '@core/components';
import { Widget } from '@core/modules/DashbordModule/components/widgets/Widget.tsx';
import { useDashboardContext } from '@core/modules/DashbordModule/contexts/DashboardModule.context.tsx';
import { EmptyDashboard } from '@core/modules/DashbordModule/views/EmptyDashboard.tsx';
import { FlexCenter } from '@core/styles';

export const Dashboard = () => {
  const { t } = useTranslation('charts');

  const {
    isLoading,
    dashboard,
    removeModalContent,
    isRemoveModalVisible,
    emptyDashboardComponent,
    removeItem,
    updateLayout,
    closeRemoveModal,
  } = useDashboardContext();

  const { layouts } = useTransformLayoutData(dashboard?.widgets || []);

  if (isLoading) {
    return (
      <FlexCenter sx={{ width: '100%', height: '100%', minHeight: 300 }}>
        <CircularProgress />
      </FlexCenter>
    );
  }

  if (!dashboard || dashboard.widgets.length === 0) {
    if (!emptyDashboardComponent) {
      return <EmptyDashboard />;
    }
    return emptyDashboardComponent as ReactNode;
  }

  return (
    <>
      <CustomDashboard
        onLayoutChange={(newLayoutAlignment) => {
          void updateLayout(newLayoutAlignment);
        }}
        layouts={{
          lg: layouts,
          md: layouts,
          sm: layouts,
          xs: layouts,
        }}
      >
        {dashboard.widgets.map((widget) => {
          return (
            <DashboardComponentWrapper key={widget.layoutKey} widget={widget}>
              <Widget {...widget} />
            </DashboardComponentWrapper>
          );
        })}
      </CustomDashboard>
      <RemoveItemModal
        headerText={t('removeChartTitle')}
        display={isRemoveModalVisible}
        content={removeModalContent}
        onRemove={removeItem}
        closeModal={closeRemoveModal}
      />
    </>
  );
};
