import { Typography, useTheme } from '@mui/material';

import { Button } from '@core/components';

import { TextButtonProps } from './TextButton.types';

export const TextButton = ({
  text,
  onClick,
  width = '100%',
  type = 'button',
}: TextButtonProps) => {
  const theme = useTheme();
  return (
    <Button sx={{ width }} type={type} variant="text" onClick={onClick}>
      <Typography
        variant="bodyMedium"
        sx={{
          fontSize: '14px',
          color: theme.palette.text.primary,
        }}
      >
        {text}
      </Typography>
    </Button>
  );
};
