import { Link, NavLink } from 'react-router-dom';

import { Box, styled } from '@mui/material';

export const NavbarMainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 24px 4px 8px',
  borderBottom: '1px solid var(--neutrals-gray-200, #dcdfe1)',
  background: '#fff',
});

export const NavbarUserMenu = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  columnGap: 12,
  alignItems: 'center',
  justifyContent: 'center',
});

export const NavbarTitle = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
  width: 'auto',
  columnGap: 8,
});

export const NavbarLinks = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

export const NavbarNavigation = styled('div')({
  marginLeft: 48,
  display: 'flex',
  columnGap: 32,
});

export const NavbarApps = styled(Link)({
  width: 300,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 8,
});

export const NavbarNavLink = styled(NavLink)(({ theme }) => ({
  color: `var(--text-text-primary, ${theme.palette.text.primary})`,
  fontFamily: 'Inter',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  textDecoration: 'none',
  borderRadius: 'var(--radius-basic, 8px)',
  display: 'flex',
  padding: '6px 12px',
  alignItems: 'center',
  gap: '4px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.active': {
    background: `var(--primary-primary-50, ${theme.palette.primary[50]})`,
  },
}));
