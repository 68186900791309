/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next';

import { CircularProgress, Stack, Typography, styled } from '@mui/material';

import { Button, TextButton } from '@core/components';

import { theme } from '@/theme';

import { useSingleKpiReportContext } from './context';

export const ButtonSection = () => {
  const { t } = useTranslation('kpi');
  const { t: commonT } = useTranslation();

  const { onSubmit, close, isUpdating, isValid } = useSingleKpiReportContext();

  return (
    <CustomButtonsContainer>
      <TextButton
        text={commonT('common.cancel')}
        width="auto"
        onClick={close}
      />
      <Button
        variant="contained"
        onClick={() => !isUpdating && onSubmit()}
        disabled={!isValid || isUpdating}
      >
        {isUpdating ? (
          <CircularProgress
            sx={{ color: theme.palette.common.white }}
            size="20px"
          />
        ) : (
          <Typography>{t('report.title')}</Typography>
        )}
      </Button>
    </CustomButtonsContainer>
  );
};

const CustomButtonsContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '12px',
});
