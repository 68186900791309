import { Outlet } from 'react-router-dom';

import { KaizenNavbar } from '@kaizen/components';

import { KaizenSettlementPeriodProvider } from '../contexts';

export const KaizenLayout = () => {
  return (
    <KaizenSettlementPeriodProvider>
      <div style={{ height: '100vh' }}>
        <KaizenNavbar />

        <Outlet />
      </div>
    </KaizenSettlementPeriodProvider>
  );
};
