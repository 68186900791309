import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Fade, IconButton } from '@mui/material';

import { AvatarMenu, LinkToOrganizationSettings } from '@core/components';
import { NotificationsComponent } from '@core/modules/Notifications/components/NotificationsComponent';
import { CustomTooltip, NavbarUserMenu } from '@core/styles';

import { useOrganizationStore, useUserStore } from '@/stores';
import { theme } from '@/theme';
import { userIsPermitted } from '@/utils';

import { NavbarUserMenuComponentProps } from './NavbarUserMenuComponent.type';

export const NavbarUserMenuComponent = ({
  additionalButtons,
}: NavbarUserMenuComponentProps) => {
  const { t } = useTranslation();

  const params = useParams();

  const { organization } = useOrganizationStore();
  const { userGroups, organizationId } = useUserStore();
  const { companyName } = useParams();

  return (
    <NavbarUserMenu>
      {additionalButtons}
      <CustomTooltip
        title={t('notification.title')}
        placement="bottom-start"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
      >
        <NotificationsComponent />
      </CustomTooltip>
      {userIsPermitted(
        userGroups,
        organizationId!,
        organization[companyName!],
      ) && (
        <LinkToOrganizationSettings
          organizationName={params.companyName ? params.companyName : ''}
        >
          <CustomTooltip
            title={t('organizationSettings.organizationSettings')}
            placement="bottom-start"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <IconButton>
              <SettingsOutlinedIcon
                sx={{
                  color: theme.palette.grey[900],
                }}
              />
            </IconButton>
          </CustomTooltip>
        </LinkToOrganizationSettings>
      )}
      <AvatarMenu />
    </NavbarUserMenu>
  );
};
