import { TFunction } from 'i18next';

import { ChartTypes } from '@/__generated__/graphql';

import { PickColor } from '.';
import { ExtractChartKeyType } from '../types';
import { FormatPieChartDataType } from '../types/FormatPieChartData.type';

export const FormatPieChartSingleDataWithTarget = (
  props: ExtractChartKeyType,
  selectedMonth: number | undefined,
  isPercent: boolean,
  getMonthName: (month?: string | number | undefined) => string | number,
  t: TFunction<'kpi', undefined>,
): FormatPieChartDataType[] => {
  if (!selectedMonth) return [];
  const monthData = getMonthData(props, selectedMonth + 1);

  if (!monthData) return [];

  const summary = { target: 0, value: 0 };

  for (const key in monthData) {
    if (key === 'name' || key === 'target') {
      continue;
    }

    if (monthData[key].target) summary.target = monthData[key].target!;
    summary.value = monthData[key].value;
  }

  if (isPercent) {
    return returnValue(
      { target: 100 - summary.value, value: summary.value },
      t,
      getMonthName,
      selectedMonth,
      100,
    );
  }

  const tooltipValue = summary.target;

  if (summary.target > summary.value) {
    summary.target = summary.target - summary.value;
  } else {
    summary.target = 0;
  }

  if (!summary.target) {
    return [
      {
        name: `${getMonthName(selectedMonth + 1)}`,
        fill: PickColor.pickColor([], ChartTypes.PieChart),
        value: summary.target,
      },
    ];
  }

  return returnValue(summary, t, getMonthName, selectedMonth, tooltipValue);
};

function getMonthData(data: ExtractChartKeyType, selectedMonth: number) {
  return data.filter((entry) => +entry.name === selectedMonth)[0];
}

const returnValue = (
  summary: { target: number; value: number },
  t: TFunction<'kpi', undefined>,
  getMonthName: (month?: string | number | undefined) => string | number,
  selectedMonth: number,
  tooltipValue?: number,
) => {
  const usedColors: string[] = [];

  return [
    {
      name: `${getMonthName(selectedMonth + 1)}`,
      fill: PickColor.pickColor(usedColors, ChartTypes.PieChart),
      value: summary.value,
    },
    {
      name: `${t('charts.target')} - ${getMonthName(selectedMonth + 1)}`,
      fill: PickColor.pickColor(usedColors, ChartTypes.PieChart),
      value: summary.target,
      tooltipValue,
    },
  ];
};
