/* eslint-disable @typescript-eslint/no-misused-promises */
import { useTranslation } from 'react-i18next';

import { useCreateChartContext } from '@kpi/modals/HandleKpiChartModal/context';
import { useDashboardPermissions } from '@kpi/pages/Dashboard/hooks/useDashboardPermissions.tsx';

import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { Divider } from '@mui/material';

import { MenuitemWithSwitch } from '@core/components';
import { CustomMenuItem } from '@core/components/CustomMenuItem';
import { MenuItem } from '@core/components/MenuItem';
import { useDashboardContext } from '@core/modules/DashbordModule/contexts/DashboardModule.context.tsx';
import { useKpiChartMenu } from '@core/modules/DashbordModule/hooks/MenuHooks';
import { DashboardWidgetContentType } from '@core/modules/DashbordModule/types';

import { ChartFragment, DashboardWidgetDto } from '@/__generated__/graphql.ts';

export const KpiChartMenu = ({
  widgetContent,
  dashboardWidgetData,
  closeMenu,
}: {
  closeMenu: () => void;
  widgetContent: DashboardWidgetContentType;
  dashboardWidgetData: DashboardWidgetDto;
}) => {
  const { t } = useTranslation('kpi');

  const {
    dashboard,
    dashboardType,
    openRemoveModal,
    changeWidgetVisibility,
    settlementPeriodId,
  } = useDashboardContext();

  const { updateKpiChart } = useCreateChartContext();

  const {
    isDetailLegend,
    isDataLabelVisible,
    isDataLabelDisabled,
    isDetailedLegendDisabled,
    toggleDataLabel,
    toggleDetailLegend,
    pinToDashboard,
  } = useKpiChartMenu(widgetContent as ChartFragment);

  const { canPinToDashboard } = useDashboardPermissions(dashboardType);

  return (
    <>
      {canPinToDashboard && (
        <CustomMenuItem
          disabled={!settlementPeriodId}
          onClick={async () => {
            closeMenu();
            await pinToDashboard(settlementPeriodId as string);
          }}
          icon={<PushPinOutlinedIcon />}
          text={t('charts.pinToOrgDashboard')}
        />
      )}

      <MenuItem
        variant={'hide'}
        onClick={() =>
          void changeWidgetVisibility(dashboardWidgetData.layoutKey, true)
        }
      ></MenuItem>
      <MenuItem
        variant={'edit'}
        onClick={async () => {
          closeMenu();
          await updateKpiChart(dashboard!.id, widgetContent as ChartFragment);
        }}
      ></MenuItem>
      <MenuItem
        variant={'delete'}
        onClick={() => {
          closeMenu();
          openRemoveModal(
            dashboardWidgetData.layoutKey,
            widgetContent!.chartName,
          );
        }}
      />
      <Divider />
      <MenuitemWithSwitch
        label={t('charts.dataLabel')}
        checked={isDataLabelVisible}
        disabled={isDataLabelDisabled}
        onChange={() => {
          void toggleDataLabel();
        }}
      />
      <MenuitemWithSwitch
        label={t('charts.isDetailLegend')}
        checked={isDetailLegend}
        disabled={isDetailedLegendDisabled}
        onChange={() => {
          void toggleDetailLegend();
        }}
      />
    </>
  );
};
