import { Trans, useTranslation } from 'react-i18next';

import {
  Checkbox,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { RemoveItemModal } from '@/core/components';
import { useCheckUserPermission } from '@/core/hooks';

import { useOrganizationModules } from '../hooks';

export const Modules = () => {
  const { t } = useTranslation();

  const checkIfUserHasPermission = useCheckUserPermission();

  const {
    modules,
    moduleToInactive,
    setModuleToInactive,
    handleCheckboxClick,
    handleUpdateModules,
    isLoading,
    allowedModules,
  } = useOrganizationModules();

  return (
    <>
      <RemoveItemModal
        headerText={t('organizationSettings.removeModuleTitle')}
        content={
          <Trans
            i18nKey="organizationSettings.areYouSureRemoveModule"
            values={{
              moduleName: t(`organizationModules.${moduleToInactive!}`),
            }}
            components={{ strong: <strong /> }}
          />
        }
        onRemove={() => {
          handleUpdateModules(
            modules.filter((module) => module !== moduleToInactive),
          );
          setModuleToInactive(undefined);
        }}
        closeModal={() => setModuleToInactive(undefined)}
        display={!!moduleToInactive}
      />
      <Typography variant="h3">{t('organization.modules')}</Typography>
      <TableContainer sx={{ width: '768px' }}>
        {isLoading ? (
          <Skeleton width={'100%'} height={300} />
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell height={'40px'}>
                  <Typography variant="bodyRegular" fontWeight={500}>
                    {t('organization.moduleName')}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allowedModules.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="left" height="48px" width={'48px'}>
                    <Tooltip
                      title={
                        !row.isActive &&
                        t('organizationSettings.disabledModule')
                      }
                    >
                      <Checkbox
                        onClick={() => handleCheckboxClick(row.name)}
                        color="default"
                        checked={!!row.isActive}
                        disabled={!checkIfUserHasPermission()}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left">
                    <Stack display="flex" flexDirection="row">
                      <img src={row.iconSrc} alt="logo"></img>
                      &nbsp;
                      <Typography variant="bodyRegular">
                        {t(`organizationModules.${row.name}`)}
                      </Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </>
  );
};
