import { useNavigate, useParams } from 'react-router-dom';

export const useGlobalRedirects = () => {
  const navigate = useNavigate();
  const { companyName, flowId } = useParams();
  const modulesDashboardPath = `/${companyName}`;
  const eSchemaPath = `/${companyName}/e-schema`;
  const eFlowPath = `/${companyName}/e-flow`;
  const kaizenPath = `/${companyName}/kaizen/submissions`;
  const aiPath = `/${companyName}/AI`;
  const kpiPath = `/${companyName}/kpi/dashboard`;

  const createNewAnalysisPath = `${eFlowPath}/create-analysis`;

  const returnToModulesDashboard = () => navigate(modulesDashboardPath);
  const openAccountSettings = () => navigate(`/users/me`);
  const openLoggedOut = () => navigate('/logged-out');
  const returnToThisFlow = () => navigate(`${eFlowPath}/${flowId}`);

  return {
    kaizenPath,
    aiPath,
    eFlowPath,
    eSchemaPath,
    modulesDashboardPath,
    returnToModulesDashboard,
    openAccountSettings,
    openLoggedOut,
    createNewAnalysisPath,
    returnToThisFlow,
    kpiPath,
  };
};
