import { HandleChartProvider } from '@kpi/modals/HandleKpiChartModal/context';

import { Stack, styled } from '@mui/material';

import { DashboardTabs } from './Dashboard/DashboardTabs';

export const Dashboard = () => {
  return (
    <HandleChartProvider>
      <Container>
        <DashboardTabs />
      </Container>
    </HandleChartProvider>
  );
};

const Container = styled(Stack)({
  gap: '24px',
  padding: '24px',
});
