import { useTranslation } from 'react-i18next';

import { Box, Divider } from '@mui/material';
import { styled } from '@mui/styles';

import { useRechartVerticalTooltip } from '@core/components/Statistica/Tooltips/RechartBarChartVerticalTooltip/useRechartCustomTooltip.tsx';
import { useGetMonthName } from '@core/hooks';

import { RechartVerticalBarChartType } from './RechartVerticalBarChartType.ts';

export const RechartVerticalBarchartCustomTooltip = (
  props: RechartVerticalBarChartType,
) => {
  const { t } = useTranslation('charts');

  const { active: isActive, payload, label } = props;
  const { getMonthName } = useGetMonthName();

  const { getLegendInfo, formatTextWithPercentLabel } =
    useRechartVerticalTooltip(props);

  if (!isActive || !payload) {
    return null;
  }

  return (
    <LegendContainer>
      <Box>{getMonthName(label)}</Box>
      <Divider />
      <Box>
        {getLegendInfo().map((entry, index) => (
          <Box key={index} sx={{ color: entry.fill }}>
            {formatTextWithPercentLabel(entry.name)}:{' '}
            {formatTextWithPercentLabel(entry.value)}
            {entry.valueTarget
              ? t('chartTooltip.target', {
                  target: formatTextWithPercentLabel(entry.valueTarget),
                })
              : ''}
          </Box>
        ))}
      </Box>
    </LegendContainer>
  );
};

const LegendContainer = styled(Box)({
  backgroundColor: 'white',
  padding: '10px',
});
