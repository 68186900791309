import { Route, Routes } from 'react-router-dom';

import { AddKpiRateProvider } from '@kpi/contexts';
import { KpiLayout } from '@kpi/layouts';
import { Dashboard, Indicators } from '@kpi/pages';
import { ProtectedRoute } from '@router/index';

import { SystemRoles } from '@/__generated__/graphql.ts';

export const KpiRoutes = () => {
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            allowedGroups={[
              SystemRoles.Root,
              SystemRoles.SystemAdmin,
              SystemRoles.KeyUser,
              SystemRoles.Administrator,
              SystemRoles.KpiMember,
            ]}
          >
            <KpiLayout />
          </ProtectedRoute>
        }
      >
        <Route path="dashboard" element={<Dashboard />} />
        <Route
          path="indicators"
          element={
            <AddKpiRateProvider>
              <Indicators />
            </AddKpiRateProvider>
          }
        />
      </Route>
    </Routes>
  );
};
