import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AddCommissionMemberInput = {
  employeeId: Scalars['String']['input'];
  isCommisionLeader: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
};

export type AddEflowCommentInput = {
  commentCategory?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  createdByModel: CreatedByModelInput;
  organizationId: Scalars['String']['input'];
  showInReport: Scalars['Boolean']['input'];
};

export type AddSubmissionToRealisationInput = {
  assigneeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  submissionId: Scalars['String']['input'];
};

export type AnalizeLeaderInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export type AnalizeLeaderModel = {
  __typename?: 'AnalizeLeaderModel';
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
};

export enum AsIsToBeNamesEnum {
  AsIs = 'AS_IS',
  ToBe = 'TO_BE'
}

export type ChangeKaizenSubmissionRootStatusInput = {
  organizationId: Scalars['String']['input'];
  status: KaizenStatuses;
  submissionId: Scalars['String']['input'];
};

export type ChangeStepPositionInput = {
  analizeType: AsIsToBeNamesEnum;
  desiredPosition: Scalars['Int']['input'];
  eFlowId: Scalars['String']['input'];
  eFlowStepId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type ChangeSubmissionStatusInput = {
  organizationId: Scalars['String']['input'];
  submissionId: Scalars['String']['input'];
};

export enum ChartDataSetType {
  KpiSet = 'KPI_SET'
}

export type ChartDisplayOptionsInput = {
  legendType: ChartLegendType;
  selectedMonths?: InputMaybe<Array<Scalars['Float']['input']>>;
  showDataAsPercent?: InputMaybe<Scalars['Boolean']['input']>;
  showDataInAscendingOrder?: InputMaybe<Scalars['Boolean']['input']>;
  showDataLabels?: InputMaybe<Scalars['Boolean']['input']>;
  showDetailedLegend?: InputMaybe<Scalars['Boolean']['input']>;
  showTarget?: InputMaybe<Scalars['Boolean']['input']>;
  sumIndicators?: InputMaybe<RateSumTypes>;
};

export type ChartDto = {
  __typename?: 'ChartDto';
  additionalInfo: Scalars['JSON']['output'];
  chartName: Scalars['String']['output'];
  chartType: ChartTypes;
  dataSet: Scalars['JSON']['output'];
  dataSourceSet: Array<Scalars['String']['output']>;
  datasetType: ChartDataSetType;
  displayOptions: ChartsDisplayOptionsModels;
  id: Scalars['String']['output'];
  isError: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
};

export enum ChartLegendType {
  EmployeeLegend = 'EMPLOYEE_LEGEND',
  MonthLegend = 'MONTH_LEGEND',
  NoLegend = 'NO_LEGEND',
  OrganizationUnitLegend = 'ORGANIZATION_UNIT_LEGEND',
  VariableLegend = 'VARIABLE_LEGEND'
}

export enum ChartTypes {
  BarChart = 'BAR_CHART',
  LineChart = 'LINE_CHART',
  PieChart = 'PIE_CHART'
}

export type ChartsDisplayOptionsModels = {
  __typename?: 'ChartsDisplayOptionsModels';
  legendType: ChartLegendType;
  showDataAsPercent?: Maybe<Scalars['Boolean']['output']>;
  showDataInAscendingOrder?: Maybe<Scalars['Boolean']['output']>;
  showDataLabels?: Maybe<Scalars['Boolean']['output']>;
  showDetailedLegend?: Maybe<Scalars['Boolean']['output']>;
  showTarget?: Maybe<Scalars['Boolean']['output']>;
  sumIndicators?: Maybe<RateSumTypes>;
};

export type CloneEFlowInput = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type CloneEflowStepsInput = {
  cloneWholeAnalysis?: InputMaybe<Scalars['Boolean']['input']>;
  desiredEflowStartPosition?: InputMaybe<Scalars['Int']['input']>;
  eFlowId: Scalars['String']['input'];
  eFlowStepsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationId: Scalars['String']['input'];
  propertiesToOmit?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CommentDto = {
  __typename?: 'CommentDto';
  commentCategory?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Employee;
  id: Scalars['String']['output'];
};

export type CommissionMemberDto = {
  __typename?: 'CommissionMemberDto';
  commissionRole: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  organizationUnit: OrganizationUnit;
  organizationUnitGuid: Scalars['String']['output'];
  organizationUrl: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  position: Scalars['String']['output'];
  profilePicture?: Maybe<Scalars['String']['output']>;
  state: EmployeeState;
  systemRole: Array<SystemRoles>;
};

export type CommissionMembers = {
  __typename?: 'CommissionMembers';
  items: Array<CommissionMemberDto>;
  total: Scalars['Float']['output'];
};

export type CreateByModel = {
  __typename?: 'CreateByModel';
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type CreateCommentInput = {
  commentCategory?: InputMaybe<Scalars['String']['input']>;
  content: Scalars['String']['input'];
  createdByModel: CreatedByModelInput;
  organizationId: Scalars['String']['input'];
};

export type CreateDashboardInput = {
  employeeId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  organizationUnitId?: InputMaybe<Scalars['String']['input']>;
  settlementPeriodId?: InputMaybe<Scalars['String']['input']>;
  type: DashboardTypesEnum;
};

export type CreateEflowInput = {
  analyzeLeadersIds?: InputMaybe<Array<AnalizeLeaderInput>>;
  asIsState?: InputMaybe<Array<Scalars['String']['input']>>;
  commentTypes?: InputMaybe<Array<EflowCommentModelInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  operations?: InputMaybe<Array<OperationInput>>;
  organizationId: Scalars['String']['input'];
  parameters?: InputMaybe<Array<ParameterInput>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  toBeState?: InputMaybe<Array<Scalars['String']['input']>>;
  workGroupUsers: Array<WorkGroupInput>;
};

export type CreateEflowStepInput = {
  analizeType: AsIsToBeNamesEnum;
  comments?: InputMaybe<Array<AddEflowCommentInput>>;
  createdAt: Scalars['DateTime']['input'];
  eFlowId: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  operation: OperationInput;
  operationName: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
  stepNumber: Scalars['Int']['input'];
};

export type CreateEmployeeInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  organizationUnitGuid: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  position: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  systemRole?: Array<SystemRoles>;
};

export type CreateKaizenScoringInput = {
  organizationId: Scalars['String']['input'];
  scoring: Scalars['JSONObject']['input'];
};

export type CreateKaizenSubmissionInput = {
  coAuthors?: InputMaybe<Array<CreatedByModelInput>>;
  isDraft: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  organizationUnitId: Scalars['String']['input'];
  problem: KaizenSubmissionProblem;
  solution: KaizenSubmissionProblem;
};

export type CreateKpiChartInput = {
  chartName: Scalars['String']['input'];
  chartType: ChartTypes;
  dashboardId: Scalars['String']['input'];
  displayOptions: ChartDisplayOptionsInput;
  kpiIds: Array<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type CreateKpiRateInput = {
  dueToDateTimeout: Scalars['Float']['input'];
  hasTarget: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
  organizationUnitId: Scalars['String']['input'];
  rateDescription?: InputMaybe<Scalars['String']['input']>;
  rateName: Scalars['String']['input'];
  reporterId: Scalars['String']['input'];
  targetData?: InputMaybe<Scalars['JSON']['input']>;
  targetType?: InputMaybe<KpiRatingTargetType>;
  unit: UnitKpiRateInput;
};

export type CreateKpiSettlementPeriodInput = {
  cloneKpis?: InputMaybe<Scalars['Boolean']['input']>;
  isActive: Scalars['Boolean']['input'];
  newKpiSettlementPeriodYear: Scalars['Float']['input'];
  organizationId: Scalars['String']['input'];
  periodName: Scalars['String']['input'];
};

export type CreateOrganisationInput = {
  modules: Array<OrganizationModules>;
  name: Scalars['String']['input'];
  systemKeyUserUUID?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type CreateOrganizationUnitInput = {
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  parentGuid?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSettlementPeriodInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['String']['input'];
  periodName: Scalars['String']['input'];
};

export type CreatedByModelInput = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type DashboardDto = {
  __typename?: 'DashboardDto';
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  widgets: Array<DashboardWidgetDto>;
};

export enum DashboardTypesEnum {
  KpiEmployeeDashboard = 'KPI_EMPLOYEE_DASHBOARD',
  KpiOrganizationDashboard = 'KPI_ORGANIZATION_DASHBOARD',
  KpiOrganizationUnitDashboard = 'KPI_ORGANIZATION_UNIT_DASHBOARD'
}

export type DashboardWidgetDto = {
  __typename?: 'DashboardWidgetDto';
  isHidden: Scalars['Boolean']['output'];
  layoutKey: Scalars['String']['output'];
  layoutModel: Scalars['JSON']['output'];
  resourceLink: Scalars['JSON']['output'];
  widgetType: SupportedWidgetsEnum;
};

export type DeleteOrganizationInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export type DeleteOrganizationUnitChildren = {
  __typename?: 'DeleteOrganizationUnitChildren';
  employees: Scalars['Float']['output'];
  organizationUnits: Scalars['Float']['output'];
};

export type DeleteOrganizationUnitDto = {
  __typename?: 'DeleteOrganizationUnitDto';
  children?: Maybe<DeleteOrganizationUnitChildren>;
  success: Scalars['Boolean']['output'];
};

export type EFlow = {
  __typename?: 'EFlow';
  analyzeLeaderModel?: Maybe<Array<AnalizeLeaderModel>>;
  analyzeLeaders?: Maybe<Array<Employee>>;
  asIsState?: Maybe<Array<Scalars['String']['output']>>;
  asIsSteps?: Maybe<Array<EflowStep>>;
  commentTypes?: Maybe<Array<EflowCommentModel>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Employee;
  createdByModel: CreateByModel;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  operations?: Maybe<Array<OperationSchema>>;
  organization: Organization;
  organizationId: Scalars['String']['output'];
  parameters?: Maybe<Array<ParameterObject>>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: EFlowStatuses;
  toBeState?: Maybe<Array<Scalars['String']['output']>>;
  toBeSteps?: Maybe<Array<Maybe<EflowStep>>>;
  updatedAt: Scalars['String']['output'];
  workGroupUsers: Array<WorkGroupModel>;
};

export type EFlowReport = {
  __typename?: 'EFlowReport';
  comments?: Maybe<Array<EflowReportCommentDto>>;
  eFlowId: Scalars['String']['output'];
  operationTypesSummary?: Maybe<Scalars['JSONObject']['output']>;
  operations: Scalars['JSONObject']['output'];
  organizationId: Scalars['String']['output'];
  parameters?: Maybe<Scalars['JSONObject']['output']>;
  updatedAt: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export enum EFlowStatuses {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW'
}

export type EFlowStepCommentDto = {
  __typename?: 'EFlowStepCommentDto';
  commentCategory?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  createdBy: Employee;
  id: Scalars['String']['output'];
  showInReport: Scalars['Boolean']['output'];
};

export type EflowCommentModel = {
  __typename?: 'EflowCommentModel';
  categoryName: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
};

export type EflowCommentModelInput = {
  categoryName: Scalars['String']['input'];
  symbol: Scalars['String']['input'];
};

export type EflowReportComment = {
  __typename?: 'EflowReportComment';
  commentId: Scalars['String']['output'];
  showInReport: Scalars['Boolean']['output'];
};

export type EflowReportCommentDto = {
  __typename?: 'EflowReportCommentDto';
  analizeType: AsIsToBeNamesEnum;
  comment: CommentDto;
  operation: OperationSchema;
  operationName: Scalars['String']['output'];
  stepNumber: Scalars['Float']['output'];
};

export type EflowStep = {
  __typename?: 'EflowStep';
  analizeType: AsIsToBeNamesEnum;
  commentModels?: Maybe<Array<EflowReportComment>>;
  comments?: Maybe<Array<EFlowStepCommentDto>>;
  createdAt: Scalars['String']['output'];
  createdBy: Employee;
  eFlowId: Scalars['String']['output'];
  files?: Maybe<Array<FileModel>>;
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  operation: OperationSchema;
  operationName: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  parameters?: Maybe<Scalars['JSONObject']['output']>;
};

export type EflowSubscription = {
  __typename?: 'EflowSubscription';
  analyzeLeaderModel?: Maybe<Array<AnalizeLeaderModel>>;
  analyzeLeaders?: Maybe<Array<Employee>>;
  asIsState?: Maybe<Array<Scalars['String']['output']>>;
  commentTypes?: Maybe<Array<EflowCommentModel>>;
  createdAt: Scalars['DateTime']['output'];
  createdBy: Employee;
  createdByModel: CreateByModel;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  operations?: Maybe<Array<OperationSchema>>;
  organization: Organization;
  organizationId: Scalars['String']['output'];
  parameters?: Maybe<Array<ParameterObject>>;
  startDate?: Maybe<Scalars['String']['output']>;
  status: EFlowStatuses;
  toBeState?: Maybe<Array<Scalars['String']['output']>>;
  updatedAt: Scalars['String']['output'];
  workGroupUsers: Array<WorkGroupModel>;
};

export type Employee = {
  __typename?: 'Employee';
  createdAt?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  organizationUnit: OrganizationUnit;
  organizationUnitGuid: Scalars['String']['output'];
  organizationUrl: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  position: Scalars['String']['output'];
  profilePicture?: Maybe<Scalars['String']['output']>;
  state: EmployeeState;
  systemRole: Array<SystemRoles>;
};

export type EmployeeInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  organizationUnit: OrganizationUnitInput;
  organizationUnitGuid: Scalars['String']['input'];
  organizationUrl: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  position: Scalars['String']['input'];
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  state: EmployeeState;
  systemRole?: Array<SystemRoles>;
};

export enum EmployeeState {
  Active = 'Active',
  Fired = 'Fired',
  Inactive = 'Inactive'
}

export type EmployeesListArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  employeeState?: InputMaybe<Array<EmployeeState>>;
  limit?: Scalars['Int']['input'];
  organisationId: Scalars['String']['input'];
  organisationUnitIds?: InputMaybe<Array<Scalars['String']['input']>>;
  positions?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  systemRoles?: InputMaybe<Array<SystemRoles>>;
};

export type FileModel = {
  __typename?: 'FileModel';
  createdAt: Scalars['String']['output'];
  createdBy: Employee;
  fileDescription?: Maybe<Scalars['String']['output']>;
  fileExtension: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type FindAllEFflows = {
  __typename?: 'FindAllEFflows';
  items: Array<EFlow>;
  total: Scalars['Float']['output'];
};

export type FindAllEFlowsInput = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  eFlowMembersIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<EFlowStatuses>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type FindAllEmployees = {
  __typename?: 'FindAllEmployees';
  items: Array<Employee>;
  total: Scalars['Float']['output'];
};

export type FindAllKaizenSubmissionsDto = {
  __typename?: 'FindAllKaizenSubmissionsDto';
  items: Array<KaizenSubmissionDto>;
  total: Scalars['Float']['output'];
};

export type FindAllKpiDto = {
  __typename?: 'FindAllKpiDto';
  cursor?: Maybe<Scalars['String']['output']>;
  items: Array<KpiRateDto>;
  total: Scalars['Float']['output'];
};

export type FindAllKpiSettlementPeriodsDto = {
  __typename?: 'FindAllKpiSettlementPeriodsDto';
  settlementPeriods: Array<KpiSettlementPeriodDto>;
};

export type FindAllKpiSettlementPeriodsInput = {
  organizationId: Scalars['String']['input'];
};

export type FindAllKpiUnitsInput = {
  organizationId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  settlementPeriodIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindAllNotificationsDto = {
  __typename?: 'FindAllNotificationsDto';
  items: Array<SingleNotificationDto>;
  total: Scalars['Float']['output'];
};

export type FindAllOrganizations = {
  __typename?: 'FindAllOrganizations';
  items: Array<Organization>;
  total: Scalars['Float']['output'];
};

export type FindAllOrganizationsInput = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  keyUserId?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};

export type FindAllSettlementPeriodsDto = {
  __typename?: 'FindAllSettlementPeriodsDto';
  organizationId: Scalars['String']['output'];
  settlementPeriods: Array<KaizenSettlementPeriodDto>;
};

export type FindAllSettlementPeriodsInput = {
  organizationId: Scalars['String']['input'];
  withArchive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FindAllSubUnitsStructureDto = {
  __typename?: 'FindAllSubUnitsStructureDto';
  rootUnit: SingleSubunitDto;
  subunits: Array<SingleSubunitDto>;
};

export type FindAllSubmissionsInput = {
  assignedToMe?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  settlementPeriodId: Array<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<KaizenStatuses>>;
  submissionParticipantsIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type FindAllUserNotificationsInput = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type FindChartInput = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type FindDashboardInput = {
  employeeId?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  organizationUnitId?: InputMaybe<Scalars['String']['input']>;
  settlementPeriodId?: InputMaybe<Scalars['String']['input']>;
  type: DashboardTypesEnum;
};

export type FindKaizenSubmissionInput = {
  organizationId: Scalars['String']['input'];
  submissionId: Scalars['String']['input'];
};

export type FindKpiModuleInfoInput = {
  organizationId: Scalars['String']['input'];
};

export type FindKpiRateInput = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  employeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: Scalars['Int']['input'];
  nameSearch?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrderBy>;
  orderBy?: InputMaybe<KpiRatingOrderBy>;
  organizationId: Scalars['String']['input'];
  organizationUnitIds: Array<Scalars['String']['input']>;
  settlementPeriodIds: Array<Scalars['String']['input']>;
  unitSearch?: InputMaybe<Scalars['String']['input']>;
};

export type FindKpiUnitsDto = {
  __typename?: 'FindKpiUnitsDto';
  units: Array<KpiUnitModelDto>;
};

export type FindManyKpisDto = {
  __typename?: 'FindManyKpisDto';
  items: Array<KpiRateDto>;
};

export type FindManyRateInput = {
  kpiRateIds: Array<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type FindModuleInfoInput = {
  organizationId: Scalars['String']['input'];
};

export type FindOneEFlowInput = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type FindOneEflowStepInput = {
  eFlowId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type FindOrganizationInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type FindOrganizationUnitSubunitsInput = {
  organizationId: Scalars['String']['input'];
  organizationUnitId?: InputMaybe<Scalars['String']['input']>;
};

export type FindRelatedResourcesInput = {
  kpiId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type FindReportInput = {
  eFlowId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  version: Scalars['Float']['input'];
};

export type FindSingleReportInput = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type GenerateEflowReportInput = {
  eFlowId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  version: Scalars['Float']['input'];
};

export type GetCommissionMemberInput = {
  organizationId: Scalars['String']['input'];
};

export type GetEmployee = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export type GetKaizenRanksDto = {
  __typename?: 'GetKaizenRanksDto';
  items: Array<KaizenRanksDto>;
  total: Scalars['Float']['output'];
};

export type GetKaizenRanksInput = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  settlementPeriodIds: Array<Scalars['String']['input']>;
};

export type GetKpiRateInput = {
  kpiId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type GetOrganizationUnitInput = {
  id: Scalars['ID']['input'];
  systemRoles?: InputMaybe<Array<SystemRoles>>;
};

export type GetSettlementPeriodSubmissionNumberDto = {
  __typename?: 'GetSettlementPeriodSubmissionNumberDto';
  total: Scalars['Float']['output'];
};

export type GetSettlementPeriodSubmissionNumberInput = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type GetSingleEmployeeDto = {
  __typename?: 'GetSingleEmployeeDto';
  createdAt?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  organizationUnit: OrganizationUnit;
  organizationUnitGuid: Scalars['String']['output'];
  organizationUnitPath?: Maybe<Scalars['JSON']['output']>;
  organizationUrl: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  position: Scalars['String']['output'];
  profilePicture?: Maybe<Scalars['String']['output']>;
  state: EmployeeState;
  systemRole: Array<SystemRoles>;
};

export type KaizenModule = {
  __typename?: 'KaizenModule';
  currentSettlementPeriod?: Maybe<KaizenSettlementPeriodDto>;
  organizationId: Scalars['String']['output'];
  scoringSet?: Maybe<KaizenScoring>;
  submissionFlow?: Maybe<KaizenSubmissionFlowDto>;
};

export type KaizenProblemDto = {
  __typename?: 'KaizenProblemDto';
  description: Scalars['String']['output'];
  files?: Maybe<Array<Maybe<FileModel>>>;
};

export type KaizenRanksDto = {
  __typename?: 'KaizenRanksDto';
  employee: Employee;
  submissionNumber: Scalars['Float']['output'];
  totalPoints: Scalars['Float']['output'];
};

export type KaizenScoring = {
  __typename?: 'KaizenScoring';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  scoring: Scalars['JSONObject']['output'];
  version: Scalars['Float']['output'];
};

export type KaizenSettlementPeriodDto = {
  __typename?: 'KaizenSettlementPeriodDto';
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
  periodName: Scalars['String']['output'];
};

export type KaizenSolutionDto = {
  __typename?: 'KaizenSolutionDto';
  description: Scalars['String']['output'];
  files?: Maybe<Array<Maybe<FileModel>>>;
};

export enum KaizenStatuses {
  Accepted = 'ACCEPTED',
  Adjustment = 'ADJUSTMENT',
  CommissionAssessment = 'COMMISSION_ASSESSMENT',
  Draft = 'DRAFT',
  ImplementationAccepted = 'IMPLEMENTATION_ACCEPTED',
  ImplementationRejected = 'IMPLEMENTATION_REJECTED',
  Implemented = 'IMPLEMENTED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  SupervisorAssessment = 'SUPERVISOR_ASSESSMENT',
  Todo = 'TODO'
}

export type KaizenSubmissionDto = {
  __typename?: 'KaizenSubmissionDto';
  assignedContractor?: Maybe<Employee>;
  assignedPoints: Scalars['Float']['output'];
  authors?: Maybe<Array<Employee>>;
  comments?: Maybe<Array<CommentDto>>;
  createdAt: Scalars['String']['output'];
  createdBy: Employee;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  organizationUnit: OrganizationUnit;
  organizationUnitId: Scalars['String']['output'];
  passedStatuses: Array<KaizenStatuses>;
  problem: KaizenProblemDto;
  processingTime: Scalars['Float']['output'];
  settlementPeriod?: Maybe<KaizenSettlementPeriodDto>;
  settlementPeriodId: Scalars['String']['output'];
  solution: KaizenSolutionDto;
  status: KaizenStatuses;
  submissionKey: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  version: Scalars['Float']['output'];
};

export type KaizenSubmissionFlowDto = {
  __typename?: 'KaizenSubmissionFlowDto';
  ACCEPTED: Array<KaizenStatuses>;
  ADJUSTMENT: Array<KaizenStatuses>;
  COMMISSION_ASSESSMENT: Array<KaizenStatuses>;
  DRAFT: Array<KaizenStatuses>;
  IMPLEMENTATION_ACCEPTED: Array<KaizenStatuses>;
  IMPLEMENTATION_REJECTED: Array<KaizenStatuses>;
  IMPLEMENTED: Array<KaizenStatuses>;
  IN_PROGRESS: Array<KaizenStatuses>;
  REJECTED: Array<KaizenStatuses>;
  SUPERVISOR_ASSESSMENT: Array<KaizenStatuses>;
  TODO: Array<KaizenStatuses>;
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type KaizenSubmissionNumberDto = {
  __typename?: 'KaizenSubmissionNumberDto';
  ACCEPTED: Scalars['Float']['output'];
  ADJUSTMENT: Scalars['Float']['output'];
  COMMISSION_ASSESSMENT: Scalars['Float']['output'];
  IMPLEMENTATION_ACCEPTED: Scalars['Float']['output'];
  IMPLEMENTATION_REJECTED: Scalars['Float']['output'];
  IMPLEMENTED: Scalars['Float']['output'];
  IN_PROGRESS: Scalars['Float']['output'];
  SUPERVISOR_ASSESSMENT: Scalars['Float']['output'];
  TODO: Scalars['Float']['output'];
};

export type KaizenSubmissionProblem = {
  description: Scalars['String']['input'];
};

export type KaizenSubmissionProcessingTimeDto = {
  __typename?: 'KaizenSubmissionProcessingTimeDto';
  ACCEPTED: Scalars['Float']['output'];
  ADJUSTMENT: Scalars['Float']['output'];
  COMMISSION_ASSESSMENT: Scalars['Float']['output'];
  IMPLEMENTATION_REJECTED: Scalars['Float']['output'];
  IN_PROGRESS: Scalars['Float']['output'];
  SUPERVISOR_ASSESSMENT: Scalars['Float']['output'];
  TODO: Scalars['Float']['output'];
};

export type KaizenSubmissionReportDto = {
  __typename?: 'KaizenSubmissionReportDto';
  avgProcessingTime: Scalars['Float']['output'];
  organizationId: Scalars['String']['output'];
  submissionProcessingTime: KaizenSubmissionProcessingTimeDto;
  submissionStatusCount: KaizenSubmissionNumberDto;
  totalNumberOfSubmissions: Scalars['Float']['output'];
  totalParticipantsNumber: Scalars['Float']['output'];
};

export type KpiModuleDto = {
  __typename?: 'KpiModuleDto';
  currentSettlementPeriodId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
};

export type KpiRateDto = {
  __typename?: 'KpiRateDto';
  assignee: Employee;
  assigneeEmployeeId: Scalars['String']['output'];
  dueToDayTimeout: Scalars['Float']['output'];
  entries?: Maybe<Scalars['JSON']['output']>;
  hasTarget: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  organizationUnit: OrganizationUnit;
  organizationUnitId: Scalars['String']['output'];
  rateDescription?: Maybe<Scalars['String']['output']>;
  rateName: Scalars['String']['output'];
  targetData?: Maybe<Scalars['JSON']['output']>;
  targetType?: Maybe<KpiRatingTargetType>;
  unit: KpiUnitModelDto;
};

export type KpiRateEntryInput = {
  entry: Scalars['Float']['input'];
  month: Scalars['Float']['input'];
  rateId: Scalars['String']['input'];
};

export enum KpiRatingOrderBy {
  Employee = 'EMPLOYEE',
  Name = 'NAME',
  OrganizationUnit = 'ORGANIZATION_UNIT'
}

export enum KpiRatingTargetType {
  MonthlyCustom = 'MONTHLY_CUSTOM',
  MonthlyUnified = 'MONTHLY_UNIFIED'
}

export type KpiSettlementPeriodDto = {
  __typename?: 'KpiSettlementPeriodDto';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
  periodName: Scalars['String']['output'];
  settlementPeriodYear?: Maybe<Scalars['String']['output']>;
};

export type KpiUnitModelDto = {
  __typename?: 'KpiUnitModelDto';
  unitName: Scalars['String']['output'];
  unitShortcut: Scalars['String']['output'];
};

export type LayoutModelInput = {
  h: Scalars['Float']['input'];
  w: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type MarkAsReadInput = {
  notificationId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type ModifyEflowReportCommentInput = {
  commentId: Scalars['String']['input'];
  showInReport: Scalars['Boolean']['input'];
};

export type ModifyPermissionInput = {
  moduleName: ModuleNamesEnum;
  operation: OperationNameEnum;
  organizationId: Scalars['String']['input'];
  organizationUnitsId?: InputMaybe<Array<Scalars['String']['input']>>;
  role: SystemRoles;
  usersId?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum ModuleNamesEnum {
  Eflow = 'EFLOW',
  Kaizen = 'KAIZEN',
  Kpi = 'KPI'
}

export type Mutation = {
  __typename?: 'Mutation';
  acceptKaizenSubmission: KaizenSubmissionDto;
  addCommissionMember: OperationStatus;
  addKaizenSubmissionToRealisation: KaizenSubmissionDto;
  addKeyUserToOrganization: OperationStatus;
  changeKaizenSubmissionStatus: KaizenSubmissionDto;
  changeStepPosition: EFlow;
  changeWidgetVisibility: DashboardDto;
  cloneEflow: EFlow;
  cloneEflowSteps: OperationStatus;
  createDashboard: DashboardDto;
  createEFlow: EFlow;
  createEFlowReport: OperationStatus;
  createEflowStep: EflowStep;
  createEmployee: Employee;
  createKaizenScoring: KaizenScoring;
  createKaizenSubmission: KaizenSubmissionDto;
  createKpiChart: DashboardDto;
  createKpiRate: KpiRateDto;
  createKpiSettlementPeriod: KpiSettlementPeriodDto;
  createOrganization: Organization;
  createOrganizationUnit: OrganizationUnit;
  createSettlementPeriod: KaizenSettlementPeriodDto;
  deleteOrganizationUnit: DeleteOrganizationUnitDto;
  kaizenCancelSubmission: KaizenSubmissionDto;
  markNotificationAsRead: OperationStatus;
  modifyPermissions: OperationStatus;
  pinToDashboard: DashboardDto;
  rejectSingleSubmission: KaizenSubmissionDto;
  rejectSubmissionImplementation: KaizenSubmissionDto;
  removeCommissionMember: OperationStatus;
  removeEflowSteps: OperationStatus;
  removeEmployee: OperationStatus;
  removeKaizenSubmission: OperationStatus;
  removeKeyUserFromOrganization: OperationStatus;
  removeKpiRate: OperationStatus;
  removeOrganisation: OperationStatus;
  removeSettlementPeriod: OperationStatus;
  reportKpiRateEntry: OperationStatus;
  sendToAdjustment: KaizenSubmissionDto;
  setCommissionLeader: OperationStatus;
  setKaizenSubmissionCommissionAssessmentStatus: KaizenSubmissionDto;
  setKaizenSubmissionImplementationAcceptedStatus: KaizenSubmissionDto;
  setKaizenSubmissionImplementedStatus: KaizenSubmissionDto;
  setKaizenSubmissionInProgressStatus: KaizenSubmissionDto;
  setKaizenSubmissionSupervisorAssessmentStatus: KaizenSubmissionDto;
  updateComment: CommentDto;
  updateDashboardLayout: DashboardDto;
  updateEflow: EFlow;
  updateEflowStatus: EFlow;
  updateEflowStep: EflowStep;
  updateEmployee: Employee;
  updateEmployeeProfilePicture: Employee;
  updateKaizenSubmission: KaizenSubmissionDto;
  updateKpiChart: ChartDto;
  updateKpiRate: KpiRateDto;
  updateKpiSettlementPeriod: KpiSettlementPeriodDto;
  updateOrganisation: Organization;
  updateOrganizationUnit: OrganizationUnit;
  updatePositions: Organization;
  updateSettlementPeriod: KaizenSettlementPeriodDto;
};


export type MutationAcceptKaizenSubmissionArgs = {
  kaizenSubmissionStatusChangeInput: ChangeSubmissionStatusInput;
};


export type MutationAddCommissionMemberArgs = {
  addCommissionMemberInput: AddCommissionMemberInput;
};


export type MutationAddKaizenSubmissionToRealisationArgs = {
  kaizenSubmissionAddToRealisation: AddSubmissionToRealisationInput;
};


export type MutationAddKeyUserToOrganizationArgs = {
  addOrganizationKeyUserInput: OrganizationKeyUserInput;
};


export type MutationChangeKaizenSubmissionStatusArgs = {
  changeKaizenSubmissionStatusInput: ChangeKaizenSubmissionRootStatusInput;
};


export type MutationChangeStepPositionArgs = {
  changeEflowStepPositionInput: ChangeStepPositionInput;
};


export type MutationChangeWidgetVisibilityArgs = {
  changeWidgetVisibilityInput: WidgetVisibilityInput;
};


export type MutationCloneEflowArgs = {
  cloneEflowInput: CloneEFlowInput;
};


export type MutationCloneEflowStepsArgs = {
  cloneEflowStepsInput: CloneEflowStepsInput;
};


export type MutationCreateDashboardArgs = {
  createDashboardInput: CreateDashboardInput;
};


export type MutationCreateEFlowArgs = {
  createEFlowInput: CreateEflowInput;
};


export type MutationCreateEFlowReportArgs = {
  generateReportInput: GenerateEflowReportInput;
};


export type MutationCreateEflowStepArgs = {
  createEflowStepInput: CreateEflowStepInput;
  resourceImage?: InputMaybe<Array<Scalars['Upload']['input']>>;
};


export type MutationCreateEmployeeArgs = {
  createEmployeeInput: CreateEmployeeInput;
};


export type MutationCreateKaizenScoringArgs = {
  createKaizenScoringInput: CreateKaizenScoringInput;
};


export type MutationCreateKaizenSubmissionArgs = {
  createKaizenSubmissionInput: CreateKaizenSubmissionInput;
  problemFiles?: InputMaybe<Array<Scalars['Upload']['input']>>;
  solutionFiles?: InputMaybe<Array<Scalars['Upload']['input']>>;
};


export type MutationCreateKpiChartArgs = {
  createKpiChartInput: CreateKpiChartInput;
};


export type MutationCreateKpiRateArgs = {
  createKpiRateInput: CreateKpiRateInput;
};


export type MutationCreateKpiSettlementPeriodArgs = {
  createKpiSettlementPeriodInput: CreateKpiSettlementPeriodInput;
};


export type MutationCreateOrganizationArgs = {
  createOrganisationInput: CreateOrganisationInput;
  resourceImage?: InputMaybe<Scalars['Upload']['input']>;
};


export type MutationCreateOrganizationUnitArgs = {
  createOrganizationUnitInput: CreateOrganizationUnitInput;
};


export type MutationCreateSettlementPeriodArgs = {
  createSettlementPeriodInput: CreateSettlementPeriodInput;
};


export type MutationDeleteOrganizationUnitArgs = {
  deleteOrganizationUnitInput: DeleteOrganizationInput;
};


export type MutationKaizenCancelSubmissionArgs = {
  kaizenSubmissionCancel: ChangeSubmissionStatusInput;
};


export type MutationMarkNotificationAsReadArgs = {
  markAsReadInput: MarkAsReadInput;
};


export type MutationModifyPermissionsArgs = {
  modifyPermissionInput: ModifyPermissionInput;
};


export type MutationPinToDashboardArgs = {
  pinToDashboardInput: PinToDashboardInput;
};


export type MutationRejectSingleSubmissionArgs = {
  rejectKaizenSubmissionInput: RejectKaizenSubmissionInput;
};


export type MutationRejectSubmissionImplementationArgs = {
  rejectKaizenSubmissionInput: RejectKaizenSubmissionInput;
};


export type MutationRemoveCommissionMemberArgs = {
  removeCommissionMemberInput: RemoveCommissionMemberInput;
};


export type MutationRemoveEflowStepsArgs = {
  removeEflowStepsInput: RemoveEflowStepsInput;
};


export type MutationRemoveEmployeeArgs = {
  removeEmployeeInput: RemoveEmployeeInput;
};


export type MutationRemoveKaizenSubmissionArgs = {
  removeKaizenSubmissionInput: RemoveKaizenSubmissionInput;
};


export type MutationRemoveKeyUserFromOrganizationArgs = {
  removeOrganizationKeyUserInput: OrganizationKeyUserInput;
};


export type MutationRemoveKpiRateArgs = {
  removeKpiInput: RemoveKpiInput;
};


export type MutationRemoveOrganisationArgs = {
  removeOrganisationInput: RemoveOrganizationInput;
};


export type MutationRemoveSettlementPeriodArgs = {
  removeSettlementPeriodInput: RemoveSettlementPeriodInput;
};


export type MutationReportKpiRateEntryArgs = {
  reportKpiRateEntryInput: ReportKpiRateEntryInput;
};


export type MutationSendToAdjustmentArgs = {
  sendToAdjustmentInput: SendToAdjustmentKaizenSubmissionInput;
};


export type MutationSetCommissionLeaderArgs = {
  setCommissionLeaderInput: SetCommissionLeaderInput;
};


export type MutationSetKaizenSubmissionCommissionAssessmentStatusArgs = {
  kaizenSubmissionStatusChangeInput: ChangeSubmissionStatusInput;
};


export type MutationSetKaizenSubmissionImplementationAcceptedStatusArgs = {
  kaizenSubmissionStatusChangeInput: ChangeSubmissionStatusInput;
};


export type MutationSetKaizenSubmissionImplementedStatusArgs = {
  kaizenSubmissionStatusChangeInput: ChangeSubmissionStatusInput;
};


export type MutationSetKaizenSubmissionInProgressStatusArgs = {
  kaizenSubmissionStatusChangeInput: ChangeSubmissionStatusInput;
};


export type MutationSetKaizenSubmissionSupervisorAssessmentStatusArgs = {
  kaizenSubmissionStatusChangeInput: ChangeSubmissionStatusInput;
};


export type MutationUpdateCommentArgs = {
  updateCommentInput: UpdateCommentInput;
};


export type MutationUpdateDashboardLayoutArgs = {
  updateDashboardLayoutInput: UpdateDashboardLayoutInput;
};


export type MutationUpdateEflowArgs = {
  updateEflowInput: UpdateEflowInput;
};


export type MutationUpdateEflowStatusArgs = {
  updateEflowStatusInput: UpdateEflowStatusInput;
};


export type MutationUpdateEflowStepArgs = {
  resourceImage?: InputMaybe<Array<Scalars['Upload']['input']>>;
  updateEflowStepInput: UpdateEflowStepInput;
};


export type MutationUpdateEmployeeArgs = {
  updateEmployeeInput: UpdateEmployeeInput;
};


export type MutationUpdateEmployeeProfilePictureArgs = {
  resourceImage: Scalars['Upload']['input'];
  updateEmployeeProfilePicture: UpdateEmployeeProfileInput;
};


export type MutationUpdateKaizenSubmissionArgs = {
  problemFiles?: InputMaybe<Array<Scalars['Upload']['input']>>;
  solutionFiles?: InputMaybe<Array<Scalars['Upload']['input']>>;
  updateKaizenSubmissionInput: UpdateKaizenSubmissionInput;
};


export type MutationUpdateKpiChartArgs = {
  updateChartInput: UpdateChartInput;
};


export type MutationUpdateKpiRateArgs = {
  updateKpiRateInput: UpdateKpiRateInput;
};


export type MutationUpdateKpiSettlementPeriodArgs = {
  updateKpiSettlementPeriodInput: UpdateKpiSettlementPeriodInput;
};


export type MutationUpdateOrganisationArgs = {
  resourceImage?: InputMaybe<Scalars['Upload']['input']>;
  updateOrganisationInput: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationUnitArgs = {
  updateOrganizationUnitInput: UpdateOrganizationUnitDto;
};


export type MutationUpdatePositionsArgs = {
  updatePositionsInput: UpdatePositionsInput;
};


export type MutationUpdateSettlementPeriodArgs = {
  updateSettlementPeriodInput: UpdateSettlementPeriodInput;
};

export enum NotificationTypesEnum {
  KaizenNewComment = 'KAIZEN_NEW_COMMENT',
  KaizenStatusChange = 'KAIZEN_STATUS_CHANGE',
  KpiReported = 'KPI_REPORTED',
  KpiReportSchedule = 'KPI_REPORT_SCHEDULE'
}

export type OperationInput = {
  name: Scalars['String']['input'];
  operationType: OperationTypesEnum;
};

export enum OperationNameEnum {
  Add = 'ADD',
  Remove = 'REMOVE',
  Update = 'UPDATE'
}

export type OperationSchema = {
  __typename?: 'OperationSchema';
  name: Scalars['String']['output'];
  operationType: OperationTypesEnum;
};

export type OperationStatus = {
  __typename?: 'OperationStatus';
  success: Scalars['Boolean']['output'];
};

export enum OperationTypesEnum {
  AddedValue = 'ADDED_VALUE',
  Inspection = 'INSPECTION',
  InspectionTransport = 'INSPECTION_TRANSPORT',
  Operation = 'OPERATION',
  OperationInspection = 'OPERATION_INSPECTION',
  OperationStorage = 'OPERATION_STORAGE',
  OperationTransport = 'OPERATION_TRANSPORT',
  Storage = 'STORAGE',
  StorageInspection = 'STORAGE_INSPECTION',
  Transport = 'TRANSPORT'
}

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Organization = {
  __typename?: 'Organization';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  modules: Array<OrganizationModules>;
  name: Scalars['String']['output'];
  positions?: Maybe<Array<Scalars['String']['output']>>;
  url: Scalars['String']['output'];
};

export type OrganizationKeyUserInput = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export enum OrganizationModules {
  EFlowAnalize = 'EFlowAnalize',
  ESchema = 'ESchema',
  Kaizen = 'Kaizen',
  Kpi = 'Kpi'
}

export type OrganizationUnit = {
  __typename?: 'OrganizationUnit';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  parentGuid?: Maybe<Scalars['String']['output']>;
};

export type OrganizationUnitInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  parentGuid?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationUnitWithChildren = {
  __typename?: 'OrganizationUnitWithChildren';
  children?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  members?: Maybe<Array<Employee>>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  parentGuid?: Maybe<Scalars['String']['output']>;
};

export type ParameterInput = {
  name: Scalars['String']['input'];
  type: ParameterTypes;
  unit: ParameterUnitsTypes;
};

export type ParameterObject = {
  __typename?: 'ParameterObject';
  name: Scalars['String']['output'];
  type: ParameterTypes;
  unit: ParameterUnitsTypes;
};

export enum ParameterTypes {
  Local = 'LOCAL',
  System = 'SYSTEM'
}

export enum ParameterUnitsTypes {
  AreaCm2 = 'AREA_CM2',
  AreaM2 = 'AREA_M2',
  DistanceCm = 'DISTANCE_CM',
  DistanceM = 'DISTANCE_M',
  DistanceMm = 'DISTANCE_MM',
  MassG = 'MASS_G',
  MassKg = 'MASS_KG',
  TimeD = 'TIME_D',
  TimeH = 'TIME_H',
  TimeHhMm = 'TIME_HH_MM',
  TimeHhMmSs = 'TIME_HH_MM_SS',
  TimeMin = 'TIME_MIN',
  TimeMmSs = 'TIME_MM_SS',
  TimeS = 'TIME_S',
  VolumeL = 'VOLUME_L',
  VolumeM3 = 'VOLUME_M3',
  VolumeUnits = 'VOLUME_UNITS'
}

export type PinToDashboardInput = {
  chartId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  settlementPeriodId: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  findAllEFlows: FindAllEFflows;
  findAllKaizenSubmissions: FindAllKaizenSubmissionsDto;
  findAllKpiSettlementPeriods: FindAllKpiSettlementPeriodsDto;
  findAllKpis: FindAllKpiDto;
  findAllOrganizations: FindAllOrganizations;
  findAllSettlementPeriods: FindAllSettlementPeriodsDto;
  findChart: ChartDto;
  findKaizenModuleInfo: KaizenModule;
  findKpiModuleInfo: KpiModuleDto;
  findKpiUnits: FindKpiUnitsDto;
  findManyKpis: FindManyKpisDto;
  findNotifications: FindAllNotificationsDto;
  findOneEFlow: EFlow;
  findOneEflowReport: EFlowReport;
  findOneEflowStep: EflowStep;
  findOrganizationSubunits: FindAllSubUnitsStructureDto;
  findSingleKaizenReport: KaizenSubmissionReportDto;
  findSingleOrganization: Organization;
  findSingleSubmission: KaizenSubmissionDto;
  getCommissionMembers: CommissionMembers;
  getEmployee: GetSingleEmployeeDto;
  getEmployees: FindAllEmployees;
  getKaizenRanks: GetKaizenRanksDto;
  getKpiDashboard: DashboardDto;
  getKpiRate: KpiRateDto;
  getOrganizationUnitsStructure: OrganizationUnitWithChildren;
  getRelatedKpiCharts: RelatedResourcesDto;
  getSettlementPeriodSubmissionNumber: GetSettlementPeriodSubmissionNumberDto;
};


export type QueryFindAllEFlowsArgs = {
  findAllEFlowsInput: FindAllEFlowsInput;
};


export type QueryFindAllKaizenSubmissionsArgs = {
  findAllKaizenSubmissionsInput: FindAllSubmissionsInput;
};


export type QueryFindAllKpiSettlementPeriodsArgs = {
  findAllKpiSettlementPeriodsInput: FindAllKpiSettlementPeriodsInput;
};


export type QueryFindAllKpisArgs = {
  findAllKpisInput: FindKpiRateInput;
};


export type QueryFindAllOrganizationsArgs = {
  paginationInput: FindAllOrganizationsInput;
};


export type QueryFindAllSettlementPeriodsArgs = {
  findSettlementPeriodsInput: FindAllSettlementPeriodsInput;
};


export type QueryFindChartArgs = {
  findChartInput: FindChartInput;
};


export type QueryFindKaizenModuleInfoArgs = {
  findKaizenModuleInput: FindModuleInfoInput;
};


export type QueryFindKpiModuleInfoArgs = {
  findKpiModuleInput: FindKpiModuleInfoInput;
};


export type QueryFindKpiUnitsArgs = {
  findAllKpisUnitsInput: FindAllKpiUnitsInput;
};


export type QueryFindManyKpisArgs = {
  findManyKpisInput: FindManyRateInput;
};


export type QueryFindNotificationsArgs = {
  findAllNotificationsInput: FindAllUserNotificationsInput;
};


export type QueryFindOneEFlowArgs = {
  findOneEFlowInput: FindOneEFlowInput;
};


export type QueryFindOneEflowReportArgs = {
  findReportInput: FindReportInput;
};


export type QueryFindOneEflowStepArgs = {
  findOneEflowStepInput: FindOneEflowStepInput;
};


export type QueryFindOrganizationSubunitsArgs = {
  findOrganizationUnitSubunitsInput: FindOrganizationUnitSubunitsInput;
};


export type QueryFindSingleKaizenReportArgs = {
  findSingleKaizenReportInput: FindSingleReportInput;
};


export type QueryFindSingleOrganizationArgs = {
  findSingleOrganization: FindOrganizationInput;
};


export type QueryFindSingleSubmissionArgs = {
  findKaizenSubmissionInput: FindKaizenSubmissionInput;
};


export type QueryGetCommissionMembersArgs = {
  getCommissionMembersInput: GetCommissionMemberInput;
};


export type QueryGetEmployeeArgs = {
  getEmployeeInput: GetEmployee;
};


export type QueryGetEmployeesArgs = {
  getEmployeesInput: EmployeesListArgs;
};


export type QueryGetKaizenRanksArgs = {
  getKaizenRanksInput: GetKaizenRanksInput;
};


export type QueryGetKpiDashboardArgs = {
  findDashboardInput: FindDashboardInput;
};


export type QueryGetKpiRateArgs = {
  getKpiRateInput: GetKpiRateInput;
};


export type QueryGetOrganizationUnitsStructureArgs = {
  getOrganizationUnitStructureInput: GetOrganizationUnitInput;
};


export type QueryGetRelatedKpiChartsArgs = {
  findRelatedResourcesInput: FindRelatedResourcesInput;
};


export type QueryGetSettlementPeriodSubmissionNumberArgs = {
  getSettlementPeriodSubmissionNumberInput: GetSettlementPeriodSubmissionNumberInput;
};

export enum RateSumTypes {
  SumAll = 'SUM_ALL',
  SumDistinctName = 'SUM_DISTINCT_NAME',
  SumEmployee = 'SUM_EMPLOYEE',
  SumOrganizationUnit = 'SUM_ORGANIZATION_UNIT'
}

export type RejectKaizenSubmissionInput = {
  commentModel: CreateCommentInput;
  organizationId: Scalars['String']['input'];
  submissionId: Scalars['String']['input'];
};

export type RelatedResourceDto = {
  __typename?: 'RelatedResourceDto';
  resourceId: Scalars['String']['output'];
  resourceName: Scalars['String']['output'];
};

export type RelatedResourcesDto = {
  __typename?: 'RelatedResourcesDto';
  relatedResources: Array<RelatedResourceDto>;
  totalRelatedResources: Scalars['Float']['output'];
};

export type RemoveCommissionMemberInput = {
  employeeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type RemoveEflowStepsInput = {
  analizeType: AsIsToBeNamesEnum;
  eFlowId: Scalars['String']['input'];
  eFlowStepsIds: Array<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};

export type RemoveEmployeeInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export type RemoveKaizenSubmissionInput = {
  organizationId: Scalars['String']['input'];
  submissionId: Scalars['String']['input'];
};

export type RemoveKpiInput = {
  kpiId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type RemoveOrganizationInput = {
  id: Scalars['String']['input'];
};

export type RemoveSettlementPeriodInput = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type ReportKpiRateEntryInput = {
  entries: Array<KpiRateEntryInput>;
  organizationId: Scalars['String']['input'];
  organizationUnitId: Scalars['String']['input'];
};

export type S3FileInput = {
  fileId: Scalars['String']['input'];
};

export type S3ReferenceKeyModelInput = {
  fileId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type SendToAdjustmentKaizenSubmissionInput = {
  commentModel: CreateCommentInput;
  organizationId: Scalars['String']['input'];
  submissionId: Scalars['String']['input'];
};

export type SetCommissionLeaderInput = {
  employeeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type SingleNotificationDto = {
  __typename?: 'SingleNotificationDto';
  body?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  isOpened: Scalars['Boolean']['output'];
  notificationTemplate: NotificationTypesEnum;
  sentAt: Scalars['String']['output'];
};

export type SingleSubunitDto = {
  __typename?: 'SingleSubunitDto';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SingleWidgetInput = {
  layoutKey: Scalars['String']['input'];
  layoutModel: LayoutModelInput;
};

export type SubscribeEflowStepsInput = {
  eFlowId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  eFlowChanged: EflowSubscription;
  eFlowStepChanged: EflowStep;
  notifications: SingleNotificationDto;
};


export type SubscriptionEFlowChangedArgs = {
  findOneEFlowInput: FindOneEFlowInput;
};


export type SubscriptionEFlowStepChangedArgs = {
  subscribeEflowStepsInput: SubscribeEflowStepsInput;
};

export enum SupportedWidgetsEnum {
  KpiChart = 'KPI_CHART'
}

export enum SystemRoles {
  Administrator = 'ADMINISTRATOR',
  EflowCreator = 'EFLOW_CREATOR',
  EflowSpectator = 'EFLOW_SPECTATOR',
  KaizenCommisionLeader = 'KAIZEN_COMMISION_LEADER',
  KaizenCommisionMember = 'KAIZEN_COMMISION_MEMBER',
  KaizenMember = 'KAIZEN_MEMBER',
  KeyUser = 'KEY_USER',
  KpiMember = 'KPI_MEMBER',
  Root = 'ROOT',
  SystemAdmin = 'SYSTEM_ADMIN',
  UnitAdmin = 'UNIT_ADMIN',
  User = 'USER'
}

export type UnitKpiRateInput = {
  unitName: Scalars['String']['input'];
  unitShortcut: Scalars['String']['input'];
};

export type UpdateChartInput = {
  chartId: Scalars['String']['input'];
  chartName?: InputMaybe<Scalars['String']['input']>;
  chartType?: InputMaybe<ChartTypes>;
  displayOptions?: InputMaybe<ChartDisplayOptionsInput>;
  kpiIds?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationId: Scalars['String']['input'];
};

export type UpdateCommentInput = {
  commentCategory?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type UpdateDashboardLayoutInput = {
  dashboardId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  widgets: Array<SingleWidgetInput>;
};

export type UpdateEflowInput = {
  analyzeLeaderModel?: InputMaybe<Array<AnalizeLeaderInput>>;
  commentTypes?: InputMaybe<Array<EflowCommentModelInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  operations?: InputMaybe<Array<OperationInput>>;
  organizationId: Scalars['String']['input'];
  parameters?: InputMaybe<Array<ParameterInput>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  workGroupUsers: Array<WorkGroupInput>;
};

export type UpdateEflowStatusInput = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  status: EFlowStatuses;
};

export type UpdateEflowStepInput = {
  analizeType?: InputMaybe<AsIsToBeNamesEnum>;
  commentModels?: InputMaybe<Array<ModifyEflowReportCommentInput>>;
  currentFiles?: InputMaybe<Array<S3FileInput>>;
  eFlowId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  newComments?: InputMaybe<Array<AddEflowCommentInput>>;
  operation?: InputMaybe<OperationInput>;
  operationName?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  parameters?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UpdateEmployeeInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  organizationUnitGuid?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  profilePicture?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<EmployeeState>;
  systemRole?: InputMaybe<Array<SystemRoles>>;
};

export type UpdateEmployeeProfileInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['String']['input'];
};

export type UpdateKaizenSubmissionInput = {
  coAuthors?: InputMaybe<Array<CreatedByModelInput>>;
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  problem: KaizenSubmissionProblem;
  problemFiles?: InputMaybe<Array<S3ReferenceKeyModelInput>>;
  solution: KaizenSubmissionProblem;
  solutionFiles?: InputMaybe<Array<S3ReferenceKeyModelInput>>;
};

export type UpdateKpiRateInput = {
  dueToDateTimeout: Scalars['Float']['input'];
  hasTarget: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  organizationUnitId: Scalars['String']['input'];
  rateDescription?: InputMaybe<Scalars['String']['input']>;
  rateName: Scalars['String']['input'];
  reporterId: Scalars['String']['input'];
  targetData?: InputMaybe<Scalars['JSON']['input']>;
  targetType?: InputMaybe<KpiRatingTargetType>;
  unit: UnitKpiRateInput;
};

export type UpdateKpiSettlementPeriodInput = {
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['String']['input'];
  periodName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationInput = {
  id: Scalars['String']['input'];
  modules?: InputMaybe<Array<OrganizationModules>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationUnitDto = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  parentGuid?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdatePositionsInput = {
  id: Scalars['String']['input'];
  positions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateSettlementPeriodInput = {
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['String']['input'];
  periodName?: InputMaybe<Scalars['String']['input']>;
};

export type WidgetVisibilityInput = {
  dashboardId: Scalars['String']['input'];
  isHidden: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
  widgetLayoutKey: Scalars['String']['input'];
};

export type WorkGroupInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  nameAndSurname?: InputMaybe<Scalars['String']['input']>;
};

export type WorkGroupModel = {
  __typename?: 'WorkGroupModel';
  id?: Maybe<Scalars['String']['output']>;
  nameAndSurname?: Maybe<Scalars['String']['output']>;
};

export type ChartFragment = { __typename?: 'ChartDto', id: string, chartName: string, dataSet: any, chartType: ChartTypes, dataSourceSet: Array<string>, datasetType: ChartDataSetType, isError: boolean, additionalInfo: any, displayOptions: { __typename?: 'ChartsDisplayOptionsModels', showDataAsPercent?: boolean | null, showTarget?: boolean | null, sumIndicators?: RateSumTypes | null, showDataInAscendingOrder?: boolean | null, legendType: ChartLegendType, showDetailedLegend?: boolean | null, showDataLabels?: boolean | null } };

export type FindChartQueryVariables = Exact<{
  input: FindChartInput;
}>;


export type FindChartQuery = { __typename?: 'Query', findChart: { __typename?: 'ChartDto', id: string, chartName: string, dataSet: any, chartType: ChartTypes, dataSourceSet: Array<string>, datasetType: ChartDataSetType, isError: boolean, additionalInfo: any, displayOptions: { __typename?: 'ChartsDisplayOptionsModels', showDataAsPercent?: boolean | null, showTarget?: boolean | null, sumIndicators?: RateSumTypes | null, showDataInAscendingOrder?: boolean | null, legendType: ChartLegendType, showDetailedLegend?: boolean | null, showDataLabels?: boolean | null } } };

export type UpdateKpiChartMutationMutationVariables = Exact<{
  input: UpdateChartInput;
}>;


export type UpdateKpiChartMutationMutation = { __typename?: 'Mutation', updateKpiChart: { __typename?: 'ChartDto', id: string, chartName: string, dataSet: any, chartType: ChartTypes, dataSourceSet: Array<string>, datasetType: ChartDataSetType, isError: boolean, additionalInfo: any, displayOptions: { __typename?: 'ChartsDisplayOptionsModels', showDataAsPercent?: boolean | null, showTarget?: boolean | null, sumIndicators?: RateSumTypes | null, showDataInAscendingOrder?: boolean | null, legendType: ChartLegendType, showDetailedLegend?: boolean | null, showDataLabels?: boolean | null } } };

export type NotificationFragment = { __typename?: 'SingleNotificationDto', id: string, isOpened: boolean, body?: any | null, notificationTemplate: NotificationTypesEnum, sentAt: string };

export type MarkNotificationAsReadMutationVariables = Exact<{
  input: MarkAsReadInput;
}>;


export type MarkNotificationAsReadMutation = { __typename?: 'Mutation', markNotificationAsRead: { __typename?: 'OperationStatus', success: boolean } };

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment: { __typename?: 'CommentDto', commentCategory?: string | null, content: string, id: string } };

export type FindAllNotificationsQueryVariables = Exact<{
  input: FindAllUserNotificationsInput;
}>;


export type FindAllNotificationsQuery = { __typename?: 'Query', findNotifications: { __typename?: 'FindAllNotificationsDto', total: number, items: Array<{ __typename?: 'SingleNotificationDto', id: string, isOpened: boolean, body?: any | null, notificationTemplate: NotificationTypesEnum, sentAt: string }> } };

export type SubscribeNotificationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeNotificationsSubscription = { __typename?: 'Subscription', notifications: { __typename?: 'SingleNotificationDto', id: string, isOpened: boolean, body?: any | null, notificationTemplate: NotificationTypesEnum, sentAt: string } };

export type DashboardFragment = { __typename?: 'DashboardDto', id: string, organizationId: string, widgets: Array<{ __typename?: 'DashboardWidgetDto', layoutModel: any, widgetType: SupportedWidgetsEnum, resourceLink: any, layoutKey: string, isHidden: boolean }> };

export type ChangeWidgetVisibilityMutationVariables = Exact<{
  input: WidgetVisibilityInput;
}>;


export type ChangeWidgetVisibilityMutation = { __typename?: 'Mutation', changeWidgetVisibility: { __typename?: 'DashboardDto', id: string, organizationId: string, widgets: Array<{ __typename?: 'DashboardWidgetDto', layoutModel: any, widgetType: SupportedWidgetsEnum, resourceLink: any, layoutKey: string, isHidden: boolean }> } };

export type CreateDashboardMutationVariables = Exact<{
  input: CreateDashboardInput;
}>;


export type CreateDashboardMutation = { __typename?: 'Mutation', createDashboard: { __typename?: 'DashboardDto', id: string, organizationId: string, widgets: Array<{ __typename?: 'DashboardWidgetDto', layoutModel: any, widgetType: SupportedWidgetsEnum, resourceLink: any, layoutKey: string, isHidden: boolean }> } };

export type PinToDashboardMutationVariables = Exact<{
  input: PinToDashboardInput;
}>;


export type PinToDashboardMutation = { __typename?: 'Mutation', pinToDashboard: { __typename?: 'DashboardDto', id: string, organizationId: string, widgets: Array<{ __typename?: 'DashboardWidgetDto', layoutModel: any, widgetType: SupportedWidgetsEnum, resourceLink: any, layoutKey: string, isHidden: boolean }> } };

export type UpdateDashboardLayoutMutationVariables = Exact<{
  input: UpdateDashboardLayoutInput;
}>;


export type UpdateDashboardLayoutMutation = { __typename?: 'Mutation', updateDashboardLayout: { __typename?: 'DashboardDto', id: string, organizationId: string, widgets: Array<{ __typename?: 'DashboardWidgetDto', layoutModel: any, widgetType: SupportedWidgetsEnum, resourceLink: any, layoutKey: string, isHidden: boolean }> } };

export type GetDashboardQueryVariables = Exact<{
  input: FindDashboardInput;
}>;


export type GetDashboardQuery = { __typename?: 'Query', getKpiDashboard: { __typename?: 'DashboardDto', id: string, organizationId: string, widgets: Array<{ __typename?: 'DashboardWidgetDto', layoutModel: any, widgetType: SupportedWidgetsEnum, resourceLink: any, layoutKey: string, isHidden: boolean }> } };

export type EflowStepFragmentFragment = { __typename?: 'EflowStep', id: string, eFlowId: string, isActive: boolean, operationName: string, parameters?: any | null, createdAt: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, createdAt: string, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', organizationId: string, id: string, profilePicture?: string | null, firstName: string, lastName: string } }> | null, commentModels?: Array<{ __typename?: 'EflowReportComment', showInReport: boolean, commentId: string }> | null, files?: Array<{ __typename?: 'FileModel', id: string, fileExtension: string, fileName: string, fileUrl: string }> | null, createdBy: { __typename?: 'Employee', organizationId: string, id: string, firstName: string, profilePicture?: string | null, lastName: string, email: string } };

export type SingleEflowFragment = { __typename?: 'EFlow', createdAt: any, description: string, id: string, name: string, status: EFlowStatuses, startDate?: string | null, analyzeLeaders?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }> | null, createdBy: { __typename?: 'Employee', firstName: string, lastName: string } };

export type SingleEflowFragmentFragment = { __typename?: 'EFlow', id: string, name: string, parameters?: Array<{ __typename?: 'ParameterObject', name: string, type: ParameterTypes, unit: ParameterUnitsTypes }> | null, operations?: Array<{ __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }> | null, commentTypes?: Array<{ __typename?: 'EflowCommentModel', categoryName: string, symbol: string }> | null };

export type ChangeStepPositionMutationVariables = Exact<{
  input: ChangeStepPositionInput;
}>;


export type ChangeStepPositionMutation = { __typename?: 'Mutation', changeStepPosition: { __typename?: 'EFlow', id: string, asIsSteps?: Array<{ __typename?: 'EflowStep', eFlowId: string, id: string, operationName: string }> | null, toBeSteps?: Array<{ __typename?: 'EflowStep', eFlowId: string, id: string, operationName: string } | null> | null } };

export type CloneEflowMutationVariables = Exact<{
  input: CloneEFlowInput;
}>;


export type CloneEflowMutation = { __typename?: 'Mutation', cloneEflow: { __typename?: 'EFlow', id: string, name: string } };

export type CloneEflowStepsMutationVariables = Exact<{
  input: CloneEflowStepsInput;
}>;


export type CloneEflowStepsMutation = { __typename?: 'Mutation', cloneEflowSteps: { __typename?: 'OperationStatus', success: boolean } };

export type CreateEFlowMutationVariables = Exact<{
  input: CreateEflowInput;
}>;


export type CreateEFlowMutation = { __typename?: 'Mutation', createEFlow: { __typename?: 'EFlow', id: string } };

export type CreateEflowStepMutationVariables = Exact<{
  input: CreateEflowStepInput;
  files?: InputMaybe<Array<Scalars['Upload']['input']> | Scalars['Upload']['input']>;
}>;


export type CreateEflowStepMutation = { __typename?: 'Mutation', createEflowStep: { __typename?: 'EflowStep', id: string, eFlowId: string, isActive: boolean, operationName: string, parameters?: any | null, createdAt: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, createdAt: string, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', organizationId: string, id: string, profilePicture?: string | null, firstName: string, lastName: string } }> | null, commentModels?: Array<{ __typename?: 'EflowReportComment', showInReport: boolean, commentId: string }> | null, files?: Array<{ __typename?: 'FileModel', id: string, fileExtension: string, fileName: string, fileUrl: string }> | null, createdBy: { __typename?: 'Employee', organizationId: string, id: string, firstName: string, profilePicture?: string | null, lastName: string, email: string } } };

export type CreateReportMutationVariables = Exact<{
  input: GenerateEflowReportInput;
}>;


export type CreateReportMutation = { __typename?: 'Mutation', createEFlowReport: { __typename?: 'OperationStatus', success: boolean } };

export type RemoveEflowStepsMutationVariables = Exact<{
  input: RemoveEflowStepsInput;
}>;


export type RemoveEflowStepsMutation = { __typename?: 'Mutation', removeEflowSteps: { __typename?: 'OperationStatus', success: boolean } };

export type UpdateCommentInEflowStepMutationVariables = Exact<{
  input: UpdateEflowStepInput;
}>;


export type UpdateCommentInEflowStepMutation = { __typename?: 'Mutation', updateEflowStep: { __typename?: 'EflowStep', commentModels?: Array<{ __typename?: 'EflowReportComment', commentId: string, showInReport: boolean }> | null } };

export type UpdateEflowMutationVariables = Exact<{
  input: UpdateEflowInput;
}>;


export type UpdateEflowMutation = { __typename?: 'Mutation', updateEflow: { __typename?: 'EFlow', id: string } };

export type UpdateEflowStatusMutationVariables = Exact<{
  input: UpdateEflowStatusInput;
}>;


export type UpdateEflowStatusMutation = { __typename?: 'Mutation', updateEflowStatus: { __typename?: 'EFlow', id: string, status: EFlowStatuses } };

export type UpdateEflowStepMutationVariables = Exact<{
  input: UpdateEflowStepInput;
  files?: InputMaybe<Array<Scalars['Upload']['input']> | Scalars['Upload']['input']>;
}>;


export type UpdateEflowStepMutation = { __typename?: 'Mutation', updateEflowStep: { __typename?: 'EflowStep', id: string, eFlowId: string, isActive: boolean, operationName: string, parameters?: any | null, createdAt: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, createdAt: string, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', organizationId: string, id: string, profilePicture?: string | null, firstName: string, lastName: string } }> | null, commentModels?: Array<{ __typename?: 'EflowReportComment', showInReport: boolean, commentId: string }> | null, files?: Array<{ __typename?: 'FileModel', id: string, fileExtension: string, fileName: string, fileUrl: string }> | null, createdBy: { __typename?: 'Employee', organizationId: string, id: string, firstName: string, profilePicture?: string | null, lastName: string, email: string } } };

export type FindAllEFlowsQueryVariables = Exact<{
  input: FindAllEFlowsInput;
}>;


export type FindAllEFlowsQuery = { __typename?: 'Query', findAllEFlows: { __typename?: 'FindAllEFflows', total: number, items: Array<{ __typename?: 'EFlow', createdAt: any, description: string, id: string, name: string, status: EFlowStatuses, startDate?: string | null, analyzeLeaders?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }> | null, createdBy: { __typename?: 'Employee', firstName: string, lastName: string } }> } };

export type FindEflowDataQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type FindEflowDataQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', name: string, description: string, status: EFlowStatuses, startDate?: string | null, workGroupUsers: Array<{ __typename?: 'WorkGroupModel', nameAndSurname?: string | null }>, analyzeLeaders?: Array<{ __typename?: 'Employee', firstName: string, lastName: string }> | null } };

export type FindEflowPermissionsQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type FindEflowPermissionsQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', id: string, status: EFlowStatuses, workGroupUsers: Array<{ __typename?: 'WorkGroupModel', id?: string | null }>, analyzeLeaders?: Array<{ __typename?: 'Employee', id: string, organizationId: string }> | null, createdBy: { __typename?: 'Employee', id: string, organizationId: string } } };

export type FindEflowToBeStepsQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type FindEflowToBeStepsQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', id: string, toBeState?: Array<string> | null, toBeSteps?: Array<{ __typename?: 'EflowStep', id: string, eFlowId: string, isActive: boolean, operationName: string, parameters?: any | null, createdAt: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, createdAt: string, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', organizationId: string, id: string, profilePicture?: string | null, firstName: string, lastName: string } }> | null, commentModels?: Array<{ __typename?: 'EflowReportComment', showInReport: boolean, commentId: string }> | null, files?: Array<{ __typename?: 'FileModel', id: string, fileExtension: string, fileName: string, fileUrl: string }> | null, createdBy: { __typename?: 'Employee', organizationId: string, id: string, firstName: string, profilePicture?: string | null, lastName: string, email: string } } | null> | null } };

export type FindOneEflowQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type FindOneEflowQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', id: string, name: string, parameters?: Array<{ __typename?: 'ParameterObject', name: string, type: ParameterTypes, unit: ParameterUnitsTypes }> | null, operations?: Array<{ __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }> | null, commentTypes?: Array<{ __typename?: 'EflowCommentModel', categoryName: string, symbol: string }> | null } };

export type FindOneEflowAsIsDataQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type FindOneEflowAsIsDataQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', id: string, asIsState?: Array<string> | null, asIsSteps?: Array<{ __typename?: 'EflowStep', id: string, eFlowId: string, isActive: boolean, operationName: string, parameters?: any | null, createdAt: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, createdAt: string, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', organizationId: string, id: string, profilePicture?: string | null, firstName: string, lastName: string } }> | null, commentModels?: Array<{ __typename?: 'EflowReportComment', showInReport: boolean, commentId: string }> | null, files?: Array<{ __typename?: 'FileModel', id: string, fileExtension: string, fileName: string, fileUrl: string }> | null, createdBy: { __typename?: 'Employee', organizationId: string, id: string, firstName: string, profilePicture?: string | null, lastName: string, email: string } }> | null } };

export type FindOneEflowStepQueryVariables = Exact<{
  input: FindOneEflowStepInput;
}>;


export type FindOneEflowStepQuery = { __typename?: 'Query', findOneEflowStep: { __typename?: 'EflowStep', id: string, eFlowId: string, isActive: boolean, operationName: string, parameters?: any | null, createdAt: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, createdAt: string, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', organizationId: string, id: string, profilePicture?: string | null, firstName: string, lastName: string } }> | null, commentModels?: Array<{ __typename?: 'EflowReportComment', showInReport: boolean, commentId: string }> | null, files?: Array<{ __typename?: 'FileModel', id: string, fileExtension: string, fileName: string, fileUrl: string }> | null, createdBy: { __typename?: 'Employee', organizationId: string, id: string, firstName: string, profilePicture?: string | null, lastName: string, email: string } } };

export type FindSingleReportQueryVariables = Exact<{
  input: FindReportInput;
}>;


export type FindSingleReportQuery = { __typename?: 'Query', findOneEflowReport: { __typename?: 'EFlowReport', eFlowId: string, version: number, operations: any, parameters?: any | null, operationTypesSummary?: any | null, comments?: Array<{ __typename?: 'EflowReportCommentDto', analizeType: AsIsToBeNamesEnum, operationName: string, stepNumber: number, comment: { __typename?: 'CommentDto', commentCategory?: string | null, content: string, createdAt: string, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string } }, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum } }> | null } };

export type GetAsIsToBeEflowParamsQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type GetAsIsToBeEflowParamsQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', asIsSteps?: Array<{ __typename?: 'EflowStep', id: string, isActive: boolean, parameters?: any | null, operation: { __typename?: 'OperationSchema', operationType: OperationTypesEnum } }> | null, toBeSteps?: Array<{ __typename?: 'EflowStep', id: string, isActive: boolean, parameters?: any | null, operation: { __typename?: 'OperationSchema', operationType: OperationTypesEnum } } | null> | null } };

export type GetAsIsToBeIdsQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type GetAsIsToBeIdsQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', id: string, asIsState?: Array<string> | null, toBeState?: Array<string> | null } };

export type GetAsIsToBeOperationSymbolsQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type GetAsIsToBeOperationSymbolsQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', asIsSteps?: Array<{ __typename?: 'EflowStep', id: string, isActive: boolean, operation: { __typename?: 'OperationSchema', operationType: OperationTypesEnum } }> | null, toBeSteps?: Array<{ __typename?: 'EflowStep', id: string, isActive: boolean, operation: { __typename?: 'OperationSchema', operationType: OperationTypesEnum } } | null> | null } };

export type GetCommentsQueryVariables = Exact<{
  input: FindOneEflowStepInput;
}>;


export type GetCommentsQuery = { __typename?: 'Query', findOneEflowStep: { __typename?: 'EflowStep', eFlowId: string, operationName: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', id: string } }> | null } };

export type GetEflowAsIsStepsQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type GetEflowAsIsStepsQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', id: string, asIsState?: Array<string> | null, asIsSteps?: Array<{ __typename?: 'EflowStep', id: string, eFlowId: string, isActive: boolean, operationName: string, parameters?: any | null, createdAt: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, createdAt: string, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', organizationId: string, id: string, profilePicture?: string | null, firstName: string, lastName: string } }> | null, commentModels?: Array<{ __typename?: 'EflowReportComment', showInReport: boolean, commentId: string }> | null, files?: Array<{ __typename?: 'FileModel', id: string, fileExtension: string, fileName: string, fileUrl: string }> | null, createdBy: { __typename?: 'Employee', organizationId: string, id: string, firstName: string, profilePicture?: string | null, lastName: string, email: string } }> | null } };

export type GetEflowNameAndParamsQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type GetEflowNameAndParamsQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', id: string, name: string, status: EFlowStatuses, parameters?: Array<{ __typename?: 'ParameterObject', name: string, type: ParameterTypes, unit: ParameterUnitsTypes }> | null } };

export type GetEflowToBeStepsQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type GetEflowToBeStepsQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', id: string, toBeState?: Array<string> | null, toBeSteps?: Array<{ __typename?: 'EflowStep', id: string, eFlowId: string, isActive: boolean, operationName: string, parameters?: any | null, createdAt: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, createdAt: string, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', organizationId: string, id: string, profilePicture?: string | null, firstName: string, lastName: string } }> | null, commentModels?: Array<{ __typename?: 'EflowReportComment', showInReport: boolean, commentId: string }> | null, files?: Array<{ __typename?: 'FileModel', id: string, fileExtension: string, fileName: string, fileUrl: string }> | null, createdBy: { __typename?: 'Employee', organizationId: string, id: string, firstName: string, profilePicture?: string | null, lastName: string, email: string } } | null> | null } };

export type FindEflowToBeStepsIdsQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type FindEflowToBeStepsIdsQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', id: string, toBeSteps?: Array<{ __typename?: 'EflowStep', id: string } | null> | null } };

export type GetOneEflowQueryVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type GetOneEflowQuery = { __typename?: 'Query', findOneEFlow: { __typename?: 'EFlow', name: string, startDate?: string | null, description: string, createdBy: { __typename?: 'Employee', firstName: string, lastName: string, profilePicture?: string | null }, analyzeLeaders?: Array<{ __typename?: 'Employee', id: string, organizationId: string, firstName: string, lastName: string, email: string }> | null, parameters?: Array<{ __typename?: 'ParameterObject', name: string, type: ParameterTypes, unit: ParameterUnitsTypes }> | null, operations?: Array<{ __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }> | null, commentTypes?: Array<{ __typename?: 'EflowCommentModel', categoryName: string, symbol: string }> | null, workGroupUsers: Array<{ __typename?: 'WorkGroupModel', id?: string | null, nameAndSurname?: string | null }> } };

export type SubscribeEflowChangeSubscriptionVariables = Exact<{
  input: FindOneEFlowInput;
}>;


export type SubscribeEflowChangeSubscription = { __typename?: 'Subscription', eFlowChanged: { __typename?: 'EflowSubscription', asIsState?: Array<string> | null, toBeState?: Array<string> | null } };

export type SubscribeEflowStepsChangeSubscriptionVariables = Exact<{
  input: SubscribeEflowStepsInput;
}>;


export type SubscribeEflowStepsChangeSubscription = { __typename?: 'Subscription', eFlowStepChanged: { __typename?: 'EflowStep', id: string, eFlowId: string, isActive: boolean, operationName: string, parameters?: any | null, createdAt: string, operation: { __typename?: 'OperationSchema', name: string, operationType: OperationTypesEnum }, comments?: Array<{ __typename?: 'EFlowStepCommentDto', id: string, commentCategory?: string | null, createdAt: string, content: string, showInReport: boolean, createdBy: { __typename?: 'Employee', organizationId: string, id: string, profilePicture?: string | null, firstName: string, lastName: string } }> | null, commentModels?: Array<{ __typename?: 'EflowReportComment', showInReport: boolean, commentId: string }> | null, files?: Array<{ __typename?: 'FileModel', id: string, fileExtension: string, fileName: string, fileUrl: string }> | null, createdBy: { __typename?: 'Employee', organizationId: string, id: string, firstName: string, profilePicture?: string | null, lastName: string, email: string } } };

export type EmployeeFragmentFragment = { __typename?: 'Employee', email: string, firstName: string, lastName: string, phone: string, organizationUnitGuid: string, position: string, id: string, organizationId: string, state: EmployeeState, systemRole: Array<SystemRoles>, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', id: string, name: string, organizationId: string, parentGuid?: string | null } };

export type NewCreatedSystemEmployeeFragment = { __typename?: 'Employee', id: string, email: string, firstName: string, lastName: string, systemRole: Array<SystemRoles>, organizationId: string, state: EmployeeState };

export type AddUserMutationMutationVariables = Exact<{
  createEmployeeInput: CreateEmployeeInput;
}>;


export type AddUserMutationMutation = { __typename?: 'Mutation', createEmployee: { __typename?: 'Employee', id: string, email: string, firstName: string, lastName: string, systemRole: Array<SystemRoles>, organizationId: string, state: EmployeeState } };

export type CreateEmployeeMutationVariables = Exact<{
  createEmployeeInput: CreateEmployeeInput;
}>;


export type CreateEmployeeMutation = { __typename?: 'Mutation', createEmployee: { __typename?: 'Employee', email: string, firstName: string, lastName: string, phone: string, organizationUnitGuid: string, position: string, id: string, organizationId: string, state: EmployeeState, systemRole: Array<SystemRoles>, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', id: string, name: string, organizationId: string, parentGuid?: string | null } } };

export type RemoveUserMutationVariables = Exact<{
  input: RemoveEmployeeInput;
}>;


export type RemoveUserMutation = { __typename?: 'Mutation', removeEmployee: { __typename?: 'OperationStatus', success: boolean } };

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'Employee', state: EmployeeState, id: string, firstName: string, lastName: string, email: string, organizationId: string, systemRole: Array<SystemRoles> } };

export type UpdateEmployeeUnitStatePositionMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeUnitStatePositionMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'Employee', position: string, state: EmployeeState, organizationUnit: { __typename?: 'OrganizationUnit', id: string } } };

export type UpdateEmployeeRoleMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeRoleMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'Employee', id: string, systemRole: Array<SystemRoles> } };

export type UpdateEmployeeStatusMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeStatusMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'Employee', systemRole: Array<SystemRoles> } };

export type UpdateEmployeeWithUnitDataMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeWithUnitDataMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, organizationId: string, organizationUnit: { __typename?: 'OrganizationUnit', name: string } } };

export type UpdateProfilePictureMutationVariables = Exact<{
  input: UpdateEmployeeProfileInput;
  file: Scalars['Upload']['input'];
}>;


export type UpdateProfilePictureMutation = { __typename?: 'Mutation', updateEmployeeProfilePicture: { __typename?: 'Employee', profilePicture?: string | null } };

export type UpdateUserNameSurnamePhoneMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateUserNameSurnamePhoneMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'Employee', id: string, email: string, firstName: string, lastName: string, phone: string } };

export type GetBasicSystemUserQueryVariables = Exact<{
  input: GetEmployee;
}>;


export type GetBasicSystemUserQuery = { __typename?: 'Query', getEmployee: { __typename?: 'GetSingleEmployeeDto', email: string, firstName: string, lastName: string, phone: string, organizationUnitPath?: any | null, organizationUnitGuid: string, position: string, id: string, organizationId: string, state: EmployeeState, systemRole: Array<SystemRoles>, profilePicture?: string | null } };

export type GetEmployeeQueryVariables = Exact<{
  input: GetEmployee;
}>;


export type GetEmployeeQuery = { __typename?: 'Query', getEmployee: { __typename?: 'GetSingleEmployeeDto', email: string, firstName: string, lastName: string, phone: string, organizationUnitPath?: any | null, organizationUnitGuid: string, position: string, id: string, organizationId: string, state: EmployeeState, systemRole: Array<SystemRoles>, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } } };

export type GetEmployeeProfilePictureQueryVariables = Exact<{
  input: GetEmployee;
}>;


export type GetEmployeeProfilePictureQuery = { __typename?: 'Query', getEmployee: { __typename?: 'GetSingleEmployeeDto', profilePicture?: string | null } };

export type GetEmployeeProfilePictureNameAndSurnameQueryVariables = Exact<{
  input: GetEmployee;
}>;


export type GetEmployeeProfilePictureNameAndSurnameQuery = { __typename?: 'Query', getEmployee: { __typename?: 'GetSingleEmployeeDto', profilePicture?: string | null, firstName: string, lastName: string, id: string } };

export type GetEmployeeSystemRolesQueryVariables = Exact<{
  input: GetEmployee;
}>;


export type GetEmployeeSystemRolesQuery = { __typename?: 'Query', getEmployee: { __typename?: 'GetSingleEmployeeDto', id: string, systemRole: Array<SystemRoles> } };

export type GetEmployeesForMultiSelectQueryVariables = Exact<{
  input: EmployeesListArgs;
}>;


export type GetEmployeesForMultiSelectQuery = { __typename?: 'Query', getEmployees: { __typename?: 'FindAllEmployees', total: number, items: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, organizationId: string, profilePicture?: string | null }> } };

export type GetEmployeesForOrganizationQueryVariables = Exact<{
  input: EmployeesListArgs;
}>;


export type GetEmployeesForOrganizationQuery = { __typename?: 'Query', getEmployees: { __typename?: 'FindAllEmployees', total: number, items: Array<{ __typename?: 'Employee', email: string, firstName: string, lastName: string, phone: string, organizationUnitGuid: string, position: string, id: string, organizationId: string, state: EmployeeState, systemRole: Array<SystemRoles>, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', id: string, name: string, organizationId: string, parentGuid?: string | null } }> } };

export type GetEmployeesForStructureQueryVariables = Exact<{
  getEmployeesInput: EmployeesListArgs;
}>;


export type GetEmployeesForStructureQuery = { __typename?: 'Query', getEmployees: { __typename?: 'FindAllEmployees', total: number, items: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, position: string, organizationId: string, profilePicture?: string | null }> } };

export type GetEmployeesWithOrganizationUnitQueryVariables = Exact<{
  getEmployeesInput: EmployeesListArgs;
}>;


export type GetEmployeesWithOrganizationUnitQuery = { __typename?: 'Query', getEmployees: { __typename?: 'FindAllEmployees', total: number, items: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, organizationId: string, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> } };

export type GetKeyUsersQueryVariables = Exact<{
  getEmployeesInput: EmployeesListArgs;
}>;


export type GetKeyUsersQuery = { __typename?: 'Query', getEmployees: { __typename?: 'FindAllEmployees', total: number, items: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, organizationUnitGuid: string }> } };

export type GetEmployeesQueryVariables = Exact<{
  getEmployeesInput: EmployeesListArgs;
}>;


export type GetEmployeesQuery = { __typename?: 'Query', getEmployees: { __typename?: 'FindAllEmployees', total: number, items: Array<{ __typename?: 'Employee', id: string, email: string, firstName: string, lastName: string, systemRole: Array<SystemRoles>, organizationId: string, state: EmployeeState }> } };

export type CommissionMemberFragmentFragment = { __typename?: 'CommissionMemberDto', id: string, firstName: string, lastName: string, email: string, commissionRole: string };

export type KaizenSubmissionFragmentFragment = { __typename?: 'KaizenSubmissionDto', id: string, status: KaizenStatuses, name: string, updatedAt: string, createdAt: string, assignedPoints: number, settlementPeriodId: string, submissionKey: string, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }> | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null };

export type KaizenSettlementPeriodFragment = { __typename?: 'KaizenSettlementPeriodDto', id: string, periodName: string, isActive: boolean, organizationId: string };

export type KaizenSubmissionFragment = { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null };

export type SubmissionProcessingTimeFragmentFragment = { __typename?: 'KaizenSubmissionProcessingTimeDto', ACCEPTED: number, ADJUSTMENT: number, COMMISSION_ASSESSMENT: number, IMPLEMENTATION_REJECTED: number, IN_PROGRESS: number, SUPERVISOR_ASSESSMENT: number, TODO: number };

export type SubmissionStatusCountFragmentFragment = { __typename?: 'KaizenSubmissionNumberDto', ACCEPTED: number, ADJUSTMENT: number, COMMISSION_ASSESSMENT: number, IMPLEMENTATION_ACCEPTED: number, IMPLEMENTATION_REJECTED: number, IMPLEMENTED: number, IN_PROGRESS: number, SUPERVISOR_ASSESSMENT: number, TODO: number };

export type SendKaizenSubmissionToAcceptMutationVariables = Exact<{
  input: ChangeSubmissionStatusInput;
}>;


export type SendKaizenSubmissionToAcceptMutation = { __typename?: 'Mutation', acceptKaizenSubmission: { __typename?: 'KaizenSubmissionDto', id: string, status: KaizenStatuses, name: string, updatedAt: string, createdAt: string, assignedPoints: number, settlementPeriodId: string, submissionKey: string, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }> | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type AcceptSubmissionImplementationMutationVariables = Exact<{
  input: ChangeSubmissionStatusInput;
}>;


export type AcceptSubmissionImplementationMutation = { __typename?: 'Mutation', setKaizenSubmissionImplementedStatus: { __typename?: 'KaizenSubmissionDto', id: string, status: KaizenStatuses, name: string, updatedAt: string, createdAt: string, assignedPoints: number, settlementPeriodId: string, submissionKey: string, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }> | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type AddCommissionMemberMutationVariables = Exact<{
  input: AddCommissionMemberInput;
}>;


export type AddCommissionMemberMutation = { __typename?: 'Mutation', addCommissionMember: { __typename?: 'OperationStatus', success: boolean } };

export type CreateSettlementPeriodMutationVariables = Exact<{
  input: CreateSettlementPeriodInput;
}>;


export type CreateSettlementPeriodMutation = { __typename?: 'Mutation', createSettlementPeriod: { __typename?: 'KaizenSettlementPeriodDto', id: string, periodName: string, isActive: boolean, organizationId: string } };

export type AssignSubmissionToRealizationMutationVariables = Exact<{
  input: AddSubmissionToRealisationInput;
}>;


export type AssignSubmissionToRealizationMutation = { __typename?: 'Mutation', addKaizenSubmissionToRealisation: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type CancelSubmissionMutationVariables = Exact<{
  input: ChangeSubmissionStatusInput;
}>;


export type CancelSubmissionMutation = { __typename?: 'Mutation', kaizenCancelSubmission: { __typename?: 'KaizenSubmissionDto', id: string, status: KaizenStatuses, name: string, updatedAt: string, createdAt: string, assignedPoints: number, settlementPeriodId: string, submissionKey: string, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }> | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type ChangeKaizenSubmissionStatusMutationVariables = Exact<{
  input: ChangeKaizenSubmissionRootStatusInput;
}>;


export type ChangeKaizenSubmissionStatusMutation = { __typename?: 'Mutation', changeKaizenSubmissionStatus: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type CreateKaizenScoringMutationVariables = Exact<{
  input: CreateKaizenScoringInput;
}>;


export type CreateKaizenScoringMutation = { __typename?: 'Mutation', createKaizenScoring: { __typename?: 'KaizenScoring', id: string, createdAt: string, scoring: any } };

export type CreateKaizenSubmissionMutationVariables = Exact<{
  input: CreateKaizenSubmissionInput;
  problemFiles?: InputMaybe<Array<Scalars['Upload']['input']> | Scalars['Upload']['input']>;
  solutionFiles?: InputMaybe<Array<Scalars['Upload']['input']> | Scalars['Upload']['input']>;
}>;


export type CreateKaizenSubmissionMutation = { __typename?: 'Mutation', createKaizenSubmission: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type SetKaizenSubmissionImplementationAcceptedStatusMutationVariables = Exact<{
  input: ChangeSubmissionStatusInput;
}>;


export type SetKaizenSubmissionImplementationAcceptedStatusMutation = { __typename?: 'Mutation', setKaizenSubmissionImplementationAcceptedStatus: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type SetKaizenSubmissionInProgressStatusMutationVariables = Exact<{
  input: ChangeSubmissionStatusInput;
}>;


export type SetKaizenSubmissionInProgressStatusMutation = { __typename?: 'Mutation', setKaizenSubmissionInProgressStatus: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type RejectSubmissionImplementationMutationVariables = Exact<{
  input: RejectKaizenSubmissionInput;
}>;


export type RejectSubmissionImplementationMutation = { __typename?: 'Mutation', rejectSubmissionImplementation: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type RejectSingleSubmissionMutationVariables = Exact<{
  input: RejectKaizenSubmissionInput;
}>;


export type RejectSingleSubmissionMutation = { __typename?: 'Mutation', rejectSingleSubmission: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type RemoveCommissionMemberMutationVariables = Exact<{
  input: RemoveCommissionMemberInput;
}>;


export type RemoveCommissionMemberMutation = { __typename?: 'Mutation', removeCommissionMember: { __typename?: 'OperationStatus', success: boolean } };

export type RemoveKaizenSubmissionMutationVariables = Exact<{
  input: RemoveKaizenSubmissionInput;
}>;


export type RemoveKaizenSubmissionMutation = { __typename?: 'Mutation', removeKaizenSubmission: { __typename?: 'OperationStatus', success: boolean } };

export type RemoveSettlementPeriodMutationVariables = Exact<{
  input: RemoveSettlementPeriodInput;
}>;


export type RemoveSettlementPeriodMutation = { __typename?: 'Mutation', removeSettlementPeriod: { __typename?: 'OperationStatus', success: boolean } };

export type SendToAdjustmentMutationVariables = Exact<{
  input: SendToAdjustmentKaizenSubmissionInput;
}>;


export type SendToAdjustmentMutation = { __typename?: 'Mutation', sendToAdjustment: { __typename?: 'KaizenSubmissionDto', id: string, status: KaizenStatuses, name: string, updatedAt: string, createdAt: string, assignedPoints: number, settlementPeriodId: string, submissionKey: string, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }> | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type SetCommissionLeaderMutationVariables = Exact<{
  input: SetCommissionLeaderInput;
}>;


export type SetCommissionLeaderMutation = { __typename?: 'Mutation', setCommissionLeader: { __typename?: 'OperationStatus', success: boolean } };

export type SetKaizenSubmissionCommissionAssessmentStatusMutationVariables = Exact<{
  input: ChangeSubmissionStatusInput;
}>;


export type SetKaizenSubmissionCommissionAssessmentStatusMutation = { __typename?: 'Mutation', setKaizenSubmissionCommissionAssessmentStatus: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type SetKaizenSubmissionSupervisorAssessmentStatusMutationVariables = Exact<{
  input: ChangeSubmissionStatusInput;
}>;


export type SetKaizenSubmissionSupervisorAssessmentStatusMutation = { __typename?: 'Mutation', setKaizenSubmissionSupervisorAssessmentStatus: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type UpdateKaizenSubmissionMutationVariables = Exact<{
  input: UpdateKaizenSubmissionInput;
  problemFiles?: InputMaybe<Array<Scalars['Upload']['input']> | Scalars['Upload']['input']>;
  solutionFiles?: InputMaybe<Array<Scalars['Upload']['input']> | Scalars['Upload']['input']>;
}>;


export type UpdateKaizenSubmissionMutation = { __typename?: 'Mutation', updateKaizenSubmission: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type UpdateSettlementPeriodMutationVariables = Exact<{
  input: UpdateSettlementPeriodInput;
}>;


export type UpdateSettlementPeriodMutation = { __typename?: 'Mutation', updateSettlementPeriod: { __typename?: 'KaizenSettlementPeriodDto', id: string, periodName: string, isActive: boolean, organizationId: string } };

export type FindAllSubmissionsQueryVariables = Exact<{
  input: FindAllSubmissionsInput;
}>;


export type FindAllSubmissionsQuery = { __typename?: 'Query', findAllKaizenSubmissions: { __typename?: 'FindAllKaizenSubmissionsDto', total: number, items: Array<{ __typename?: 'KaizenSubmissionDto', id: string, status: KaizenStatuses, name: string, updatedAt: string, createdAt: string, assignedPoints: number, settlementPeriodId: string, submissionKey: string, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }> | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null }> } };

export type FindDefaultSubmissionInfoQueryVariables = Exact<{
  input: FindKaizenSubmissionInput;
}>;


export type FindDefaultSubmissionInfoQuery = { __typename?: 'Query', findSingleSubmission: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationUnitId: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null } };

export type FindKaizenModuleInfoQueryVariables = Exact<{
  input: FindModuleInfoInput;
}>;


export type FindKaizenModuleInfoQuery = { __typename?: 'Query', findKaizenModuleInfo: { __typename?: 'KaizenModule', scoringSet?: { __typename?: 'KaizenScoring', scoring: any } | null, currentSettlementPeriod?: { __typename?: 'KaizenSettlementPeriodDto', id: string, periodName: string } | null, submissionFlow?: { __typename?: 'KaizenSubmissionFlowDto', id: string } | null } };

export type FindSingleKaizenReportQueryVariables = Exact<{
  input: FindSingleReportInput;
}>;


export type FindSingleKaizenReportQuery = { __typename?: 'Query', findSingleKaizenReport: { __typename?: 'KaizenSubmissionReportDto', avgProcessingTime: number, organizationId: string, totalNumberOfSubmissions: number, totalParticipantsNumber: number, submissionProcessingTime: { __typename?: 'KaizenSubmissionProcessingTimeDto', ACCEPTED: number, ADJUSTMENT: number, COMMISSION_ASSESSMENT: number, IMPLEMENTATION_REJECTED: number, IN_PROGRESS: number, SUPERVISOR_ASSESSMENT: number, TODO: number }, submissionStatusCount: { __typename?: 'KaizenSubmissionNumberDto', ACCEPTED: number, ADJUSTMENT: number, COMMISSION_ASSESSMENT: number, IMPLEMENTATION_ACCEPTED: number, IMPLEMENTATION_REJECTED: number, IMPLEMENTED: number, IN_PROGRESS: number, SUPERVISOR_ASSESSMENT: number, TODO: number } } };

export type FindKaizenSubmissionFlowQueryVariables = Exact<{
  input: FindModuleInfoInput;
}>;


export type FindKaizenSubmissionFlowQuery = { __typename?: 'Query', findKaizenModuleInfo: { __typename?: 'KaizenModule', submissionFlow?: { __typename?: 'KaizenSubmissionFlowDto', id: string, ACCEPTED: Array<KaizenStatuses>, ADJUSTMENT: Array<KaizenStatuses>, COMMISSION_ASSESSMENT: Array<KaizenStatuses>, DRAFT: Array<KaizenStatuses>, IMPLEMENTATION_ACCEPTED: Array<KaizenStatuses>, IMPLEMENTATION_REJECTED: Array<KaizenStatuses>, IMPLEMENTED: Array<KaizenStatuses>, IN_PROGRESS: Array<KaizenStatuses>, REJECTED: Array<KaizenStatuses>, SUPERVISOR_ASSESSMENT: Array<KaizenStatuses>, TODO: Array<KaizenStatuses>, organizationId: string } | null } };

export type FindSingleSubmissionInfoQueryVariables = Exact<{
  input: FindKaizenSubmissionInput;
}>;


export type FindSingleSubmissionInfoQuery = { __typename?: 'Query', findSingleSubmission: { __typename?: 'KaizenSubmissionDto', id: string, name: string, version: number, organizationId: string, organizationUnitId: string, assignedPoints: number, createdAt: string, passedStatuses: Array<KaizenStatuses>, processingTime: number, settlementPeriodId: string, updatedAt: string, submissionKey: string, status: KaizenStatuses, organizationUnit: { __typename?: 'OrganizationUnit', name: string }, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }, problem: { __typename?: 'KaizenProblemDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, solution: { __typename?: 'KaizenSolutionDto', description: string, files?: Array<{ __typename?: 'FileModel', fileName: string, fileExtension: string, fileUrl: string, id: string, organizationId: string } | null> | null }, authors?: Array<{ __typename?: 'Employee', id: string, firstName: string, lastName: string, email: string, profilePicture?: string | null, organizationUnit: { __typename?: 'OrganizationUnit', name: string } }> | null, comments?: Array<{ __typename?: 'CommentDto', id: string, createdAt: string, content: string, commentCategory?: string | null, createdBy: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } }> | null, assignedContractor?: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null } | null } };

export type GetCommissionMembersQueryVariables = Exact<{
  input: GetCommissionMemberInput;
}>;


export type GetCommissionMembersQuery = { __typename?: 'Query', getCommissionMembers: { __typename?: 'CommissionMembers', total: number, items: Array<{ __typename?: 'CommissionMemberDto', id: string, firstName: string, lastName: string, email: string, commissionRole: string }> } };

export type GetKaizenRanksQueryVariables = Exact<{
  input: GetKaizenRanksInput;
}>;


export type GetKaizenRanksQuery = { __typename?: 'Query', getKaizenRanks: { __typename?: 'GetKaizenRanksDto', total: number, items: Array<{ __typename?: 'KaizenRanksDto', submissionNumber: number, totalPoints: number, employee: { __typename?: 'Employee', profilePicture?: string | null, firstName: string, lastName: string, id: string } }> } };

export type GetSettlementPeriodSubmissionNumberQueryVariables = Exact<{
  input: GetSettlementPeriodSubmissionNumberInput;
}>;


export type GetSettlementPeriodSubmissionNumberQuery = { __typename?: 'Query', getSettlementPeriodSubmissionNumber: { __typename?: 'GetSettlementPeriodSubmissionNumberDto', total: number } };

export type GetAllSettlementPeriodsQueryVariables = Exact<{
  input: FindAllSettlementPeriodsInput;
}>;


export type GetAllSettlementPeriodsQuery = { __typename?: 'Query', findAllSettlementPeriods: { __typename?: 'FindAllSettlementPeriodsDto', organizationId: string, settlementPeriods: Array<{ __typename?: 'KaizenSettlementPeriodDto', id: string, periodName: string, isActive: boolean, organizationId: string }> } };

export type FindAllSubUnitsStructureDtoFragmentFragment = { __typename?: 'FindAllSubUnitsStructureDto', rootUnit: { __typename?: 'SingleSubunitDto', id: string, name: string }, subunits: Array<{ __typename?: 'SingleSubunitDto', id: string, name: string }> };

export type KpiRateFragment = { __typename?: 'KpiRateDto', assigneeEmployeeId: string, organizationUnitId: string, dueToDayTimeout: number, entries?: any | null, hasTarget: boolean, id: string, organizationId: string, rateDescription?: string | null, rateName: string, targetData?: any | null, targetType?: KpiRatingTargetType | null, assignee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, organizationUnit: { __typename?: 'OrganizationUnit', id: string, name: string }, unit: { __typename?: 'KpiUnitModelDto', unitName: string, unitShortcut: string } };

export type KpiSettlementPeriodFragment = { __typename?: 'KpiSettlementPeriodDto', id: string, periodName: string, isActive: boolean, settlementPeriodYear?: string | null };

export type KpiUnitModelFragment = { __typename?: 'KpiUnitModelDto', unitName: string, unitShortcut: string };

export type CreateKpiChartMutationVariables = Exact<{
  input: CreateKpiChartInput;
}>;


export type CreateKpiChartMutation = { __typename?: 'Mutation', createKpiChart: { __typename?: 'DashboardDto', id: string, organizationId: string, widgets: Array<{ __typename?: 'DashboardWidgetDto', layoutModel: any, widgetType: SupportedWidgetsEnum, resourceLink: any, layoutKey: string, isHidden: boolean }> } };

export type CreateKpiRateMutationVariables = Exact<{
  input: CreateKpiRateInput;
}>;


export type CreateKpiRateMutation = { __typename?: 'Mutation', createKpiRate: { __typename?: 'KpiRateDto', assigneeEmployeeId: string, organizationUnitId: string, dueToDayTimeout: number, entries?: any | null, hasTarget: boolean, id: string, organizationId: string, rateDescription?: string | null, rateName: string, targetData?: any | null, targetType?: KpiRatingTargetType | null, assignee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, organizationUnit: { __typename?: 'OrganizationUnit', id: string, name: string }, unit: { __typename?: 'KpiUnitModelDto', unitName: string, unitShortcut: string } } };

export type CreateKpiSettlementPeriodMutationVariables = Exact<{
  input: CreateKpiSettlementPeriodInput;
}>;


export type CreateKpiSettlementPeriodMutation = { __typename?: 'Mutation', createKpiSettlementPeriod: { __typename?: 'KpiSettlementPeriodDto', id: string, periodName: string, isActive: boolean, settlementPeriodYear?: string | null } };

export type RemoveKpiRateMutationVariables = Exact<{
  input: RemoveKpiInput;
}>;


export type RemoveKpiRateMutation = { __typename?: 'Mutation', removeKpiRate: { __typename?: 'OperationStatus', success: boolean } };

export type ReportKpiRateEntryMutationVariables = Exact<{
  input: ReportKpiRateEntryInput;
}>;


export type ReportKpiRateEntryMutation = { __typename?: 'Mutation', reportKpiRateEntry: { __typename?: 'OperationStatus', success: boolean } };

export type UpdateKpiRateMutationVariables = Exact<{
  input: UpdateKpiRateInput;
}>;


export type UpdateKpiRateMutation = { __typename?: 'Mutation', updateKpiRate: { __typename?: 'KpiRateDto', assigneeEmployeeId: string, organizationUnitId: string, dueToDayTimeout: number, entries?: any | null, hasTarget: boolean, id: string, organizationId: string, rateDescription?: string | null, rateName: string, targetData?: any | null, targetType?: KpiRatingTargetType | null, assignee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, organizationUnit: { __typename?: 'OrganizationUnit', id: string, name: string }, unit: { __typename?: 'KpiUnitModelDto', unitName: string, unitShortcut: string } } };

export type UpdateKpiSettlementPeriodMutationVariables = Exact<{
  input: UpdateKpiSettlementPeriodInput;
}>;


export type UpdateKpiSettlementPeriodMutation = { __typename?: 'Mutation', updateKpiSettlementPeriod: { __typename?: 'KpiSettlementPeriodDto', id: string, periodName: string, isActive: boolean, settlementPeriodYear?: string | null } };

export type FindAllKpiSettlementPeriodsQueryVariables = Exact<{
  input: FindAllKpiSettlementPeriodsInput;
}>;


export type FindAllKpiSettlementPeriodsQuery = { __typename?: 'Query', findAllKpiSettlementPeriods: { __typename?: 'FindAllKpiSettlementPeriodsDto', settlementPeriods: Array<{ __typename?: 'KpiSettlementPeriodDto', id: string, periodName: string, isActive: boolean, settlementPeriodYear?: string | null }> } };

export type FindAllKpisQueryVariables = Exact<{
  input: FindKpiRateInput;
}>;


export type FindAllKpisQuery = { __typename?: 'Query', findAllKpis: { __typename?: 'FindAllKpiDto', total: number, items: Array<{ __typename?: 'KpiRateDto', assigneeEmployeeId: string, organizationUnitId: string, dueToDayTimeout: number, entries?: any | null, hasTarget: boolean, id: string, organizationId: string, rateDescription?: string | null, rateName: string, targetData?: any | null, targetType?: KpiRatingTargetType | null, assignee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, organizationUnit: { __typename?: 'OrganizationUnit', id: string, name: string }, unit: { __typename?: 'KpiUnitModelDto', unitName: string, unitShortcut: string } }> } };

export type FindKpiUnitsQueryVariables = Exact<{
  input: FindAllKpiUnitsInput;
}>;


export type FindKpiUnitsQuery = { __typename?: 'Query', findKpiUnits: { __typename?: 'FindKpiUnitsDto', units: Array<{ __typename?: 'KpiUnitModelDto', unitName: string, unitShortcut: string }> } };

export type FindManyKpisQueryVariables = Exact<{
  input: FindManyRateInput;
}>;


export type FindManyKpisQuery = { __typename?: 'Query', findManyKpis: { __typename?: 'FindManyKpisDto', items: Array<{ __typename?: 'KpiRateDto', assigneeEmployeeId: string, organizationUnitId: string, dueToDayTimeout: number, entries?: any | null, hasTarget: boolean, id: string, organizationId: string, rateDescription?: string | null, rateName: string, targetData?: any | null, targetType?: KpiRatingTargetType | null, assignee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, organizationUnit: { __typename?: 'OrganizationUnit', id: string, name: string }, unit: { __typename?: 'KpiUnitModelDto', unitName: string, unitShortcut: string } }> } };

export type FindOrganizationSubunitsQueryVariables = Exact<{
  input: FindOrganizationUnitSubunitsInput;
}>;


export type FindOrganizationSubunitsQuery = { __typename?: 'Query', findOrganizationSubunits: { __typename?: 'FindAllSubUnitsStructureDto', rootUnit: { __typename?: 'SingleSubunitDto', id: string, name: string }, subunits: Array<{ __typename?: 'SingleSubunitDto', id: string, name: string }> } };

export type GetKpiRateQueryVariables = Exact<{
  input: GetKpiRateInput;
}>;


export type GetKpiRateQuery = { __typename?: 'Query', getKpiRate: { __typename?: 'KpiRateDto', assigneeEmployeeId: string, organizationUnitId: string, dueToDayTimeout: number, entries?: any | null, hasTarget: boolean, id: string, organizationId: string, rateDescription?: string | null, rateName: string, targetData?: any | null, targetType?: KpiRatingTargetType | null, assignee: { __typename?: 'Employee', id: string, firstName: string, lastName: string, profilePicture?: string | null }, organizationUnit: { __typename?: 'OrganizationUnit', id: string, name: string }, unit: { __typename?: 'KpiUnitModelDto', unitName: string, unitShortcut: string } } };

export type GetRelatedKpiChartsQueryVariables = Exact<{
  input: FindRelatedResourcesInput;
}>;


export type GetRelatedKpiChartsQuery = { __typename?: 'Query', getRelatedKpiCharts: { __typename?: 'RelatedResourcesDto', totalRelatedResources: number, relatedResources: Array<{ __typename?: 'RelatedResourceDto', resourceId: string, resourceName: string }> } };

export type AddKeyUserToOrganizationMutationVariables = Exact<{
  input: OrganizationKeyUserInput;
}>;


export type AddKeyUserToOrganizationMutation = { __typename?: 'Mutation', addKeyUserToOrganization: { __typename?: 'OperationStatus', success: boolean } };

export type CreateOrganisationMutationVariables = Exact<{
  input: CreateOrganisationInput;
  file?: InputMaybe<Scalars['Upload']['input']>;
}>;


export type CreateOrganisationMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'Organization', id: string } };

export type DeleteSingleOrganizationMutationVariables = Exact<{
  input: RemoveOrganizationInput;
}>;


export type DeleteSingleOrganizationMutation = { __typename?: 'Mutation', removeOrganisation: { __typename?: 'OperationStatus', success: boolean } };

export type RemoveKeyUserFromOrganizationMutationVariables = Exact<{
  input: OrganizationKeyUserInput;
}>;


export type RemoveKeyUserFromOrganizationMutation = { __typename?: 'Mutation', removeKeyUserFromOrganization: { __typename?: 'OperationStatus', success: boolean } };

export type SetOrganizationPositionsMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type SetOrganizationPositionsMutation = { __typename?: 'Mutation', updateOrganisation: { __typename?: 'Organization', positions?: Array<string> | null } };

export type UpdateOrganizationLogoMutationVariables = Exact<{
  input: UpdateOrganizationInput;
  file?: InputMaybe<Scalars['Upload']['input']>;
}>;


export type UpdateOrganizationLogoMutation = { __typename?: 'Mutation', updateOrganisation: { __typename?: 'Organization', logo?: string | null } };

export type UpdateOrganizationModulesMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type UpdateOrganizationModulesMutation = { __typename?: 'Mutation', updateOrganisation: { __typename?: 'Organization', modules: Array<OrganizationModules> } };

export type UpdateOrganizationNameMutationVariables = Exact<{
  input: UpdateOrganizationInput;
}>;


export type UpdateOrganizationNameMutation = { __typename?: 'Mutation', updateOrganisation: { __typename?: 'Organization', name: string, id: string } };

export type UpdatePositionsMutationVariables = Exact<{
  input: UpdatePositionsInput;
}>;


export type UpdatePositionsMutation = { __typename?: 'Mutation', updatePositions: { __typename?: 'Organization', id: string, positions?: Array<string> | null } };

export type FindAllOrganizationsQueryVariables = Exact<{
  paginationInput: FindAllOrganizationsInput;
}>;


export type FindAllOrganizationsQuery = { __typename?: 'Query', findAllOrganizations: { __typename?: 'FindAllOrganizations', total: number, items: Array<{ __typename?: 'Organization', id: string, name: string, createdAt?: string | null, url: string, modules: Array<OrganizationModules>, logo?: string | null }> } };

export type FindSingleOrganizationQueryVariables = Exact<{
  input: FindOrganizationInput;
}>;


export type FindSingleOrganizationQuery = { __typename?: 'Query', findSingleOrganization: { __typename?: 'Organization', name: string, url: string, id: string, modules: Array<OrganizationModules>, logo?: string | null } };

export type FindSingleOrganizationPositionsQueryVariables = Exact<{
  input: FindOrganizationInput;
}>;


export type FindSingleOrganizationPositionsQuery = { __typename?: 'Query', findSingleOrganization: { __typename?: 'Organization', positions?: Array<string> | null } };

export type FindSingleOrganizationModulesQueryVariables = Exact<{
  input: FindOrganizationInput;
}>;


export type FindSingleOrganizationModulesQuery = { __typename?: 'Query', findSingleOrganization: { __typename?: 'Organization', modules: Array<OrganizationModules> } };

export type CreateOrganizationUnitMutationVariables = Exact<{
  createOrganizationUnitInput: CreateOrganizationUnitInput;
}>;


export type CreateOrganizationUnitMutation = { __typename?: 'Mutation', createOrganizationUnit: { __typename?: 'OrganizationUnit', name: string, parentGuid?: string | null, id: string } };

export type OrganizationUnitFragmentFragment = { __typename?: 'OrganizationUnit', name: string, parentGuid?: string | null, id: string };

export type DeleteOrganizationUnitMutationVariables = Exact<{
  deleteOrganizationUnitInput: DeleteOrganizationInput;
}>;


export type DeleteOrganizationUnitMutation = { __typename?: 'Mutation', deleteOrganizationUnit: { __typename?: 'DeleteOrganizationUnitDto', success: boolean, children?: { __typename?: 'DeleteOrganizationUnitChildren', employees: number, organizationUnits: number } | null } };

export type UpdateOrganizationUnitMutationVariables = Exact<{
  updateOrganizationUnitInput: UpdateOrganizationUnitDto;
}>;


export type UpdateOrganizationUnitMutation = { __typename?: 'Mutation', updateOrganizationUnit: { __typename?: 'OrganizationUnit', name: string, parentGuid?: string | null, id: string } };

export type GetCheckboxOrganizationUnitStructureQueryVariables = Exact<{
  input: GetOrganizationUnitInput;
}>;


export type GetCheckboxOrganizationUnitStructureQuery = { __typename?: 'Query', getOrganizationUnitsStructure: { __typename?: 'OrganizationUnitWithChildren', children?: any | null, id: string, name: string, parentGuid?: string | null } };

export type GetOrganizationUnitChildrenNameQueryVariables = Exact<{
  getOrganizationUnitInput: GetOrganizationUnitInput;
}>;


export type GetOrganizationUnitChildrenNameQuery = { __typename?: 'Query', getOrganizationUnitsStructure: { __typename?: 'OrganizationUnitWithChildren', id: string, name: string, children?: any | null } };

export type GetOrganizationUnitStructureQueryVariables = Exact<{
  getOrganizationUnitInput: GetOrganizationUnitInput;
}>;


export type GetOrganizationUnitStructureQuery = { __typename?: 'Query', getOrganizationUnitsStructure: { __typename?: 'OrganizationUnitWithChildren', children?: any | null, id: string, name: string, parentGuid?: string | null, members?: Array<{ __typename?: 'Employee', firstName: string, lastName: string, position: string, profilePicture?: string | null, systemRole: Array<SystemRoles> }> | null } };

export type ModifyPermissionsMutationVariables = Exact<{
  input: ModifyPermissionInput;
}>;


export type ModifyPermissionsMutation = { __typename?: 'Mutation', modifyPermissions: { __typename?: 'OperationStatus', success: boolean } };

export const ChartFragmentDoc = gql`
    fragment Chart on ChartDto {
  id
  chartName
  dataSet
  chartType
  dataSourceSet
  datasetType
  displayOptions {
    showDataAsPercent
    showTarget
    sumIndicators
    showDataInAscendingOrder
    legendType
    showDetailedLegend
    showDataLabels
  }
  isError
  additionalInfo
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on SingleNotificationDto {
  id
  isOpened
  body
  notificationTemplate
  sentAt
}
    `;
export const DashboardFragmentDoc = gql`
    fragment Dashboard on DashboardDto {
  id
  organizationId
  widgets {
    layoutModel
    widgetType
    resourceLink
    layoutKey
    isHidden
  }
}
    `;
export const EflowStepFragmentFragmentDoc = gql`
    fragment EflowStepFragment on EflowStep {
  id
  eFlowId
  isActive
  operationName
  operation {
    name
    operationType
  }
  comments {
    id
    commentCategory
    createdAt
    createdBy {
      organizationId
      id
      profilePicture
      firstName
      lastName
    }
    content
    showInReport
  }
  commentModels {
    showInReport
    commentId
  }
  parameters
  files {
    id
    fileExtension
    fileName
    fileUrl
  }
  createdBy {
    organizationId
    id
    firstName
    profilePicture
    lastName
    email
  }
  createdAt
}
    `;
export const SingleEflowFragmentDoc = gql`
    fragment SingleEflow on EFlow {
  analyzeLeaders {
    id
    firstName
    lastName
    profilePicture
  }
  createdAt
  description
  id
  name
  status
  createdBy {
    firstName
    lastName
  }
  startDate
}
    `;
export const SingleEflowFragmentFragmentDoc = gql`
    fragment SingleEflowFragment on EFlow {
  id
  name
  parameters {
    name
    type
    unit
  }
  operations {
    name
    operationType
  }
  commentTypes {
    categoryName
    symbol
  }
}
    `;
export const EmployeeFragmentFragmentDoc = gql`
    fragment EmployeeFragment on Employee {
  email
  firstName
  lastName
  phone
  organizationUnit {
    id
    name
    organizationId
    parentGuid
  }
  organizationUnitGuid
  position
  id
  organizationId
  state
  systemRole
  profilePicture
}
    `;
export const NewCreatedSystemEmployeeFragmentDoc = gql`
    fragment NewCreatedSystemEmployee on Employee {
  id
  email
  firstName
  lastName
  systemRole
  organizationId
  state
}
    `;
export const CommissionMemberFragmentFragmentDoc = gql`
    fragment CommissionMemberFragment on CommissionMemberDto {
  id
  firstName
  lastName
  email
  commissionRole
}
    `;
export const KaizenSubmissionFragmentFragmentDoc = gql`
    fragment KaizenSubmissionFragment on KaizenSubmissionDto {
  authors {
    id
    firstName
    lastName
    profilePicture
  }
  id
  status
  name
  updatedAt
  createdAt
  assignedPoints
  settlementPeriodId
  submissionKey
  createdBy {
    id
    firstName
    lastName
    profilePicture
  }
  assignedContractor {
    id
    firstName
    lastName
    profilePicture
  }
}
    `;
export const KaizenSettlementPeriodFragmentDoc = gql`
    fragment KaizenSettlementPeriod on KaizenSettlementPeriodDto {
  id
  periodName
  isActive
  organizationId
}
    `;
export const KaizenSubmissionFragmentDoc = gql`
    fragment KaizenSubmission on KaizenSubmissionDto {
  id
  name
  version
  organizationId
  organizationUnitId
  organizationUnit {
    name
  }
  assignedPoints
  createdAt
  createdBy {
    id
    firstName
    lastName
    email
    organizationUnit {
      name
    }
    profilePicture
  }
  passedStatuses
  processingTime
  settlementPeriodId
  updatedAt
  problem {
    description
    files {
      fileName
      fileExtension
      fileUrl
      id
      organizationId
    }
  }
  submissionKey
  status
  solution {
    description
    files {
      fileName
      fileExtension
      fileUrl
      id
      organizationId
    }
  }
  authors {
    id
    firstName
    lastName
    email
    organizationUnit {
      name
    }
    profilePicture
  }
  comments {
    id
    createdBy {
      id
      firstName
      lastName
      profilePicture
    }
    createdAt
    content
    commentCategory
  }
  assignedContractor {
    id
    firstName
    lastName
    profilePicture
  }
}
    `;
export const SubmissionProcessingTimeFragmentFragmentDoc = gql`
    fragment SubmissionProcessingTimeFragment on KaizenSubmissionProcessingTimeDto {
  ACCEPTED
  ADJUSTMENT
  COMMISSION_ASSESSMENT
  IMPLEMENTATION_REJECTED
  IN_PROGRESS
  SUPERVISOR_ASSESSMENT
  TODO
}
    `;
export const SubmissionStatusCountFragmentFragmentDoc = gql`
    fragment SubmissionStatusCountFragment on KaizenSubmissionNumberDto {
  ACCEPTED
  ADJUSTMENT
  COMMISSION_ASSESSMENT
  IMPLEMENTATION_ACCEPTED
  IMPLEMENTATION_REJECTED
  IMPLEMENTED
  IN_PROGRESS
  SUPERVISOR_ASSESSMENT
  TODO
}
    `;
export const FindAllSubUnitsStructureDtoFragmentFragmentDoc = gql`
    fragment FindAllSubUnitsStructureDtoFragment on FindAllSubUnitsStructureDto {
  rootUnit {
    id
    name
  }
  subunits {
    id
    name
  }
}
    `;
export const KpiRateFragmentDoc = gql`
    fragment KpiRate on KpiRateDto {
  assignee {
    id
    firstName
    lastName
    profilePicture
  }
  assigneeEmployeeId
  organizationUnitId
  dueToDayTimeout
  entries
  hasTarget
  id
  organizationId
  organizationUnit {
    id
    name
  }
  rateDescription
  rateName
  targetData
  targetType
  unit {
    unitName
    unitShortcut
  }
}
    `;
export const KpiSettlementPeriodFragmentDoc = gql`
    fragment KpiSettlementPeriod on KpiSettlementPeriodDto {
  id
  periodName
  isActive
  settlementPeriodYear
}
    `;
export const KpiUnitModelFragmentDoc = gql`
    fragment KpiUnitModel on KpiUnitModelDto {
  unitName
  unitShortcut
}
    `;
export const OrganizationUnitFragmentFragmentDoc = gql`
    fragment OrganizationUnitFragment on OrganizationUnit {
  name
  parentGuid
  id
}
    `;
export const FindChartDocument = gql`
    query FindChart($input: FindChartInput!) {
  findChart(findChartInput: $input) {
    ...Chart
  }
}
    ${ChartFragmentDoc}`;

/**
 * __useFindChartQuery__
 *
 * To run a query within a React component, call `useFindChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindChartQuery(baseOptions: Apollo.QueryHookOptions<FindChartQuery, FindChartQueryVariables> & ({ variables: FindChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindChartQuery, FindChartQueryVariables>(FindChartDocument, options);
      }
export function useFindChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindChartQuery, FindChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindChartQuery, FindChartQueryVariables>(FindChartDocument, options);
        }
export function useFindChartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindChartQuery, FindChartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindChartQuery, FindChartQueryVariables>(FindChartDocument, options);
        }
export type FindChartQueryHookResult = ReturnType<typeof useFindChartQuery>;
export type FindChartLazyQueryHookResult = ReturnType<typeof useFindChartLazyQuery>;
export type FindChartSuspenseQueryHookResult = ReturnType<typeof useFindChartSuspenseQuery>;
export type FindChartQueryResult = Apollo.QueryResult<FindChartQuery, FindChartQueryVariables>;
export const UpdateKpiChartMutationDocument = gql`
    mutation UpdateKpiChartMutation($input: UpdateChartInput!) {
  updateKpiChart(updateChartInput: $input) {
    ...Chart
  }
}
    ${ChartFragmentDoc}`;
export type UpdateKpiChartMutationMutationFn = Apollo.MutationFunction<UpdateKpiChartMutationMutation, UpdateKpiChartMutationMutationVariables>;

/**
 * __useUpdateKpiChartMutationMutation__
 *
 * To run a mutation, you first call `useUpdateKpiChartMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKpiChartMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKpiChartMutationMutation, { data, loading, error }] = useUpdateKpiChartMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKpiChartMutationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKpiChartMutationMutation, UpdateKpiChartMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKpiChartMutationMutation, UpdateKpiChartMutationMutationVariables>(UpdateKpiChartMutationDocument, options);
      }
export type UpdateKpiChartMutationMutationHookResult = ReturnType<typeof useUpdateKpiChartMutationMutation>;
export type UpdateKpiChartMutationMutationResult = Apollo.MutationResult<UpdateKpiChartMutationMutation>;
export type UpdateKpiChartMutationMutationOptions = Apollo.BaseMutationOptions<UpdateKpiChartMutationMutation, UpdateKpiChartMutationMutationVariables>;
export const MarkNotificationAsReadDocument = gql`
    mutation MarkNotificationAsRead($input: MarkAsReadInput!) {
  markNotificationAsRead(markAsReadInput: $input) {
    success
  }
}
    `;
export type MarkNotificationAsReadMutationFn = Apollo.MutationFunction<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;

/**
 * __useMarkNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationAsReadMutation, { data, loading, error }] = useMarkNotificationAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkNotificationAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>(MarkNotificationAsReadDocument, options);
      }
export type MarkNotificationAsReadMutationHookResult = ReturnType<typeof useMarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationResult = Apollo.MutationResult<MarkNotificationAsReadMutation>;
export type MarkNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<MarkNotificationAsReadMutation, MarkNotificationAsReadMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(updateCommentInput: $input) {
    commentCategory
    content
    id
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const FindAllNotificationsDocument = gql`
    query findAllNotifications($input: FindAllUserNotificationsInput!) {
  findNotifications(findAllNotificationsInput: $input) {
    items {
      ...Notification
    }
    total
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useFindAllNotificationsQuery__
 *
 * To run a query within a React component, call `useFindAllNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllNotificationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllNotificationsQuery(baseOptions: Apollo.QueryHookOptions<FindAllNotificationsQuery, FindAllNotificationsQueryVariables> & ({ variables: FindAllNotificationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>(FindAllNotificationsDocument, options);
      }
export function useFindAllNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>(FindAllNotificationsDocument, options);
        }
export function useFindAllNotificationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>(FindAllNotificationsDocument, options);
        }
export type FindAllNotificationsQueryHookResult = ReturnType<typeof useFindAllNotificationsQuery>;
export type FindAllNotificationsLazyQueryHookResult = ReturnType<typeof useFindAllNotificationsLazyQuery>;
export type FindAllNotificationsSuspenseQueryHookResult = ReturnType<typeof useFindAllNotificationsSuspenseQuery>;
export type FindAllNotificationsQueryResult = Apollo.QueryResult<FindAllNotificationsQuery, FindAllNotificationsQueryVariables>;
export const SubscribeNotificationsDocument = gql`
    subscription subscribeNotifications {
  notifications {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useSubscribeNotificationsSubscription__
 *
 * To run a query within a React component, call `useSubscribeNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeNotificationsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeNotificationsSubscription, SubscribeNotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeNotificationsSubscription, SubscribeNotificationsSubscriptionVariables>(SubscribeNotificationsDocument, options);
      }
export type SubscribeNotificationsSubscriptionHookResult = ReturnType<typeof useSubscribeNotificationsSubscription>;
export type SubscribeNotificationsSubscriptionResult = Apollo.SubscriptionResult<SubscribeNotificationsSubscription>;
export const ChangeWidgetVisibilityDocument = gql`
    mutation ChangeWidgetVisibility($input: WidgetVisibilityInput!) {
  changeWidgetVisibility(changeWidgetVisibilityInput: $input) {
    ...Dashboard
  }
}
    ${DashboardFragmentDoc}`;
export type ChangeWidgetVisibilityMutationFn = Apollo.MutationFunction<ChangeWidgetVisibilityMutation, ChangeWidgetVisibilityMutationVariables>;

/**
 * __useChangeWidgetVisibilityMutation__
 *
 * To run a mutation, you first call `useChangeWidgetVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeWidgetVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeWidgetVisibilityMutation, { data, loading, error }] = useChangeWidgetVisibilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeWidgetVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<ChangeWidgetVisibilityMutation, ChangeWidgetVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeWidgetVisibilityMutation, ChangeWidgetVisibilityMutationVariables>(ChangeWidgetVisibilityDocument, options);
      }
export type ChangeWidgetVisibilityMutationHookResult = ReturnType<typeof useChangeWidgetVisibilityMutation>;
export type ChangeWidgetVisibilityMutationResult = Apollo.MutationResult<ChangeWidgetVisibilityMutation>;
export type ChangeWidgetVisibilityMutationOptions = Apollo.BaseMutationOptions<ChangeWidgetVisibilityMutation, ChangeWidgetVisibilityMutationVariables>;
export const CreateDashboardDocument = gql`
    mutation CreateDashboard($input: CreateDashboardInput!) {
  createDashboard(createDashboardInput: $input) {
    ...Dashboard
  }
}
    ${DashboardFragmentDoc}`;
export type CreateDashboardMutationFn = Apollo.MutationFunction<CreateDashboardMutation, CreateDashboardMutationVariables>;

/**
 * __useCreateDashboardMutation__
 *
 * To run a mutation, you first call `useCreateDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDashboardMutation, { data, loading, error }] = useCreateDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDashboardMutation(baseOptions?: Apollo.MutationHookOptions<CreateDashboardMutation, CreateDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDashboardMutation, CreateDashboardMutationVariables>(CreateDashboardDocument, options);
      }
export type CreateDashboardMutationHookResult = ReturnType<typeof useCreateDashboardMutation>;
export type CreateDashboardMutationResult = Apollo.MutationResult<CreateDashboardMutation>;
export type CreateDashboardMutationOptions = Apollo.BaseMutationOptions<CreateDashboardMutation, CreateDashboardMutationVariables>;
export const PinToDashboardDocument = gql`
    mutation PinToDashboard($input: PinToDashboardInput!) {
  pinToDashboard(pinToDashboardInput: $input) {
    ...Dashboard
  }
}
    ${DashboardFragmentDoc}`;
export type PinToDashboardMutationFn = Apollo.MutationFunction<PinToDashboardMutation, PinToDashboardMutationVariables>;

/**
 * __usePinToDashboardMutation__
 *
 * To run a mutation, you first call `usePinToDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinToDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinToDashboardMutation, { data, loading, error }] = usePinToDashboardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePinToDashboardMutation(baseOptions?: Apollo.MutationHookOptions<PinToDashboardMutation, PinToDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PinToDashboardMutation, PinToDashboardMutationVariables>(PinToDashboardDocument, options);
      }
export type PinToDashboardMutationHookResult = ReturnType<typeof usePinToDashboardMutation>;
export type PinToDashboardMutationResult = Apollo.MutationResult<PinToDashboardMutation>;
export type PinToDashboardMutationOptions = Apollo.BaseMutationOptions<PinToDashboardMutation, PinToDashboardMutationVariables>;
export const UpdateDashboardLayoutDocument = gql`
    mutation UpdateDashboardLayout($input: UpdateDashboardLayoutInput!) {
  updateDashboardLayout(updateDashboardLayoutInput: $input) {
    ...Dashboard
  }
}
    ${DashboardFragmentDoc}`;
export type UpdateDashboardLayoutMutationFn = Apollo.MutationFunction<UpdateDashboardLayoutMutation, UpdateDashboardLayoutMutationVariables>;

/**
 * __useUpdateDashboardLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardLayoutMutation, { data, loading, error }] = useUpdateDashboardLayoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDashboardLayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDashboardLayoutMutation, UpdateDashboardLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDashboardLayoutMutation, UpdateDashboardLayoutMutationVariables>(UpdateDashboardLayoutDocument, options);
      }
export type UpdateDashboardLayoutMutationHookResult = ReturnType<typeof useUpdateDashboardLayoutMutation>;
export type UpdateDashboardLayoutMutationResult = Apollo.MutationResult<UpdateDashboardLayoutMutation>;
export type UpdateDashboardLayoutMutationOptions = Apollo.BaseMutationOptions<UpdateDashboardLayoutMutation, UpdateDashboardLayoutMutationVariables>;
export const GetDashboardDocument = gql`
    query getDashboard($input: FindDashboardInput!) {
  getKpiDashboard(findDashboardInput: $input) {
    ...Dashboard
  }
}
    ${DashboardFragmentDoc}`;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables> & ({ variables: GetDashboardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
      }
export function useGetDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
        }
export function useGetDashboardSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardSuspenseQueryHookResult = ReturnType<typeof useGetDashboardSuspenseQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export const ChangeStepPositionDocument = gql`
    mutation changeStepPosition($input: ChangeStepPositionInput!) {
  changeStepPosition(changeEflowStepPositionInput: $input) {
    id
    asIsSteps {
      eFlowId
      id
      operationName
    }
    toBeSteps {
      eFlowId
      id
      operationName
    }
  }
}
    `;
export type ChangeStepPositionMutationFn = Apollo.MutationFunction<ChangeStepPositionMutation, ChangeStepPositionMutationVariables>;

/**
 * __useChangeStepPositionMutation__
 *
 * To run a mutation, you first call `useChangeStepPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeStepPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeStepPositionMutation, { data, loading, error }] = useChangeStepPositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeStepPositionMutation(baseOptions?: Apollo.MutationHookOptions<ChangeStepPositionMutation, ChangeStepPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeStepPositionMutation, ChangeStepPositionMutationVariables>(ChangeStepPositionDocument, options);
      }
export type ChangeStepPositionMutationHookResult = ReturnType<typeof useChangeStepPositionMutation>;
export type ChangeStepPositionMutationResult = Apollo.MutationResult<ChangeStepPositionMutation>;
export type ChangeStepPositionMutationOptions = Apollo.BaseMutationOptions<ChangeStepPositionMutation, ChangeStepPositionMutationVariables>;
export const CloneEflowDocument = gql`
    mutation CloneEflow($input: CloneEFlowInput!) {
  cloneEflow(cloneEflowInput: $input) {
    id
    name
  }
}
    `;
export type CloneEflowMutationFn = Apollo.MutationFunction<CloneEflowMutation, CloneEflowMutationVariables>;

/**
 * __useCloneEflowMutation__
 *
 * To run a mutation, you first call `useCloneEflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneEflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneEflowMutation, { data, loading, error }] = useCloneEflowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneEflowMutation(baseOptions?: Apollo.MutationHookOptions<CloneEflowMutation, CloneEflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneEflowMutation, CloneEflowMutationVariables>(CloneEflowDocument, options);
      }
export type CloneEflowMutationHookResult = ReturnType<typeof useCloneEflowMutation>;
export type CloneEflowMutationResult = Apollo.MutationResult<CloneEflowMutation>;
export type CloneEflowMutationOptions = Apollo.BaseMutationOptions<CloneEflowMutation, CloneEflowMutationVariables>;
export const CloneEflowStepsDocument = gql`
    mutation CloneEflowSteps($input: CloneEflowStepsInput!) {
  cloneEflowSteps(cloneEflowStepsInput: $input) {
    success
  }
}
    `;
export type CloneEflowStepsMutationFn = Apollo.MutationFunction<CloneEflowStepsMutation, CloneEflowStepsMutationVariables>;

/**
 * __useCloneEflowStepsMutation__
 *
 * To run a mutation, you first call `useCloneEflowStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneEflowStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneEflowStepsMutation, { data, loading, error }] = useCloneEflowStepsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCloneEflowStepsMutation(baseOptions?: Apollo.MutationHookOptions<CloneEflowStepsMutation, CloneEflowStepsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneEflowStepsMutation, CloneEflowStepsMutationVariables>(CloneEflowStepsDocument, options);
      }
export type CloneEflowStepsMutationHookResult = ReturnType<typeof useCloneEflowStepsMutation>;
export type CloneEflowStepsMutationResult = Apollo.MutationResult<CloneEflowStepsMutation>;
export type CloneEflowStepsMutationOptions = Apollo.BaseMutationOptions<CloneEflowStepsMutation, CloneEflowStepsMutationVariables>;
export const CreateEFlowDocument = gql`
    mutation CreateEFlow($input: CreateEflowInput!) {
  createEFlow(createEFlowInput: $input) {
    id
  }
}
    `;
export type CreateEFlowMutationFn = Apollo.MutationFunction<CreateEFlowMutation, CreateEFlowMutationVariables>;

/**
 * __useCreateEFlowMutation__
 *
 * To run a mutation, you first call `useCreateEFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEFlowMutation, { data, loading, error }] = useCreateEFlowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEFlowMutation(baseOptions?: Apollo.MutationHookOptions<CreateEFlowMutation, CreateEFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEFlowMutation, CreateEFlowMutationVariables>(CreateEFlowDocument, options);
      }
export type CreateEFlowMutationHookResult = ReturnType<typeof useCreateEFlowMutation>;
export type CreateEFlowMutationResult = Apollo.MutationResult<CreateEFlowMutation>;
export type CreateEFlowMutationOptions = Apollo.BaseMutationOptions<CreateEFlowMutation, CreateEFlowMutationVariables>;
export const CreateEflowStepDocument = gql`
    mutation CreateEflowStep($input: CreateEflowStepInput!, $files: [Upload!]) {
  createEflowStep(createEflowStepInput: $input, resourceImage: $files) {
    ...EflowStepFragment
  }
}
    ${EflowStepFragmentFragmentDoc}`;
export type CreateEflowStepMutationFn = Apollo.MutationFunction<CreateEflowStepMutation, CreateEflowStepMutationVariables>;

/**
 * __useCreateEflowStepMutation__
 *
 * To run a mutation, you first call `useCreateEflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEflowStepMutation, { data, loading, error }] = useCreateEflowStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useCreateEflowStepMutation(baseOptions?: Apollo.MutationHookOptions<CreateEflowStepMutation, CreateEflowStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEflowStepMutation, CreateEflowStepMutationVariables>(CreateEflowStepDocument, options);
      }
export type CreateEflowStepMutationHookResult = ReturnType<typeof useCreateEflowStepMutation>;
export type CreateEflowStepMutationResult = Apollo.MutationResult<CreateEflowStepMutation>;
export type CreateEflowStepMutationOptions = Apollo.BaseMutationOptions<CreateEflowStepMutation, CreateEflowStepMutationVariables>;
export const CreateReportDocument = gql`
    mutation CreateReport($input: GenerateEflowReportInput!) {
  createEFlowReport(generateReportInput: $input) {
    success
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const RemoveEflowStepsDocument = gql`
    mutation RemoveEflowSteps($input: RemoveEflowStepsInput!) {
  removeEflowSteps(removeEflowStepsInput: $input) {
    success
  }
}
    `;
export type RemoveEflowStepsMutationFn = Apollo.MutationFunction<RemoveEflowStepsMutation, RemoveEflowStepsMutationVariables>;

/**
 * __useRemoveEflowStepsMutation__
 *
 * To run a mutation, you first call `useRemoveEflowStepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveEflowStepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeEflowStepsMutation, { data, loading, error }] = useRemoveEflowStepsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveEflowStepsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveEflowStepsMutation, RemoveEflowStepsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveEflowStepsMutation, RemoveEflowStepsMutationVariables>(RemoveEflowStepsDocument, options);
      }
export type RemoveEflowStepsMutationHookResult = ReturnType<typeof useRemoveEflowStepsMutation>;
export type RemoveEflowStepsMutationResult = Apollo.MutationResult<RemoveEflowStepsMutation>;
export type RemoveEflowStepsMutationOptions = Apollo.BaseMutationOptions<RemoveEflowStepsMutation, RemoveEflowStepsMutationVariables>;
export const UpdateCommentInEflowStepDocument = gql`
    mutation UpdateCommentInEflowStep($input: UpdateEflowStepInput!) {
  updateEflowStep(updateEflowStepInput: $input) {
    commentModels {
      commentId
      showInReport
    }
  }
}
    `;
export type UpdateCommentInEflowStepMutationFn = Apollo.MutationFunction<UpdateCommentInEflowStepMutation, UpdateCommentInEflowStepMutationVariables>;

/**
 * __useUpdateCommentInEflowStepMutation__
 *
 * To run a mutation, you first call `useUpdateCommentInEflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentInEflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentInEflowStepMutation, { data, loading, error }] = useUpdateCommentInEflowStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentInEflowStepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentInEflowStepMutation, UpdateCommentInEflowStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentInEflowStepMutation, UpdateCommentInEflowStepMutationVariables>(UpdateCommentInEflowStepDocument, options);
      }
export type UpdateCommentInEflowStepMutationHookResult = ReturnType<typeof useUpdateCommentInEflowStepMutation>;
export type UpdateCommentInEflowStepMutationResult = Apollo.MutationResult<UpdateCommentInEflowStepMutation>;
export type UpdateCommentInEflowStepMutationOptions = Apollo.BaseMutationOptions<UpdateCommentInEflowStepMutation, UpdateCommentInEflowStepMutationVariables>;
export const UpdateEflowDocument = gql`
    mutation UpdateEflow($input: UpdateEflowInput!) {
  updateEflow(updateEflowInput: $input) {
    id
  }
}
    `;
export type UpdateEflowMutationFn = Apollo.MutationFunction<UpdateEflowMutation, UpdateEflowMutationVariables>;

/**
 * __useUpdateEflowMutation__
 *
 * To run a mutation, you first call `useUpdateEflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEflowMutation, { data, loading, error }] = useUpdateEflowMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEflowMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEflowMutation, UpdateEflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEflowMutation, UpdateEflowMutationVariables>(UpdateEflowDocument, options);
      }
export type UpdateEflowMutationHookResult = ReturnType<typeof useUpdateEflowMutation>;
export type UpdateEflowMutationResult = Apollo.MutationResult<UpdateEflowMutation>;
export type UpdateEflowMutationOptions = Apollo.BaseMutationOptions<UpdateEflowMutation, UpdateEflowMutationVariables>;
export const UpdateEflowStatusDocument = gql`
    mutation UpdateEflowStatus($input: UpdateEflowStatusInput!) {
  updateEflowStatus(updateEflowStatusInput: $input) {
    id
    status
  }
}
    `;
export type UpdateEflowStatusMutationFn = Apollo.MutationFunction<UpdateEflowStatusMutation, UpdateEflowStatusMutationVariables>;

/**
 * __useUpdateEflowStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEflowStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEflowStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEflowStatusMutation, { data, loading, error }] = useUpdateEflowStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEflowStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEflowStatusMutation, UpdateEflowStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEflowStatusMutation, UpdateEflowStatusMutationVariables>(UpdateEflowStatusDocument, options);
      }
export type UpdateEflowStatusMutationHookResult = ReturnType<typeof useUpdateEflowStatusMutation>;
export type UpdateEflowStatusMutationResult = Apollo.MutationResult<UpdateEflowStatusMutation>;
export type UpdateEflowStatusMutationOptions = Apollo.BaseMutationOptions<UpdateEflowStatusMutation, UpdateEflowStatusMutationVariables>;
export const UpdateEflowStepDocument = gql`
    mutation UpdateEflowStep($input: UpdateEflowStepInput!, $files: [Upload!]) {
  updateEflowStep(updateEflowStepInput: $input, resourceImage: $files) {
    ...EflowStepFragment
  }
}
    ${EflowStepFragmentFragmentDoc}`;
export type UpdateEflowStepMutationFn = Apollo.MutationFunction<UpdateEflowStepMutation, UpdateEflowStepMutationVariables>;

/**
 * __useUpdateEflowStepMutation__
 *
 * To run a mutation, you first call `useUpdateEflowStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEflowStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEflowStepMutation, { data, loading, error }] = useUpdateEflowStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUpdateEflowStepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEflowStepMutation, UpdateEflowStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEflowStepMutation, UpdateEflowStepMutationVariables>(UpdateEflowStepDocument, options);
      }
export type UpdateEflowStepMutationHookResult = ReturnType<typeof useUpdateEflowStepMutation>;
export type UpdateEflowStepMutationResult = Apollo.MutationResult<UpdateEflowStepMutation>;
export type UpdateEflowStepMutationOptions = Apollo.BaseMutationOptions<UpdateEflowStepMutation, UpdateEflowStepMutationVariables>;
export const FindAllEFlowsDocument = gql`
    query findAllEFlows($input: FindAllEFlowsInput!) {
  findAllEFlows(findAllEFlowsInput: $input) {
    items {
      ...SingleEflow
    }
    total
  }
}
    ${SingleEflowFragmentDoc}`;

/**
 * __useFindAllEFlowsQuery__
 *
 * To run a query within a React component, call `useFindAllEFlowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllEFlowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllEFlowsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllEFlowsQuery(baseOptions: Apollo.QueryHookOptions<FindAllEFlowsQuery, FindAllEFlowsQueryVariables> & ({ variables: FindAllEFlowsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllEFlowsQuery, FindAllEFlowsQueryVariables>(FindAllEFlowsDocument, options);
      }
export function useFindAllEFlowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllEFlowsQuery, FindAllEFlowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllEFlowsQuery, FindAllEFlowsQueryVariables>(FindAllEFlowsDocument, options);
        }
export function useFindAllEFlowsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAllEFlowsQuery, FindAllEFlowsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAllEFlowsQuery, FindAllEFlowsQueryVariables>(FindAllEFlowsDocument, options);
        }
export type FindAllEFlowsQueryHookResult = ReturnType<typeof useFindAllEFlowsQuery>;
export type FindAllEFlowsLazyQueryHookResult = ReturnType<typeof useFindAllEFlowsLazyQuery>;
export type FindAllEFlowsSuspenseQueryHookResult = ReturnType<typeof useFindAllEFlowsSuspenseQuery>;
export type FindAllEFlowsQueryResult = Apollo.QueryResult<FindAllEFlowsQuery, FindAllEFlowsQueryVariables>;
export const FindEflowDataDocument = gql`
    query findEflowData($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    name
    description
    workGroupUsers {
      nameAndSurname
    }
    status
    analyzeLeaders {
      firstName
      lastName
    }
    startDate
  }
}
    `;

/**
 * __useFindEflowDataQuery__
 *
 * To run a query within a React component, call `useFindEflowDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEflowDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEflowDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindEflowDataQuery(baseOptions: Apollo.QueryHookOptions<FindEflowDataQuery, FindEflowDataQueryVariables> & ({ variables: FindEflowDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEflowDataQuery, FindEflowDataQueryVariables>(FindEflowDataDocument, options);
      }
export function useFindEflowDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEflowDataQuery, FindEflowDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEflowDataQuery, FindEflowDataQueryVariables>(FindEflowDataDocument, options);
        }
export function useFindEflowDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindEflowDataQuery, FindEflowDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindEflowDataQuery, FindEflowDataQueryVariables>(FindEflowDataDocument, options);
        }
export type FindEflowDataQueryHookResult = ReturnType<typeof useFindEflowDataQuery>;
export type FindEflowDataLazyQueryHookResult = ReturnType<typeof useFindEflowDataLazyQuery>;
export type FindEflowDataSuspenseQueryHookResult = ReturnType<typeof useFindEflowDataSuspenseQuery>;
export type FindEflowDataQueryResult = Apollo.QueryResult<FindEflowDataQuery, FindEflowDataQueryVariables>;
export const FindEflowPermissionsDocument = gql`
    query findEflowPermissions($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    id
    workGroupUsers {
      id
    }
    status
    analyzeLeaders {
      id
      organizationId
    }
    createdBy {
      id
      organizationId
    }
  }
}
    `;

/**
 * __useFindEflowPermissionsQuery__
 *
 * To run a query within a React component, call `useFindEflowPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEflowPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEflowPermissionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindEflowPermissionsQuery(baseOptions: Apollo.QueryHookOptions<FindEflowPermissionsQuery, FindEflowPermissionsQueryVariables> & ({ variables: FindEflowPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEflowPermissionsQuery, FindEflowPermissionsQueryVariables>(FindEflowPermissionsDocument, options);
      }
export function useFindEflowPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEflowPermissionsQuery, FindEflowPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEflowPermissionsQuery, FindEflowPermissionsQueryVariables>(FindEflowPermissionsDocument, options);
        }
export function useFindEflowPermissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindEflowPermissionsQuery, FindEflowPermissionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindEflowPermissionsQuery, FindEflowPermissionsQueryVariables>(FindEflowPermissionsDocument, options);
        }
export type FindEflowPermissionsQueryHookResult = ReturnType<typeof useFindEflowPermissionsQuery>;
export type FindEflowPermissionsLazyQueryHookResult = ReturnType<typeof useFindEflowPermissionsLazyQuery>;
export type FindEflowPermissionsSuspenseQueryHookResult = ReturnType<typeof useFindEflowPermissionsSuspenseQuery>;
export type FindEflowPermissionsQueryResult = Apollo.QueryResult<FindEflowPermissionsQuery, FindEflowPermissionsQueryVariables>;
export const FindEflowToBeStepsDocument = gql`
    query findEflowToBeSteps($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    id
    toBeSteps {
      ...EflowStepFragment
    }
    toBeState
  }
}
    ${EflowStepFragmentFragmentDoc}`;

/**
 * __useFindEflowToBeStepsQuery__
 *
 * To run a query within a React component, call `useFindEflowToBeStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEflowToBeStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEflowToBeStepsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindEflowToBeStepsQuery(baseOptions: Apollo.QueryHookOptions<FindEflowToBeStepsQuery, FindEflowToBeStepsQueryVariables> & ({ variables: FindEflowToBeStepsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEflowToBeStepsQuery, FindEflowToBeStepsQueryVariables>(FindEflowToBeStepsDocument, options);
      }
export function useFindEflowToBeStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEflowToBeStepsQuery, FindEflowToBeStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEflowToBeStepsQuery, FindEflowToBeStepsQueryVariables>(FindEflowToBeStepsDocument, options);
        }
export function useFindEflowToBeStepsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindEflowToBeStepsQuery, FindEflowToBeStepsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindEflowToBeStepsQuery, FindEflowToBeStepsQueryVariables>(FindEflowToBeStepsDocument, options);
        }
export type FindEflowToBeStepsQueryHookResult = ReturnType<typeof useFindEflowToBeStepsQuery>;
export type FindEflowToBeStepsLazyQueryHookResult = ReturnType<typeof useFindEflowToBeStepsLazyQuery>;
export type FindEflowToBeStepsSuspenseQueryHookResult = ReturnType<typeof useFindEflowToBeStepsSuspenseQuery>;
export type FindEflowToBeStepsQueryResult = Apollo.QueryResult<FindEflowToBeStepsQuery, FindEflowToBeStepsQueryVariables>;
export const FindOneEflowDocument = gql`
    query FindOneEflow($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    ...SingleEflowFragment
  }
}
    ${SingleEflowFragmentFragmentDoc}`;

/**
 * __useFindOneEflowQuery__
 *
 * To run a query within a React component, call `useFindOneEflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneEflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneEflowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindOneEflowQuery(baseOptions: Apollo.QueryHookOptions<FindOneEflowQuery, FindOneEflowQueryVariables> & ({ variables: FindOneEflowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneEflowQuery, FindOneEflowQueryVariables>(FindOneEflowDocument, options);
      }
export function useFindOneEflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneEflowQuery, FindOneEflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneEflowQuery, FindOneEflowQueryVariables>(FindOneEflowDocument, options);
        }
export function useFindOneEflowSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindOneEflowQuery, FindOneEflowQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindOneEflowQuery, FindOneEflowQueryVariables>(FindOneEflowDocument, options);
        }
export type FindOneEflowQueryHookResult = ReturnType<typeof useFindOneEflowQuery>;
export type FindOneEflowLazyQueryHookResult = ReturnType<typeof useFindOneEflowLazyQuery>;
export type FindOneEflowSuspenseQueryHookResult = ReturnType<typeof useFindOneEflowSuspenseQuery>;
export type FindOneEflowQueryResult = Apollo.QueryResult<FindOneEflowQuery, FindOneEflowQueryVariables>;
export const FindOneEflowAsIsDataDocument = gql`
    query FindOneEflowAsIsData($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    id
    asIsSteps {
      ...EflowStepFragment
    }
    asIsState
  }
}
    ${EflowStepFragmentFragmentDoc}`;

/**
 * __useFindOneEflowAsIsDataQuery__
 *
 * To run a query within a React component, call `useFindOneEflowAsIsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneEflowAsIsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneEflowAsIsDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindOneEflowAsIsDataQuery(baseOptions: Apollo.QueryHookOptions<FindOneEflowAsIsDataQuery, FindOneEflowAsIsDataQueryVariables> & ({ variables: FindOneEflowAsIsDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneEflowAsIsDataQuery, FindOneEflowAsIsDataQueryVariables>(FindOneEflowAsIsDataDocument, options);
      }
export function useFindOneEflowAsIsDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneEflowAsIsDataQuery, FindOneEflowAsIsDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneEflowAsIsDataQuery, FindOneEflowAsIsDataQueryVariables>(FindOneEflowAsIsDataDocument, options);
        }
export function useFindOneEflowAsIsDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindOneEflowAsIsDataQuery, FindOneEflowAsIsDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindOneEflowAsIsDataQuery, FindOneEflowAsIsDataQueryVariables>(FindOneEflowAsIsDataDocument, options);
        }
export type FindOneEflowAsIsDataQueryHookResult = ReturnType<typeof useFindOneEflowAsIsDataQuery>;
export type FindOneEflowAsIsDataLazyQueryHookResult = ReturnType<typeof useFindOneEflowAsIsDataLazyQuery>;
export type FindOneEflowAsIsDataSuspenseQueryHookResult = ReturnType<typeof useFindOneEflowAsIsDataSuspenseQuery>;
export type FindOneEflowAsIsDataQueryResult = Apollo.QueryResult<FindOneEflowAsIsDataQuery, FindOneEflowAsIsDataQueryVariables>;
export const FindOneEflowStepDocument = gql`
    query FindOneEflowStep($input: FindOneEflowStepInput!) {
  findOneEflowStep(findOneEflowStepInput: $input) {
    ...EflowStepFragment
  }
}
    ${EflowStepFragmentFragmentDoc}`;

/**
 * __useFindOneEflowStepQuery__
 *
 * To run a query within a React component, call `useFindOneEflowStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneEflowStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneEflowStepQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindOneEflowStepQuery(baseOptions: Apollo.QueryHookOptions<FindOneEflowStepQuery, FindOneEflowStepQueryVariables> & ({ variables: FindOneEflowStepQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneEflowStepQuery, FindOneEflowStepQueryVariables>(FindOneEflowStepDocument, options);
      }
export function useFindOneEflowStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneEflowStepQuery, FindOneEflowStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneEflowStepQuery, FindOneEflowStepQueryVariables>(FindOneEflowStepDocument, options);
        }
export function useFindOneEflowStepSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindOneEflowStepQuery, FindOneEflowStepQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindOneEflowStepQuery, FindOneEflowStepQueryVariables>(FindOneEflowStepDocument, options);
        }
export type FindOneEflowStepQueryHookResult = ReturnType<typeof useFindOneEflowStepQuery>;
export type FindOneEflowStepLazyQueryHookResult = ReturnType<typeof useFindOneEflowStepLazyQuery>;
export type FindOneEflowStepSuspenseQueryHookResult = ReturnType<typeof useFindOneEflowStepSuspenseQuery>;
export type FindOneEflowStepQueryResult = Apollo.QueryResult<FindOneEflowStepQuery, FindOneEflowStepQueryVariables>;
export const FindSingleReportDocument = gql`
    query FindSingleReport($input: FindReportInput!) {
  findOneEflowReport(findReportInput: $input) {
    eFlowId
    version
    operations
    parameters
    operationTypesSummary
    comments {
      analizeType
      comment {
        commentCategory
        content
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
      }
      operation {
        name
        operationType
      }
      operationName
      stepNumber
    }
  }
}
    `;

/**
 * __useFindSingleReportQuery__
 *
 * To run a query within a React component, call `useFindSingleReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSingleReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSingleReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindSingleReportQuery(baseOptions: Apollo.QueryHookOptions<FindSingleReportQuery, FindSingleReportQueryVariables> & ({ variables: FindSingleReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSingleReportQuery, FindSingleReportQueryVariables>(FindSingleReportDocument, options);
      }
export function useFindSingleReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSingleReportQuery, FindSingleReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSingleReportQuery, FindSingleReportQueryVariables>(FindSingleReportDocument, options);
        }
export function useFindSingleReportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindSingleReportQuery, FindSingleReportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindSingleReportQuery, FindSingleReportQueryVariables>(FindSingleReportDocument, options);
        }
export type FindSingleReportQueryHookResult = ReturnType<typeof useFindSingleReportQuery>;
export type FindSingleReportLazyQueryHookResult = ReturnType<typeof useFindSingleReportLazyQuery>;
export type FindSingleReportSuspenseQueryHookResult = ReturnType<typeof useFindSingleReportSuspenseQuery>;
export type FindSingleReportQueryResult = Apollo.QueryResult<FindSingleReportQuery, FindSingleReportQueryVariables>;
export const GetAsIsToBeEflowParamsDocument = gql`
    query GetAsIsToBeEflowParams($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    asIsSteps {
      operation {
        operationType
      }
      id
      isActive
      parameters
    }
    toBeSteps {
      operation {
        operationType
      }
      id
      isActive
      parameters
    }
  }
}
    `;

/**
 * __useGetAsIsToBeEflowParamsQuery__
 *
 * To run a query within a React component, call `useGetAsIsToBeEflowParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAsIsToBeEflowParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAsIsToBeEflowParamsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAsIsToBeEflowParamsQuery(baseOptions: Apollo.QueryHookOptions<GetAsIsToBeEflowParamsQuery, GetAsIsToBeEflowParamsQueryVariables> & ({ variables: GetAsIsToBeEflowParamsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAsIsToBeEflowParamsQuery, GetAsIsToBeEflowParamsQueryVariables>(GetAsIsToBeEflowParamsDocument, options);
      }
export function useGetAsIsToBeEflowParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAsIsToBeEflowParamsQuery, GetAsIsToBeEflowParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAsIsToBeEflowParamsQuery, GetAsIsToBeEflowParamsQueryVariables>(GetAsIsToBeEflowParamsDocument, options);
        }
export function useGetAsIsToBeEflowParamsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAsIsToBeEflowParamsQuery, GetAsIsToBeEflowParamsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAsIsToBeEflowParamsQuery, GetAsIsToBeEflowParamsQueryVariables>(GetAsIsToBeEflowParamsDocument, options);
        }
export type GetAsIsToBeEflowParamsQueryHookResult = ReturnType<typeof useGetAsIsToBeEflowParamsQuery>;
export type GetAsIsToBeEflowParamsLazyQueryHookResult = ReturnType<typeof useGetAsIsToBeEflowParamsLazyQuery>;
export type GetAsIsToBeEflowParamsSuspenseQueryHookResult = ReturnType<typeof useGetAsIsToBeEflowParamsSuspenseQuery>;
export type GetAsIsToBeEflowParamsQueryResult = Apollo.QueryResult<GetAsIsToBeEflowParamsQuery, GetAsIsToBeEflowParamsQueryVariables>;
export const GetAsIsToBeIdsDocument = gql`
    query GetAsIsToBeIds($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    id
    asIsState
    toBeState
  }
}
    `;

/**
 * __useGetAsIsToBeIdsQuery__
 *
 * To run a query within a React component, call `useGetAsIsToBeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAsIsToBeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAsIsToBeIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAsIsToBeIdsQuery(baseOptions: Apollo.QueryHookOptions<GetAsIsToBeIdsQuery, GetAsIsToBeIdsQueryVariables> & ({ variables: GetAsIsToBeIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAsIsToBeIdsQuery, GetAsIsToBeIdsQueryVariables>(GetAsIsToBeIdsDocument, options);
      }
export function useGetAsIsToBeIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAsIsToBeIdsQuery, GetAsIsToBeIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAsIsToBeIdsQuery, GetAsIsToBeIdsQueryVariables>(GetAsIsToBeIdsDocument, options);
        }
export function useGetAsIsToBeIdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAsIsToBeIdsQuery, GetAsIsToBeIdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAsIsToBeIdsQuery, GetAsIsToBeIdsQueryVariables>(GetAsIsToBeIdsDocument, options);
        }
export type GetAsIsToBeIdsQueryHookResult = ReturnType<typeof useGetAsIsToBeIdsQuery>;
export type GetAsIsToBeIdsLazyQueryHookResult = ReturnType<typeof useGetAsIsToBeIdsLazyQuery>;
export type GetAsIsToBeIdsSuspenseQueryHookResult = ReturnType<typeof useGetAsIsToBeIdsSuspenseQuery>;
export type GetAsIsToBeIdsQueryResult = Apollo.QueryResult<GetAsIsToBeIdsQuery, GetAsIsToBeIdsQueryVariables>;
export const GetAsIsToBeOperationSymbolsDocument = gql`
    query GetAsIsToBeOperationSymbols($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    asIsSteps {
      id
      isActive
      operation {
        operationType
      }
    }
    toBeSteps {
      id
      isActive
      operation {
        operationType
      }
    }
  }
}
    `;

/**
 * __useGetAsIsToBeOperationSymbolsQuery__
 *
 * To run a query within a React component, call `useGetAsIsToBeOperationSymbolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAsIsToBeOperationSymbolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAsIsToBeOperationSymbolsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAsIsToBeOperationSymbolsQuery(baseOptions: Apollo.QueryHookOptions<GetAsIsToBeOperationSymbolsQuery, GetAsIsToBeOperationSymbolsQueryVariables> & ({ variables: GetAsIsToBeOperationSymbolsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAsIsToBeOperationSymbolsQuery, GetAsIsToBeOperationSymbolsQueryVariables>(GetAsIsToBeOperationSymbolsDocument, options);
      }
export function useGetAsIsToBeOperationSymbolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAsIsToBeOperationSymbolsQuery, GetAsIsToBeOperationSymbolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAsIsToBeOperationSymbolsQuery, GetAsIsToBeOperationSymbolsQueryVariables>(GetAsIsToBeOperationSymbolsDocument, options);
        }
export function useGetAsIsToBeOperationSymbolsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAsIsToBeOperationSymbolsQuery, GetAsIsToBeOperationSymbolsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAsIsToBeOperationSymbolsQuery, GetAsIsToBeOperationSymbolsQueryVariables>(GetAsIsToBeOperationSymbolsDocument, options);
        }
export type GetAsIsToBeOperationSymbolsQueryHookResult = ReturnType<typeof useGetAsIsToBeOperationSymbolsQuery>;
export type GetAsIsToBeOperationSymbolsLazyQueryHookResult = ReturnType<typeof useGetAsIsToBeOperationSymbolsLazyQuery>;
export type GetAsIsToBeOperationSymbolsSuspenseQueryHookResult = ReturnType<typeof useGetAsIsToBeOperationSymbolsSuspenseQuery>;
export type GetAsIsToBeOperationSymbolsQueryResult = Apollo.QueryResult<GetAsIsToBeOperationSymbolsQuery, GetAsIsToBeOperationSymbolsQueryVariables>;
export const GetCommentsDocument = gql`
    query GetComments($input: FindOneEflowStepInput!) {
  findOneEflowStep(findOneEflowStepInput: $input) {
    eFlowId
    operation {
      name
      operationType
    }
    operationName
    comments {
      id
      commentCategory
      content
      createdBy {
        id
      }
      showInReport
    }
  }
}
    `;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables> & ({ variables: GetCommentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export function useGetCommentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsSuspenseQueryHookResult = ReturnType<typeof useGetCommentsSuspenseQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>;
export const GetEflowAsIsStepsDocument = gql`
    query GetEflowAsIsSteps($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    id
    asIsSteps {
      ...EflowStepFragment
    }
    asIsState
  }
}
    ${EflowStepFragmentFragmentDoc}`;

/**
 * __useGetEflowAsIsStepsQuery__
 *
 * To run a query within a React component, call `useGetEflowAsIsStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEflowAsIsStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEflowAsIsStepsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEflowAsIsStepsQuery(baseOptions: Apollo.QueryHookOptions<GetEflowAsIsStepsQuery, GetEflowAsIsStepsQueryVariables> & ({ variables: GetEflowAsIsStepsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEflowAsIsStepsQuery, GetEflowAsIsStepsQueryVariables>(GetEflowAsIsStepsDocument, options);
      }
export function useGetEflowAsIsStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEflowAsIsStepsQuery, GetEflowAsIsStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEflowAsIsStepsQuery, GetEflowAsIsStepsQueryVariables>(GetEflowAsIsStepsDocument, options);
        }
export function useGetEflowAsIsStepsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEflowAsIsStepsQuery, GetEflowAsIsStepsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEflowAsIsStepsQuery, GetEflowAsIsStepsQueryVariables>(GetEflowAsIsStepsDocument, options);
        }
export type GetEflowAsIsStepsQueryHookResult = ReturnType<typeof useGetEflowAsIsStepsQuery>;
export type GetEflowAsIsStepsLazyQueryHookResult = ReturnType<typeof useGetEflowAsIsStepsLazyQuery>;
export type GetEflowAsIsStepsSuspenseQueryHookResult = ReturnType<typeof useGetEflowAsIsStepsSuspenseQuery>;
export type GetEflowAsIsStepsQueryResult = Apollo.QueryResult<GetEflowAsIsStepsQuery, GetEflowAsIsStepsQueryVariables>;
export const GetEflowNameAndParamsDocument = gql`
    query GetEflowNameAndParams($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    id
    name
    status
    parameters {
      name
      type
      unit
    }
  }
}
    `;

/**
 * __useGetEflowNameAndParamsQuery__
 *
 * To run a query within a React component, call `useGetEflowNameAndParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEflowNameAndParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEflowNameAndParamsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEflowNameAndParamsQuery(baseOptions: Apollo.QueryHookOptions<GetEflowNameAndParamsQuery, GetEflowNameAndParamsQueryVariables> & ({ variables: GetEflowNameAndParamsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEflowNameAndParamsQuery, GetEflowNameAndParamsQueryVariables>(GetEflowNameAndParamsDocument, options);
      }
export function useGetEflowNameAndParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEflowNameAndParamsQuery, GetEflowNameAndParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEflowNameAndParamsQuery, GetEflowNameAndParamsQueryVariables>(GetEflowNameAndParamsDocument, options);
        }
export function useGetEflowNameAndParamsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEflowNameAndParamsQuery, GetEflowNameAndParamsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEflowNameAndParamsQuery, GetEflowNameAndParamsQueryVariables>(GetEflowNameAndParamsDocument, options);
        }
export type GetEflowNameAndParamsQueryHookResult = ReturnType<typeof useGetEflowNameAndParamsQuery>;
export type GetEflowNameAndParamsLazyQueryHookResult = ReturnType<typeof useGetEflowNameAndParamsLazyQuery>;
export type GetEflowNameAndParamsSuspenseQueryHookResult = ReturnType<typeof useGetEflowNameAndParamsSuspenseQuery>;
export type GetEflowNameAndParamsQueryResult = Apollo.QueryResult<GetEflowNameAndParamsQuery, GetEflowNameAndParamsQueryVariables>;
export const GetEflowToBeStepsDocument = gql`
    query GetEflowToBeSteps($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    id
    toBeSteps {
      ...EflowStepFragment
    }
    toBeState
  }
}
    ${EflowStepFragmentFragmentDoc}`;

/**
 * __useGetEflowToBeStepsQuery__
 *
 * To run a query within a React component, call `useGetEflowToBeStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEflowToBeStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEflowToBeStepsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEflowToBeStepsQuery(baseOptions: Apollo.QueryHookOptions<GetEflowToBeStepsQuery, GetEflowToBeStepsQueryVariables> & ({ variables: GetEflowToBeStepsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEflowToBeStepsQuery, GetEflowToBeStepsQueryVariables>(GetEflowToBeStepsDocument, options);
      }
export function useGetEflowToBeStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEflowToBeStepsQuery, GetEflowToBeStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEflowToBeStepsQuery, GetEflowToBeStepsQueryVariables>(GetEflowToBeStepsDocument, options);
        }
export function useGetEflowToBeStepsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEflowToBeStepsQuery, GetEflowToBeStepsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEflowToBeStepsQuery, GetEflowToBeStepsQueryVariables>(GetEflowToBeStepsDocument, options);
        }
export type GetEflowToBeStepsQueryHookResult = ReturnType<typeof useGetEflowToBeStepsQuery>;
export type GetEflowToBeStepsLazyQueryHookResult = ReturnType<typeof useGetEflowToBeStepsLazyQuery>;
export type GetEflowToBeStepsSuspenseQueryHookResult = ReturnType<typeof useGetEflowToBeStepsSuspenseQuery>;
export type GetEflowToBeStepsQueryResult = Apollo.QueryResult<GetEflowToBeStepsQuery, GetEflowToBeStepsQueryVariables>;
export const FindEflowToBeStepsIdsDocument = gql`
    query findEflowToBeStepsIds($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    id
    toBeSteps {
      id
    }
  }
}
    `;

/**
 * __useFindEflowToBeStepsIdsQuery__
 *
 * To run a query within a React component, call `useFindEflowToBeStepsIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEflowToBeStepsIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEflowToBeStepsIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindEflowToBeStepsIdsQuery(baseOptions: Apollo.QueryHookOptions<FindEflowToBeStepsIdsQuery, FindEflowToBeStepsIdsQueryVariables> & ({ variables: FindEflowToBeStepsIdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindEflowToBeStepsIdsQuery, FindEflowToBeStepsIdsQueryVariables>(FindEflowToBeStepsIdsDocument, options);
      }
export function useFindEflowToBeStepsIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindEflowToBeStepsIdsQuery, FindEflowToBeStepsIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindEflowToBeStepsIdsQuery, FindEflowToBeStepsIdsQueryVariables>(FindEflowToBeStepsIdsDocument, options);
        }
export function useFindEflowToBeStepsIdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindEflowToBeStepsIdsQuery, FindEflowToBeStepsIdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindEflowToBeStepsIdsQuery, FindEflowToBeStepsIdsQueryVariables>(FindEflowToBeStepsIdsDocument, options);
        }
export type FindEflowToBeStepsIdsQueryHookResult = ReturnType<typeof useFindEflowToBeStepsIdsQuery>;
export type FindEflowToBeStepsIdsLazyQueryHookResult = ReturnType<typeof useFindEflowToBeStepsIdsLazyQuery>;
export type FindEflowToBeStepsIdsSuspenseQueryHookResult = ReturnType<typeof useFindEflowToBeStepsIdsSuspenseQuery>;
export type FindEflowToBeStepsIdsQueryResult = Apollo.QueryResult<FindEflowToBeStepsIdsQuery, FindEflowToBeStepsIdsQueryVariables>;
export const GetOneEflowDocument = gql`
    query GetOneEflow($input: FindOneEFlowInput!) {
  findOneEFlow(findOneEFlowInput: $input) {
    name
    startDate
    createdBy {
      firstName
      lastName
      profilePicture
    }
    description
    analyzeLeaders {
      id
      organizationId
      firstName
      lastName
      email
      organizationId
    }
    parameters {
      name
      type
      unit
    }
    operations {
      name
      operationType
    }
    commentTypes {
      categoryName
      symbol
    }
    workGroupUsers {
      id
      nameAndSurname
    }
  }
}
    `;

/**
 * __useGetOneEflowQuery__
 *
 * To run a query within a React component, call `useGetOneEflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneEflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneEflowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOneEflowQuery(baseOptions: Apollo.QueryHookOptions<GetOneEflowQuery, GetOneEflowQueryVariables> & ({ variables: GetOneEflowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneEflowQuery, GetOneEflowQueryVariables>(GetOneEflowDocument, options);
      }
export function useGetOneEflowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneEflowQuery, GetOneEflowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneEflowQuery, GetOneEflowQueryVariables>(GetOneEflowDocument, options);
        }
export function useGetOneEflowSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOneEflowQuery, GetOneEflowQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneEflowQuery, GetOneEflowQueryVariables>(GetOneEflowDocument, options);
        }
export type GetOneEflowQueryHookResult = ReturnType<typeof useGetOneEflowQuery>;
export type GetOneEflowLazyQueryHookResult = ReturnType<typeof useGetOneEflowLazyQuery>;
export type GetOneEflowSuspenseQueryHookResult = ReturnType<typeof useGetOneEflowSuspenseQuery>;
export type GetOneEflowQueryResult = Apollo.QueryResult<GetOneEflowQuery, GetOneEflowQueryVariables>;
export const SubscribeEflowChangeDocument = gql`
    subscription subscribeEflowChange($input: FindOneEFlowInput!) {
  eFlowChanged(findOneEFlowInput: $input) {
    asIsState
    toBeState
  }
}
    `;

/**
 * __useSubscribeEflowChangeSubscription__
 *
 * To run a query within a React component, call `useSubscribeEflowChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeEflowChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeEflowChangeSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeEflowChangeSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeEflowChangeSubscription, SubscribeEflowChangeSubscriptionVariables> & ({ variables: SubscribeEflowChangeSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeEflowChangeSubscription, SubscribeEflowChangeSubscriptionVariables>(SubscribeEflowChangeDocument, options);
      }
export type SubscribeEflowChangeSubscriptionHookResult = ReturnType<typeof useSubscribeEflowChangeSubscription>;
export type SubscribeEflowChangeSubscriptionResult = Apollo.SubscriptionResult<SubscribeEflowChangeSubscription>;
export const SubscribeEflowStepsChangeDocument = gql`
    subscription subscribeEflowStepsChange($input: SubscribeEflowStepsInput!) {
  eFlowStepChanged(subscribeEflowStepsInput: $input) {
    ...EflowStepFragment
  }
}
    ${EflowStepFragmentFragmentDoc}`;

/**
 * __useSubscribeEflowStepsChangeSubscription__
 *
 * To run a query within a React component, call `useSubscribeEflowStepsChangeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeEflowStepsChangeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeEflowStepsChangeSubscription({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeEflowStepsChangeSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeEflowStepsChangeSubscription, SubscribeEflowStepsChangeSubscriptionVariables> & ({ variables: SubscribeEflowStepsChangeSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeEflowStepsChangeSubscription, SubscribeEflowStepsChangeSubscriptionVariables>(SubscribeEflowStepsChangeDocument, options);
      }
export type SubscribeEflowStepsChangeSubscriptionHookResult = ReturnType<typeof useSubscribeEflowStepsChangeSubscription>;
export type SubscribeEflowStepsChangeSubscriptionResult = Apollo.SubscriptionResult<SubscribeEflowStepsChangeSubscription>;
export const AddUserMutationDocument = gql`
    mutation AddUserMutation($createEmployeeInput: CreateEmployeeInput!) {
  createEmployee(createEmployeeInput: $createEmployeeInput) {
    ...NewCreatedSystemEmployee
  }
}
    ${NewCreatedSystemEmployeeFragmentDoc}`;
export type AddUserMutationMutationFn = Apollo.MutationFunction<AddUserMutationMutation, AddUserMutationMutationVariables>;

/**
 * __useAddUserMutationMutation__
 *
 * To run a mutation, you first call `useAddUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutationMutation, { data, loading, error }] = useAddUserMutationMutation({
 *   variables: {
 *      createEmployeeInput: // value for 'createEmployeeInput'
 *   },
 * });
 */
export function useAddUserMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutationMutation, AddUserMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutationMutation, AddUserMutationMutationVariables>(AddUserMutationDocument, options);
      }
export type AddUserMutationMutationHookResult = ReturnType<typeof useAddUserMutationMutation>;
export type AddUserMutationMutationResult = Apollo.MutationResult<AddUserMutationMutation>;
export type AddUserMutationMutationOptions = Apollo.BaseMutationOptions<AddUserMutationMutation, AddUserMutationMutationVariables>;
export const CreateEmployeeDocument = gql`
    mutation createEmployee($createEmployeeInput: CreateEmployeeInput!) {
  createEmployee(createEmployeeInput: $createEmployeeInput) {
    ...EmployeeFragment
  }
}
    ${EmployeeFragmentFragmentDoc}`;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      createEmployeeInput: // value for 'createEmployeeInput'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, options);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($input: RemoveEmployeeInput!) {
  removeEmployee(removeEmployeeInput: $input) {
    success
  }
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation updateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(updateEmployeeInput: $input) {
    state
    id
    firstName
    lastName
    email
    organizationId
    systemRole
  }
}
    `;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const UpdateEmployeeUnitStatePositionDocument = gql`
    mutation updateEmployeeUnitStatePosition($input: UpdateEmployeeInput!) {
  updateEmployee(updateEmployeeInput: $input) {
    position
    state
    organizationUnit {
      id
    }
  }
}
    `;
export type UpdateEmployeeUnitStatePositionMutationFn = Apollo.MutationFunction<UpdateEmployeeUnitStatePositionMutation, UpdateEmployeeUnitStatePositionMutationVariables>;

/**
 * __useUpdateEmployeeUnitStatePositionMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeUnitStatePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeUnitStatePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeUnitStatePositionMutation, { data, loading, error }] = useUpdateEmployeeUnitStatePositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeUnitStatePositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeUnitStatePositionMutation, UpdateEmployeeUnitStatePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeUnitStatePositionMutation, UpdateEmployeeUnitStatePositionMutationVariables>(UpdateEmployeeUnitStatePositionDocument, options);
      }
export type UpdateEmployeeUnitStatePositionMutationHookResult = ReturnType<typeof useUpdateEmployeeUnitStatePositionMutation>;
export type UpdateEmployeeUnitStatePositionMutationResult = Apollo.MutationResult<UpdateEmployeeUnitStatePositionMutation>;
export type UpdateEmployeeUnitStatePositionMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeUnitStatePositionMutation, UpdateEmployeeUnitStatePositionMutationVariables>;
export const UpdateEmployeeRoleDocument = gql`
    mutation updateEmployeeRole($input: UpdateEmployeeInput!) {
  updateEmployee(updateEmployeeInput: $input) {
    id
    systemRole
  }
}
    `;
export type UpdateEmployeeRoleMutationFn = Apollo.MutationFunction<UpdateEmployeeRoleMutation, UpdateEmployeeRoleMutationVariables>;

/**
 * __useUpdateEmployeeRoleMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeRoleMutation, { data, loading, error }] = useUpdateEmployeeRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeRoleMutation, UpdateEmployeeRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeRoleMutation, UpdateEmployeeRoleMutationVariables>(UpdateEmployeeRoleDocument, options);
      }
export type UpdateEmployeeRoleMutationHookResult = ReturnType<typeof useUpdateEmployeeRoleMutation>;
export type UpdateEmployeeRoleMutationResult = Apollo.MutationResult<UpdateEmployeeRoleMutation>;
export type UpdateEmployeeRoleMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeRoleMutation, UpdateEmployeeRoleMutationVariables>;
export const UpdateEmployeeStatusDocument = gql`
    mutation updateEmployeeStatus($input: UpdateEmployeeInput!) {
  updateEmployee(updateEmployeeInput: $input) {
    systemRole
  }
}
    `;
export type UpdateEmployeeStatusMutationFn = Apollo.MutationFunction<UpdateEmployeeStatusMutation, UpdateEmployeeStatusMutationVariables>;

/**
 * __useUpdateEmployeeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeStatusMutation, { data, loading, error }] = useUpdateEmployeeStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeStatusMutation, UpdateEmployeeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeStatusMutation, UpdateEmployeeStatusMutationVariables>(UpdateEmployeeStatusDocument, options);
      }
export type UpdateEmployeeStatusMutationHookResult = ReturnType<typeof useUpdateEmployeeStatusMutation>;
export type UpdateEmployeeStatusMutationResult = Apollo.MutationResult<UpdateEmployeeStatusMutation>;
export type UpdateEmployeeStatusMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeStatusMutation, UpdateEmployeeStatusMutationVariables>;
export const UpdateEmployeeWithUnitDataDocument = gql`
    mutation UpdateEmployeeWithUnitData($input: UpdateEmployeeInput!) {
  updateEmployee(updateEmployeeInput: $input) {
    id
    firstName
    lastName
    email
    organizationId
    organizationUnit {
      name
    }
  }
}
    `;
export type UpdateEmployeeWithUnitDataMutationFn = Apollo.MutationFunction<UpdateEmployeeWithUnitDataMutation, UpdateEmployeeWithUnitDataMutationVariables>;

/**
 * __useUpdateEmployeeWithUnitDataMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeWithUnitDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeWithUnitDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeWithUnitDataMutation, { data, loading, error }] = useUpdateEmployeeWithUnitDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeWithUnitDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeWithUnitDataMutation, UpdateEmployeeWithUnitDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeWithUnitDataMutation, UpdateEmployeeWithUnitDataMutationVariables>(UpdateEmployeeWithUnitDataDocument, options);
      }
export type UpdateEmployeeWithUnitDataMutationHookResult = ReturnType<typeof useUpdateEmployeeWithUnitDataMutation>;
export type UpdateEmployeeWithUnitDataMutationResult = Apollo.MutationResult<UpdateEmployeeWithUnitDataMutation>;
export type UpdateEmployeeWithUnitDataMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeWithUnitDataMutation, UpdateEmployeeWithUnitDataMutationVariables>;
export const UpdateProfilePictureDocument = gql`
    mutation UpdateProfilePicture($input: UpdateEmployeeProfileInput!, $file: Upload!) {
  updateEmployeeProfilePicture(
    updateEmployeeProfilePicture: $input
    resourceImage: $file
  ) {
    profilePicture
  }
}
    `;
export type UpdateProfilePictureMutationFn = Apollo.MutationFunction<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>;

/**
 * __useUpdateProfilePictureMutation__
 *
 * To run a mutation, you first call `useUpdateProfilePictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfilePictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfilePictureMutation, { data, loading, error }] = useUpdateProfilePictureMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateProfilePictureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>(UpdateProfilePictureDocument, options);
      }
export type UpdateProfilePictureMutationHookResult = ReturnType<typeof useUpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationResult = Apollo.MutationResult<UpdateProfilePictureMutation>;
export type UpdateProfilePictureMutationOptions = Apollo.BaseMutationOptions<UpdateProfilePictureMutation, UpdateProfilePictureMutationVariables>;
export const UpdateUserNameSurnamePhoneDocument = gql`
    mutation UpdateUserNameSurnamePhone($input: UpdateEmployeeInput!) {
  updateEmployee(updateEmployeeInput: $input) {
    id
    email
    firstName
    lastName
    phone
  }
}
    `;
export type UpdateUserNameSurnamePhoneMutationFn = Apollo.MutationFunction<UpdateUserNameSurnamePhoneMutation, UpdateUserNameSurnamePhoneMutationVariables>;

/**
 * __useUpdateUserNameSurnamePhoneMutation__
 *
 * To run a mutation, you first call `useUpdateUserNameSurnamePhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNameSurnamePhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNameSurnamePhoneMutation, { data, loading, error }] = useUpdateUserNameSurnamePhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserNameSurnamePhoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserNameSurnamePhoneMutation, UpdateUserNameSurnamePhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserNameSurnamePhoneMutation, UpdateUserNameSurnamePhoneMutationVariables>(UpdateUserNameSurnamePhoneDocument, options);
      }
export type UpdateUserNameSurnamePhoneMutationHookResult = ReturnType<typeof useUpdateUserNameSurnamePhoneMutation>;
export type UpdateUserNameSurnamePhoneMutationResult = Apollo.MutationResult<UpdateUserNameSurnamePhoneMutation>;
export type UpdateUserNameSurnamePhoneMutationOptions = Apollo.BaseMutationOptions<UpdateUserNameSurnamePhoneMutation, UpdateUserNameSurnamePhoneMutationVariables>;
export const GetBasicSystemUserDocument = gql`
    query getBasicSystemUser($input: GetEmployee!) {
  getEmployee(getEmployeeInput: $input) {
    email
    firstName
    lastName
    phone
    organizationUnitPath
    organizationUnitGuid
    position
    id
    organizationId
    state
    systemRole
    profilePicture
  }
}
    `;

/**
 * __useGetBasicSystemUserQuery__
 *
 * To run a query within a React component, call `useGetBasicSystemUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicSystemUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicSystemUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBasicSystemUserQuery(baseOptions: Apollo.QueryHookOptions<GetBasicSystemUserQuery, GetBasicSystemUserQueryVariables> & ({ variables: GetBasicSystemUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBasicSystemUserQuery, GetBasicSystemUserQueryVariables>(GetBasicSystemUserDocument, options);
      }
export function useGetBasicSystemUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasicSystemUserQuery, GetBasicSystemUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBasicSystemUserQuery, GetBasicSystemUserQueryVariables>(GetBasicSystemUserDocument, options);
        }
export function useGetBasicSystemUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBasicSystemUserQuery, GetBasicSystemUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBasicSystemUserQuery, GetBasicSystemUserQueryVariables>(GetBasicSystemUserDocument, options);
        }
export type GetBasicSystemUserQueryHookResult = ReturnType<typeof useGetBasicSystemUserQuery>;
export type GetBasicSystemUserLazyQueryHookResult = ReturnType<typeof useGetBasicSystemUserLazyQuery>;
export type GetBasicSystemUserSuspenseQueryHookResult = ReturnType<typeof useGetBasicSystemUserSuspenseQuery>;
export type GetBasicSystemUserQueryResult = Apollo.QueryResult<GetBasicSystemUserQuery, GetBasicSystemUserQueryVariables>;
export const GetEmployeeDocument = gql`
    query getEmployee($input: GetEmployee!) {
  getEmployee(getEmployeeInput: $input) {
    email
    firstName
    lastName
    phone
    organizationUnitPath
    organizationUnitGuid
    position
    id
    organizationId
    state
    systemRole
    profilePicture
    organizationUnit {
      name
    }
  }
}
    `;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmployeeQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables> & ({ variables: GetEmployeeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
      }
export function useGetEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
        }
export function useGetEmployeeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
        }
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeSuspenseQuery>;
export type GetEmployeeQueryResult = Apollo.QueryResult<GetEmployeeQuery, GetEmployeeQueryVariables>;
export const GetEmployeeProfilePictureDocument = gql`
    query getEmployeeProfilePicture($input: GetEmployee!) {
  getEmployee(getEmployeeInput: $input) {
    profilePicture
  }
}
    `;

/**
 * __useGetEmployeeProfilePictureQuery__
 *
 * To run a query within a React component, call `useGetEmployeeProfilePictureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeProfilePictureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeProfilePictureQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmployeeProfilePictureQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeProfilePictureQuery, GetEmployeeProfilePictureQueryVariables> & ({ variables: GetEmployeeProfilePictureQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeProfilePictureQuery, GetEmployeeProfilePictureQueryVariables>(GetEmployeeProfilePictureDocument, options);
      }
export function useGetEmployeeProfilePictureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeProfilePictureQuery, GetEmployeeProfilePictureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeProfilePictureQuery, GetEmployeeProfilePictureQueryVariables>(GetEmployeeProfilePictureDocument, options);
        }
export function useGetEmployeeProfilePictureSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeeProfilePictureQuery, GetEmployeeProfilePictureQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeProfilePictureQuery, GetEmployeeProfilePictureQueryVariables>(GetEmployeeProfilePictureDocument, options);
        }
export type GetEmployeeProfilePictureQueryHookResult = ReturnType<typeof useGetEmployeeProfilePictureQuery>;
export type GetEmployeeProfilePictureLazyQueryHookResult = ReturnType<typeof useGetEmployeeProfilePictureLazyQuery>;
export type GetEmployeeProfilePictureSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeProfilePictureSuspenseQuery>;
export type GetEmployeeProfilePictureQueryResult = Apollo.QueryResult<GetEmployeeProfilePictureQuery, GetEmployeeProfilePictureQueryVariables>;
export const GetEmployeeProfilePictureNameAndSurnameDocument = gql`
    query getEmployeeProfilePictureNameAndSurname($input: GetEmployee!) {
  getEmployee(getEmployeeInput: $input) {
    profilePicture
    firstName
    lastName
    id
  }
}
    `;

/**
 * __useGetEmployeeProfilePictureNameAndSurnameQuery__
 *
 * To run a query within a React component, call `useGetEmployeeProfilePictureNameAndSurnameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeProfilePictureNameAndSurnameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeProfilePictureNameAndSurnameQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmployeeProfilePictureNameAndSurnameQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeProfilePictureNameAndSurnameQuery, GetEmployeeProfilePictureNameAndSurnameQueryVariables> & ({ variables: GetEmployeeProfilePictureNameAndSurnameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeProfilePictureNameAndSurnameQuery, GetEmployeeProfilePictureNameAndSurnameQueryVariables>(GetEmployeeProfilePictureNameAndSurnameDocument, options);
      }
export function useGetEmployeeProfilePictureNameAndSurnameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeProfilePictureNameAndSurnameQuery, GetEmployeeProfilePictureNameAndSurnameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeProfilePictureNameAndSurnameQuery, GetEmployeeProfilePictureNameAndSurnameQueryVariables>(GetEmployeeProfilePictureNameAndSurnameDocument, options);
        }
export function useGetEmployeeProfilePictureNameAndSurnameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeeProfilePictureNameAndSurnameQuery, GetEmployeeProfilePictureNameAndSurnameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeProfilePictureNameAndSurnameQuery, GetEmployeeProfilePictureNameAndSurnameQueryVariables>(GetEmployeeProfilePictureNameAndSurnameDocument, options);
        }
export type GetEmployeeProfilePictureNameAndSurnameQueryHookResult = ReturnType<typeof useGetEmployeeProfilePictureNameAndSurnameQuery>;
export type GetEmployeeProfilePictureNameAndSurnameLazyQueryHookResult = ReturnType<typeof useGetEmployeeProfilePictureNameAndSurnameLazyQuery>;
export type GetEmployeeProfilePictureNameAndSurnameSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeProfilePictureNameAndSurnameSuspenseQuery>;
export type GetEmployeeProfilePictureNameAndSurnameQueryResult = Apollo.QueryResult<GetEmployeeProfilePictureNameAndSurnameQuery, GetEmployeeProfilePictureNameAndSurnameQueryVariables>;
export const GetEmployeeSystemRolesDocument = gql`
    query getEmployeeSystemRoles($input: GetEmployee!) {
  getEmployee(getEmployeeInput: $input) {
    id
    systemRole
  }
}
    `;

/**
 * __useGetEmployeeSystemRolesQuery__
 *
 * To run a query within a React component, call `useGetEmployeeSystemRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeSystemRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeSystemRolesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmployeeSystemRolesQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeSystemRolesQuery, GetEmployeeSystemRolesQueryVariables> & ({ variables: GetEmployeeSystemRolesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeSystemRolesQuery, GetEmployeeSystemRolesQueryVariables>(GetEmployeeSystemRolesDocument, options);
      }
export function useGetEmployeeSystemRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeSystemRolesQuery, GetEmployeeSystemRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeSystemRolesQuery, GetEmployeeSystemRolesQueryVariables>(GetEmployeeSystemRolesDocument, options);
        }
export function useGetEmployeeSystemRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeeSystemRolesQuery, GetEmployeeSystemRolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeSystemRolesQuery, GetEmployeeSystemRolesQueryVariables>(GetEmployeeSystemRolesDocument, options);
        }
export type GetEmployeeSystemRolesQueryHookResult = ReturnType<typeof useGetEmployeeSystemRolesQuery>;
export type GetEmployeeSystemRolesLazyQueryHookResult = ReturnType<typeof useGetEmployeeSystemRolesLazyQuery>;
export type GetEmployeeSystemRolesSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeSystemRolesSuspenseQuery>;
export type GetEmployeeSystemRolesQueryResult = Apollo.QueryResult<GetEmployeeSystemRolesQuery, GetEmployeeSystemRolesQueryVariables>;
export const GetEmployeesForMultiSelectDocument = gql`
    query getEmployeesForMultiSelect($input: EmployeesListArgs!) {
  getEmployees(getEmployeesInput: $input) {
    items {
      id
      firstName
      lastName
      email
      organizationId
      profilePicture
    }
    total
  }
}
    `;

/**
 * __useGetEmployeesForMultiSelectQuery__
 *
 * To run a query within a React component, call `useGetEmployeesForMultiSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesForMultiSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesForMultiSelectQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmployeesForMultiSelectQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesForMultiSelectQuery, GetEmployeesForMultiSelectQueryVariables> & ({ variables: GetEmployeesForMultiSelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesForMultiSelectQuery, GetEmployeesForMultiSelectQueryVariables>(GetEmployeesForMultiSelectDocument, options);
      }
export function useGetEmployeesForMultiSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesForMultiSelectQuery, GetEmployeesForMultiSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesForMultiSelectQuery, GetEmployeesForMultiSelectQueryVariables>(GetEmployeesForMultiSelectDocument, options);
        }
export function useGetEmployeesForMultiSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesForMultiSelectQuery, GetEmployeesForMultiSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesForMultiSelectQuery, GetEmployeesForMultiSelectQueryVariables>(GetEmployeesForMultiSelectDocument, options);
        }
export type GetEmployeesForMultiSelectQueryHookResult = ReturnType<typeof useGetEmployeesForMultiSelectQuery>;
export type GetEmployeesForMultiSelectLazyQueryHookResult = ReturnType<typeof useGetEmployeesForMultiSelectLazyQuery>;
export type GetEmployeesForMultiSelectSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesForMultiSelectSuspenseQuery>;
export type GetEmployeesForMultiSelectQueryResult = Apollo.QueryResult<GetEmployeesForMultiSelectQuery, GetEmployeesForMultiSelectQueryVariables>;
export const GetEmployeesForOrganizationDocument = gql`
    query getEmployeesForOrganization($input: EmployeesListArgs!) {
  getEmployees(getEmployeesInput: $input) {
    items {
      ...EmployeeFragment
    }
    total
  }
}
    ${EmployeeFragmentFragmentDoc}`;

/**
 * __useGetEmployeesForOrganizationQuery__
 *
 * To run a query within a React component, call `useGetEmployeesForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesForOrganizationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEmployeesForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesForOrganizationQuery, GetEmployeesForOrganizationQueryVariables> & ({ variables: GetEmployeesForOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesForOrganizationQuery, GetEmployeesForOrganizationQueryVariables>(GetEmployeesForOrganizationDocument, options);
      }
export function useGetEmployeesForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesForOrganizationQuery, GetEmployeesForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesForOrganizationQuery, GetEmployeesForOrganizationQueryVariables>(GetEmployeesForOrganizationDocument, options);
        }
export function useGetEmployeesForOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesForOrganizationQuery, GetEmployeesForOrganizationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesForOrganizationQuery, GetEmployeesForOrganizationQueryVariables>(GetEmployeesForOrganizationDocument, options);
        }
export type GetEmployeesForOrganizationQueryHookResult = ReturnType<typeof useGetEmployeesForOrganizationQuery>;
export type GetEmployeesForOrganizationLazyQueryHookResult = ReturnType<typeof useGetEmployeesForOrganizationLazyQuery>;
export type GetEmployeesForOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesForOrganizationSuspenseQuery>;
export type GetEmployeesForOrganizationQueryResult = Apollo.QueryResult<GetEmployeesForOrganizationQuery, GetEmployeesForOrganizationQueryVariables>;
export const GetEmployeesForStructureDocument = gql`
    query getEmployeesForStructure($getEmployeesInput: EmployeesListArgs!) {
  getEmployees(getEmployeesInput: $getEmployeesInput) {
    items {
      id
      firstName
      lastName
      email
      position
      organizationId
      profilePicture
    }
    total
  }
}
    `;

/**
 * __useGetEmployeesForStructureQuery__
 *
 * To run a query within a React component, call `useGetEmployeesForStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesForStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesForStructureQuery({
 *   variables: {
 *      getEmployeesInput: // value for 'getEmployeesInput'
 *   },
 * });
 */
export function useGetEmployeesForStructureQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesForStructureQuery, GetEmployeesForStructureQueryVariables> & ({ variables: GetEmployeesForStructureQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesForStructureQuery, GetEmployeesForStructureQueryVariables>(GetEmployeesForStructureDocument, options);
      }
export function useGetEmployeesForStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesForStructureQuery, GetEmployeesForStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesForStructureQuery, GetEmployeesForStructureQueryVariables>(GetEmployeesForStructureDocument, options);
        }
export function useGetEmployeesForStructureSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesForStructureQuery, GetEmployeesForStructureQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesForStructureQuery, GetEmployeesForStructureQueryVariables>(GetEmployeesForStructureDocument, options);
        }
export type GetEmployeesForStructureQueryHookResult = ReturnType<typeof useGetEmployeesForStructureQuery>;
export type GetEmployeesForStructureLazyQueryHookResult = ReturnType<typeof useGetEmployeesForStructureLazyQuery>;
export type GetEmployeesForStructureSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesForStructureSuspenseQuery>;
export type GetEmployeesForStructureQueryResult = Apollo.QueryResult<GetEmployeesForStructureQuery, GetEmployeesForStructureQueryVariables>;
export const GetEmployeesWithOrganizationUnitDocument = gql`
    query getEmployeesWithOrganizationUnit($getEmployeesInput: EmployeesListArgs!) {
  getEmployees(getEmployeesInput: $getEmployeesInput) {
    items {
      id
      firstName
      lastName
      email
      organizationId
      organizationUnit {
        name
      }
    }
    total
  }
}
    `;

/**
 * __useGetEmployeesWithOrganizationUnitQuery__
 *
 * To run a query within a React component, call `useGetEmployeesWithOrganizationUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesWithOrganizationUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesWithOrganizationUnitQuery({
 *   variables: {
 *      getEmployeesInput: // value for 'getEmployeesInput'
 *   },
 * });
 */
export function useGetEmployeesWithOrganizationUnitQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesWithOrganizationUnitQuery, GetEmployeesWithOrganizationUnitQueryVariables> & ({ variables: GetEmployeesWithOrganizationUnitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesWithOrganizationUnitQuery, GetEmployeesWithOrganizationUnitQueryVariables>(GetEmployeesWithOrganizationUnitDocument, options);
      }
export function useGetEmployeesWithOrganizationUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesWithOrganizationUnitQuery, GetEmployeesWithOrganizationUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesWithOrganizationUnitQuery, GetEmployeesWithOrganizationUnitQueryVariables>(GetEmployeesWithOrganizationUnitDocument, options);
        }
export function useGetEmployeesWithOrganizationUnitSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesWithOrganizationUnitQuery, GetEmployeesWithOrganizationUnitQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesWithOrganizationUnitQuery, GetEmployeesWithOrganizationUnitQueryVariables>(GetEmployeesWithOrganizationUnitDocument, options);
        }
export type GetEmployeesWithOrganizationUnitQueryHookResult = ReturnType<typeof useGetEmployeesWithOrganizationUnitQuery>;
export type GetEmployeesWithOrganizationUnitLazyQueryHookResult = ReturnType<typeof useGetEmployeesWithOrganizationUnitLazyQuery>;
export type GetEmployeesWithOrganizationUnitSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesWithOrganizationUnitSuspenseQuery>;
export type GetEmployeesWithOrganizationUnitQueryResult = Apollo.QueryResult<GetEmployeesWithOrganizationUnitQuery, GetEmployeesWithOrganizationUnitQueryVariables>;
export const GetKeyUsersDocument = gql`
    query getKeyUsers($getEmployeesInput: EmployeesListArgs!) {
  getEmployees(getEmployeesInput: $getEmployeesInput) {
    items {
      id
      firstName
      lastName
      email
      organizationUnitGuid
    }
    total
  }
}
    `;

/**
 * __useGetKeyUsersQuery__
 *
 * To run a query within a React component, call `useGetKeyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKeyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKeyUsersQuery({
 *   variables: {
 *      getEmployeesInput: // value for 'getEmployeesInput'
 *   },
 * });
 */
export function useGetKeyUsersQuery(baseOptions: Apollo.QueryHookOptions<GetKeyUsersQuery, GetKeyUsersQueryVariables> & ({ variables: GetKeyUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKeyUsersQuery, GetKeyUsersQueryVariables>(GetKeyUsersDocument, options);
      }
export function useGetKeyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKeyUsersQuery, GetKeyUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKeyUsersQuery, GetKeyUsersQueryVariables>(GetKeyUsersDocument, options);
        }
export function useGetKeyUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetKeyUsersQuery, GetKeyUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetKeyUsersQuery, GetKeyUsersQueryVariables>(GetKeyUsersDocument, options);
        }
export type GetKeyUsersQueryHookResult = ReturnType<typeof useGetKeyUsersQuery>;
export type GetKeyUsersLazyQueryHookResult = ReturnType<typeof useGetKeyUsersLazyQuery>;
export type GetKeyUsersSuspenseQueryHookResult = ReturnType<typeof useGetKeyUsersSuspenseQuery>;
export type GetKeyUsersQueryResult = Apollo.QueryResult<GetKeyUsersQuery, GetKeyUsersQueryVariables>;
export const GetEmployeesDocument = gql`
    query getEmployees($getEmployeesInput: EmployeesListArgs!) {
  getEmployees(getEmployeesInput: $getEmployeesInput) {
    items {
      id
      email
      firstName
      lastName
      systemRole
      organizationId
      state
    }
    total
  }
}
    `;

/**
 * __useGetEmployeesQuery__
 *
 * To run a query within a React component, call `useGetEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesQuery({
 *   variables: {
 *      getEmployeesInput: // value for 'getEmployeesInput'
 *   },
 * });
 */
export function useGetEmployeesQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables> & ({ variables: GetEmployeesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
      }
export function useGetEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
        }
export function useGetEmployeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesQuery, GetEmployeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesQuery, GetEmployeesQueryVariables>(GetEmployeesDocument, options);
        }
export type GetEmployeesQueryHookResult = ReturnType<typeof useGetEmployeesQuery>;
export type GetEmployeesLazyQueryHookResult = ReturnType<typeof useGetEmployeesLazyQuery>;
export type GetEmployeesSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesSuspenseQuery>;
export type GetEmployeesQueryResult = Apollo.QueryResult<GetEmployeesQuery, GetEmployeesQueryVariables>;
export const SendKaizenSubmissionToAcceptDocument = gql`
    mutation SendKaizenSubmissionToAccept($input: ChangeSubmissionStatusInput!) {
  acceptKaizenSubmission(kaizenSubmissionStatusChangeInput: $input) {
    ...KaizenSubmissionFragment
  }
}
    ${KaizenSubmissionFragmentFragmentDoc}`;
export type SendKaizenSubmissionToAcceptMutationFn = Apollo.MutationFunction<SendKaizenSubmissionToAcceptMutation, SendKaizenSubmissionToAcceptMutationVariables>;

/**
 * __useSendKaizenSubmissionToAcceptMutation__
 *
 * To run a mutation, you first call `useSendKaizenSubmissionToAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendKaizenSubmissionToAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendKaizenSubmissionToAcceptMutation, { data, loading, error }] = useSendKaizenSubmissionToAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendKaizenSubmissionToAcceptMutation(baseOptions?: Apollo.MutationHookOptions<SendKaizenSubmissionToAcceptMutation, SendKaizenSubmissionToAcceptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendKaizenSubmissionToAcceptMutation, SendKaizenSubmissionToAcceptMutationVariables>(SendKaizenSubmissionToAcceptDocument, options);
      }
export type SendKaizenSubmissionToAcceptMutationHookResult = ReturnType<typeof useSendKaizenSubmissionToAcceptMutation>;
export type SendKaizenSubmissionToAcceptMutationResult = Apollo.MutationResult<SendKaizenSubmissionToAcceptMutation>;
export type SendKaizenSubmissionToAcceptMutationOptions = Apollo.BaseMutationOptions<SendKaizenSubmissionToAcceptMutation, SendKaizenSubmissionToAcceptMutationVariables>;
export const AcceptSubmissionImplementationDocument = gql`
    mutation AcceptSubmissionImplementation($input: ChangeSubmissionStatusInput!) {
  setKaizenSubmissionImplementedStatus(kaizenSubmissionStatusChangeInput: $input) {
    ...KaizenSubmissionFragment
  }
}
    ${KaizenSubmissionFragmentFragmentDoc}`;
export type AcceptSubmissionImplementationMutationFn = Apollo.MutationFunction<AcceptSubmissionImplementationMutation, AcceptSubmissionImplementationMutationVariables>;

/**
 * __useAcceptSubmissionImplementationMutation__
 *
 * To run a mutation, you first call `useAcceptSubmissionImplementationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSubmissionImplementationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSubmissionImplementationMutation, { data, loading, error }] = useAcceptSubmissionImplementationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptSubmissionImplementationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptSubmissionImplementationMutation, AcceptSubmissionImplementationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptSubmissionImplementationMutation, AcceptSubmissionImplementationMutationVariables>(AcceptSubmissionImplementationDocument, options);
      }
export type AcceptSubmissionImplementationMutationHookResult = ReturnType<typeof useAcceptSubmissionImplementationMutation>;
export type AcceptSubmissionImplementationMutationResult = Apollo.MutationResult<AcceptSubmissionImplementationMutation>;
export type AcceptSubmissionImplementationMutationOptions = Apollo.BaseMutationOptions<AcceptSubmissionImplementationMutation, AcceptSubmissionImplementationMutationVariables>;
export const AddCommissionMemberDocument = gql`
    mutation AddCommissionMember($input: AddCommissionMemberInput!) {
  addCommissionMember(addCommissionMemberInput: $input) {
    success
  }
}
    `;
export type AddCommissionMemberMutationFn = Apollo.MutationFunction<AddCommissionMemberMutation, AddCommissionMemberMutationVariables>;

/**
 * __useAddCommissionMemberMutation__
 *
 * To run a mutation, you first call `useAddCommissionMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommissionMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommissionMemberMutation, { data, loading, error }] = useAddCommissionMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommissionMemberMutation(baseOptions?: Apollo.MutationHookOptions<AddCommissionMemberMutation, AddCommissionMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommissionMemberMutation, AddCommissionMemberMutationVariables>(AddCommissionMemberDocument, options);
      }
export type AddCommissionMemberMutationHookResult = ReturnType<typeof useAddCommissionMemberMutation>;
export type AddCommissionMemberMutationResult = Apollo.MutationResult<AddCommissionMemberMutation>;
export type AddCommissionMemberMutationOptions = Apollo.BaseMutationOptions<AddCommissionMemberMutation, AddCommissionMemberMutationVariables>;
export const CreateSettlementPeriodDocument = gql`
    mutation createSettlementPeriod($input: CreateSettlementPeriodInput!) {
  createSettlementPeriod(createSettlementPeriodInput: $input) {
    ...KaizenSettlementPeriod
  }
}
    ${KaizenSettlementPeriodFragmentDoc}`;
export type CreateSettlementPeriodMutationFn = Apollo.MutationFunction<CreateSettlementPeriodMutation, CreateSettlementPeriodMutationVariables>;

/**
 * __useCreateSettlementPeriodMutation__
 *
 * To run a mutation, you first call `useCreateSettlementPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSettlementPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSettlementPeriodMutation, { data, loading, error }] = useCreateSettlementPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSettlementPeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateSettlementPeriodMutation, CreateSettlementPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSettlementPeriodMutation, CreateSettlementPeriodMutationVariables>(CreateSettlementPeriodDocument, options);
      }
export type CreateSettlementPeriodMutationHookResult = ReturnType<typeof useCreateSettlementPeriodMutation>;
export type CreateSettlementPeriodMutationResult = Apollo.MutationResult<CreateSettlementPeriodMutation>;
export type CreateSettlementPeriodMutationOptions = Apollo.BaseMutationOptions<CreateSettlementPeriodMutation, CreateSettlementPeriodMutationVariables>;
export const AssignSubmissionToRealizationDocument = gql`
    mutation AssignSubmissionToRealization($input: AddSubmissionToRealisationInput!) {
  addKaizenSubmissionToRealisation(kaizenSubmissionAddToRealisation: $input) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type AssignSubmissionToRealizationMutationFn = Apollo.MutationFunction<AssignSubmissionToRealizationMutation, AssignSubmissionToRealizationMutationVariables>;

/**
 * __useAssignSubmissionToRealizationMutation__
 *
 * To run a mutation, you first call `useAssignSubmissionToRealizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignSubmissionToRealizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignSubmissionToRealizationMutation, { data, loading, error }] = useAssignSubmissionToRealizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignSubmissionToRealizationMutation(baseOptions?: Apollo.MutationHookOptions<AssignSubmissionToRealizationMutation, AssignSubmissionToRealizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignSubmissionToRealizationMutation, AssignSubmissionToRealizationMutationVariables>(AssignSubmissionToRealizationDocument, options);
      }
export type AssignSubmissionToRealizationMutationHookResult = ReturnType<typeof useAssignSubmissionToRealizationMutation>;
export type AssignSubmissionToRealizationMutationResult = Apollo.MutationResult<AssignSubmissionToRealizationMutation>;
export type AssignSubmissionToRealizationMutationOptions = Apollo.BaseMutationOptions<AssignSubmissionToRealizationMutation, AssignSubmissionToRealizationMutationVariables>;
export const CancelSubmissionDocument = gql`
    mutation CancelSubmission($input: ChangeSubmissionStatusInput!) {
  kaizenCancelSubmission(kaizenSubmissionCancel: $input) {
    ...KaizenSubmissionFragment
  }
}
    ${KaizenSubmissionFragmentFragmentDoc}`;
export type CancelSubmissionMutationFn = Apollo.MutationFunction<CancelSubmissionMutation, CancelSubmissionMutationVariables>;

/**
 * __useCancelSubmissionMutation__
 *
 * To run a mutation, you first call `useCancelSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubmissionMutation, { data, loading, error }] = useCancelSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubmissionMutation, CancelSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubmissionMutation, CancelSubmissionMutationVariables>(CancelSubmissionDocument, options);
      }
export type CancelSubmissionMutationHookResult = ReturnType<typeof useCancelSubmissionMutation>;
export type CancelSubmissionMutationResult = Apollo.MutationResult<CancelSubmissionMutation>;
export type CancelSubmissionMutationOptions = Apollo.BaseMutationOptions<CancelSubmissionMutation, CancelSubmissionMutationVariables>;
export const ChangeKaizenSubmissionStatusDocument = gql`
    mutation ChangeKaizenSubmissionStatus($input: ChangeKaizenSubmissionRootStatusInput!) {
  changeKaizenSubmissionStatus(changeKaizenSubmissionStatusInput: $input) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type ChangeKaizenSubmissionStatusMutationFn = Apollo.MutationFunction<ChangeKaizenSubmissionStatusMutation, ChangeKaizenSubmissionStatusMutationVariables>;

/**
 * __useChangeKaizenSubmissionStatusMutation__
 *
 * To run a mutation, you first call `useChangeKaizenSubmissionStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeKaizenSubmissionStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeKaizenSubmissionStatusMutation, { data, loading, error }] = useChangeKaizenSubmissionStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeKaizenSubmissionStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeKaizenSubmissionStatusMutation, ChangeKaizenSubmissionStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeKaizenSubmissionStatusMutation, ChangeKaizenSubmissionStatusMutationVariables>(ChangeKaizenSubmissionStatusDocument, options);
      }
export type ChangeKaizenSubmissionStatusMutationHookResult = ReturnType<typeof useChangeKaizenSubmissionStatusMutation>;
export type ChangeKaizenSubmissionStatusMutationResult = Apollo.MutationResult<ChangeKaizenSubmissionStatusMutation>;
export type ChangeKaizenSubmissionStatusMutationOptions = Apollo.BaseMutationOptions<ChangeKaizenSubmissionStatusMutation, ChangeKaizenSubmissionStatusMutationVariables>;
export const CreateKaizenScoringDocument = gql`
    mutation CreateKaizenScoring($input: CreateKaizenScoringInput!) {
  createKaizenScoring(createKaizenScoringInput: $input) {
    id
    createdAt
    scoring
  }
}
    `;
export type CreateKaizenScoringMutationFn = Apollo.MutationFunction<CreateKaizenScoringMutation, CreateKaizenScoringMutationVariables>;

/**
 * __useCreateKaizenScoringMutation__
 *
 * To run a mutation, you first call `useCreateKaizenScoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKaizenScoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKaizenScoringMutation, { data, loading, error }] = useCreateKaizenScoringMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKaizenScoringMutation(baseOptions?: Apollo.MutationHookOptions<CreateKaizenScoringMutation, CreateKaizenScoringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKaizenScoringMutation, CreateKaizenScoringMutationVariables>(CreateKaizenScoringDocument, options);
      }
export type CreateKaizenScoringMutationHookResult = ReturnType<typeof useCreateKaizenScoringMutation>;
export type CreateKaizenScoringMutationResult = Apollo.MutationResult<CreateKaizenScoringMutation>;
export type CreateKaizenScoringMutationOptions = Apollo.BaseMutationOptions<CreateKaizenScoringMutation, CreateKaizenScoringMutationVariables>;
export const CreateKaizenSubmissionDocument = gql`
    mutation CreateKaizenSubmission($input: CreateKaizenSubmissionInput!, $problemFiles: [Upload!], $solutionFiles: [Upload!]) {
  createKaizenSubmission(
    createKaizenSubmissionInput: $input
    problemFiles: $problemFiles
    solutionFiles: $solutionFiles
  ) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type CreateKaizenSubmissionMutationFn = Apollo.MutationFunction<CreateKaizenSubmissionMutation, CreateKaizenSubmissionMutationVariables>;

/**
 * __useCreateKaizenSubmissionMutation__
 *
 * To run a mutation, you first call `useCreateKaizenSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKaizenSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKaizenSubmissionMutation, { data, loading, error }] = useCreateKaizenSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      problemFiles: // value for 'problemFiles'
 *      solutionFiles: // value for 'solutionFiles'
 *   },
 * });
 */
export function useCreateKaizenSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateKaizenSubmissionMutation, CreateKaizenSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKaizenSubmissionMutation, CreateKaizenSubmissionMutationVariables>(CreateKaizenSubmissionDocument, options);
      }
export type CreateKaizenSubmissionMutationHookResult = ReturnType<typeof useCreateKaizenSubmissionMutation>;
export type CreateKaizenSubmissionMutationResult = Apollo.MutationResult<CreateKaizenSubmissionMutation>;
export type CreateKaizenSubmissionMutationOptions = Apollo.BaseMutationOptions<CreateKaizenSubmissionMutation, CreateKaizenSubmissionMutationVariables>;
export const SetKaizenSubmissionImplementationAcceptedStatusDocument = gql`
    mutation SetKaizenSubmissionImplementationAcceptedStatus($input: ChangeSubmissionStatusInput!) {
  setKaizenSubmissionImplementationAcceptedStatus(
    kaizenSubmissionStatusChangeInput: $input
  ) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type SetKaizenSubmissionImplementationAcceptedStatusMutationFn = Apollo.MutationFunction<SetKaizenSubmissionImplementationAcceptedStatusMutation, SetKaizenSubmissionImplementationAcceptedStatusMutationVariables>;

/**
 * __useSetKaizenSubmissionImplementationAcceptedStatusMutation__
 *
 * To run a mutation, you first call `useSetKaizenSubmissionImplementationAcceptedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKaizenSubmissionImplementationAcceptedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKaizenSubmissionImplementationAcceptedStatusMutation, { data, loading, error }] = useSetKaizenSubmissionImplementationAcceptedStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetKaizenSubmissionImplementationAcceptedStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetKaizenSubmissionImplementationAcceptedStatusMutation, SetKaizenSubmissionImplementationAcceptedStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetKaizenSubmissionImplementationAcceptedStatusMutation, SetKaizenSubmissionImplementationAcceptedStatusMutationVariables>(SetKaizenSubmissionImplementationAcceptedStatusDocument, options);
      }
export type SetKaizenSubmissionImplementationAcceptedStatusMutationHookResult = ReturnType<typeof useSetKaizenSubmissionImplementationAcceptedStatusMutation>;
export type SetKaizenSubmissionImplementationAcceptedStatusMutationResult = Apollo.MutationResult<SetKaizenSubmissionImplementationAcceptedStatusMutation>;
export type SetKaizenSubmissionImplementationAcceptedStatusMutationOptions = Apollo.BaseMutationOptions<SetKaizenSubmissionImplementationAcceptedStatusMutation, SetKaizenSubmissionImplementationAcceptedStatusMutationVariables>;
export const SetKaizenSubmissionInProgressStatusDocument = gql`
    mutation SetKaizenSubmissionInProgressStatus($input: ChangeSubmissionStatusInput!) {
  setKaizenSubmissionInProgressStatus(kaizenSubmissionStatusChangeInput: $input) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type SetKaizenSubmissionInProgressStatusMutationFn = Apollo.MutationFunction<SetKaizenSubmissionInProgressStatusMutation, SetKaizenSubmissionInProgressStatusMutationVariables>;

/**
 * __useSetKaizenSubmissionInProgressStatusMutation__
 *
 * To run a mutation, you first call `useSetKaizenSubmissionInProgressStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKaizenSubmissionInProgressStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKaizenSubmissionInProgressStatusMutation, { data, loading, error }] = useSetKaizenSubmissionInProgressStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetKaizenSubmissionInProgressStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetKaizenSubmissionInProgressStatusMutation, SetKaizenSubmissionInProgressStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetKaizenSubmissionInProgressStatusMutation, SetKaizenSubmissionInProgressStatusMutationVariables>(SetKaizenSubmissionInProgressStatusDocument, options);
      }
export type SetKaizenSubmissionInProgressStatusMutationHookResult = ReturnType<typeof useSetKaizenSubmissionInProgressStatusMutation>;
export type SetKaizenSubmissionInProgressStatusMutationResult = Apollo.MutationResult<SetKaizenSubmissionInProgressStatusMutation>;
export type SetKaizenSubmissionInProgressStatusMutationOptions = Apollo.BaseMutationOptions<SetKaizenSubmissionInProgressStatusMutation, SetKaizenSubmissionInProgressStatusMutationVariables>;
export const RejectSubmissionImplementationDocument = gql`
    mutation RejectSubmissionImplementation($input: RejectKaizenSubmissionInput!) {
  rejectSubmissionImplementation(rejectKaizenSubmissionInput: $input) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type RejectSubmissionImplementationMutationFn = Apollo.MutationFunction<RejectSubmissionImplementationMutation, RejectSubmissionImplementationMutationVariables>;

/**
 * __useRejectSubmissionImplementationMutation__
 *
 * To run a mutation, you first call `useRejectSubmissionImplementationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectSubmissionImplementationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectSubmissionImplementationMutation, { data, loading, error }] = useRejectSubmissionImplementationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectSubmissionImplementationMutation(baseOptions?: Apollo.MutationHookOptions<RejectSubmissionImplementationMutation, RejectSubmissionImplementationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectSubmissionImplementationMutation, RejectSubmissionImplementationMutationVariables>(RejectSubmissionImplementationDocument, options);
      }
export type RejectSubmissionImplementationMutationHookResult = ReturnType<typeof useRejectSubmissionImplementationMutation>;
export type RejectSubmissionImplementationMutationResult = Apollo.MutationResult<RejectSubmissionImplementationMutation>;
export type RejectSubmissionImplementationMutationOptions = Apollo.BaseMutationOptions<RejectSubmissionImplementationMutation, RejectSubmissionImplementationMutationVariables>;
export const RejectSingleSubmissionDocument = gql`
    mutation RejectSingleSubmission($input: RejectKaizenSubmissionInput!) {
  rejectSingleSubmission(rejectKaizenSubmissionInput: $input) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type RejectSingleSubmissionMutationFn = Apollo.MutationFunction<RejectSingleSubmissionMutation, RejectSingleSubmissionMutationVariables>;

/**
 * __useRejectSingleSubmissionMutation__
 *
 * To run a mutation, you first call `useRejectSingleSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectSingleSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectSingleSubmissionMutation, { data, loading, error }] = useRejectSingleSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectSingleSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<RejectSingleSubmissionMutation, RejectSingleSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectSingleSubmissionMutation, RejectSingleSubmissionMutationVariables>(RejectSingleSubmissionDocument, options);
      }
export type RejectSingleSubmissionMutationHookResult = ReturnType<typeof useRejectSingleSubmissionMutation>;
export type RejectSingleSubmissionMutationResult = Apollo.MutationResult<RejectSingleSubmissionMutation>;
export type RejectSingleSubmissionMutationOptions = Apollo.BaseMutationOptions<RejectSingleSubmissionMutation, RejectSingleSubmissionMutationVariables>;
export const RemoveCommissionMemberDocument = gql`
    mutation RemoveCommissionMember($input: RemoveCommissionMemberInput!) {
  removeCommissionMember(removeCommissionMemberInput: $input) {
    success
  }
}
    `;
export type RemoveCommissionMemberMutationFn = Apollo.MutationFunction<RemoveCommissionMemberMutation, RemoveCommissionMemberMutationVariables>;

/**
 * __useRemoveCommissionMemberMutation__
 *
 * To run a mutation, you first call `useRemoveCommissionMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommissionMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommissionMemberMutation, { data, loading, error }] = useRemoveCommissionMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveCommissionMemberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCommissionMemberMutation, RemoveCommissionMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCommissionMemberMutation, RemoveCommissionMemberMutationVariables>(RemoveCommissionMemberDocument, options);
      }
export type RemoveCommissionMemberMutationHookResult = ReturnType<typeof useRemoveCommissionMemberMutation>;
export type RemoveCommissionMemberMutationResult = Apollo.MutationResult<RemoveCommissionMemberMutation>;
export type RemoveCommissionMemberMutationOptions = Apollo.BaseMutationOptions<RemoveCommissionMemberMutation, RemoveCommissionMemberMutationVariables>;
export const RemoveKaizenSubmissionDocument = gql`
    mutation RemoveKaizenSubmission($input: RemoveKaizenSubmissionInput!) {
  removeKaizenSubmission(removeKaizenSubmissionInput: $input) {
    success
  }
}
    `;
export type RemoveKaizenSubmissionMutationFn = Apollo.MutationFunction<RemoveKaizenSubmissionMutation, RemoveKaizenSubmissionMutationVariables>;

/**
 * __useRemoveKaizenSubmissionMutation__
 *
 * To run a mutation, you first call `useRemoveKaizenSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKaizenSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKaizenSubmissionMutation, { data, loading, error }] = useRemoveKaizenSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveKaizenSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKaizenSubmissionMutation, RemoveKaizenSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveKaizenSubmissionMutation, RemoveKaizenSubmissionMutationVariables>(RemoveKaizenSubmissionDocument, options);
      }
export type RemoveKaizenSubmissionMutationHookResult = ReturnType<typeof useRemoveKaizenSubmissionMutation>;
export type RemoveKaizenSubmissionMutationResult = Apollo.MutationResult<RemoveKaizenSubmissionMutation>;
export type RemoveKaizenSubmissionMutationOptions = Apollo.BaseMutationOptions<RemoveKaizenSubmissionMutation, RemoveKaizenSubmissionMutationVariables>;
export const RemoveSettlementPeriodDocument = gql`
    mutation RemoveSettlementPeriod($input: RemoveSettlementPeriodInput!) {
  removeSettlementPeriod(removeSettlementPeriodInput: $input) {
    success
  }
}
    `;
export type RemoveSettlementPeriodMutationFn = Apollo.MutationFunction<RemoveSettlementPeriodMutation, RemoveSettlementPeriodMutationVariables>;

/**
 * __useRemoveSettlementPeriodMutation__
 *
 * To run a mutation, you first call `useRemoveSettlementPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSettlementPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSettlementPeriodMutation, { data, loading, error }] = useRemoveSettlementPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSettlementPeriodMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSettlementPeriodMutation, RemoveSettlementPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSettlementPeriodMutation, RemoveSettlementPeriodMutationVariables>(RemoveSettlementPeriodDocument, options);
      }
export type RemoveSettlementPeriodMutationHookResult = ReturnType<typeof useRemoveSettlementPeriodMutation>;
export type RemoveSettlementPeriodMutationResult = Apollo.MutationResult<RemoveSettlementPeriodMutation>;
export type RemoveSettlementPeriodMutationOptions = Apollo.BaseMutationOptions<RemoveSettlementPeriodMutation, RemoveSettlementPeriodMutationVariables>;
export const SendToAdjustmentDocument = gql`
    mutation SendToAdjustment($input: SendToAdjustmentKaizenSubmissionInput!) {
  sendToAdjustment(sendToAdjustmentInput: $input) {
    ...KaizenSubmissionFragment
  }
}
    ${KaizenSubmissionFragmentFragmentDoc}`;
export type SendToAdjustmentMutationFn = Apollo.MutationFunction<SendToAdjustmentMutation, SendToAdjustmentMutationVariables>;

/**
 * __useSendToAdjustmentMutation__
 *
 * To run a mutation, you first call `useSendToAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendToAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendToAdjustmentMutation, { data, loading, error }] = useSendToAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendToAdjustmentMutation(baseOptions?: Apollo.MutationHookOptions<SendToAdjustmentMutation, SendToAdjustmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendToAdjustmentMutation, SendToAdjustmentMutationVariables>(SendToAdjustmentDocument, options);
      }
export type SendToAdjustmentMutationHookResult = ReturnType<typeof useSendToAdjustmentMutation>;
export type SendToAdjustmentMutationResult = Apollo.MutationResult<SendToAdjustmentMutation>;
export type SendToAdjustmentMutationOptions = Apollo.BaseMutationOptions<SendToAdjustmentMutation, SendToAdjustmentMutationVariables>;
export const SetCommissionLeaderDocument = gql`
    mutation SetCommissionLeader($input: SetCommissionLeaderInput!) {
  setCommissionLeader(setCommissionLeaderInput: $input) {
    success
  }
}
    `;
export type SetCommissionLeaderMutationFn = Apollo.MutationFunction<SetCommissionLeaderMutation, SetCommissionLeaderMutationVariables>;

/**
 * __useSetCommissionLeaderMutation__
 *
 * To run a mutation, you first call `useSetCommissionLeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCommissionLeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCommissionLeaderMutation, { data, loading, error }] = useSetCommissionLeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetCommissionLeaderMutation(baseOptions?: Apollo.MutationHookOptions<SetCommissionLeaderMutation, SetCommissionLeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCommissionLeaderMutation, SetCommissionLeaderMutationVariables>(SetCommissionLeaderDocument, options);
      }
export type SetCommissionLeaderMutationHookResult = ReturnType<typeof useSetCommissionLeaderMutation>;
export type SetCommissionLeaderMutationResult = Apollo.MutationResult<SetCommissionLeaderMutation>;
export type SetCommissionLeaderMutationOptions = Apollo.BaseMutationOptions<SetCommissionLeaderMutation, SetCommissionLeaderMutationVariables>;
export const SetKaizenSubmissionCommissionAssessmentStatusDocument = gql`
    mutation SetKaizenSubmissionCommissionAssessmentStatus($input: ChangeSubmissionStatusInput!) {
  setKaizenSubmissionCommissionAssessmentStatus(
    kaizenSubmissionStatusChangeInput: $input
  ) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type SetKaizenSubmissionCommissionAssessmentStatusMutationFn = Apollo.MutationFunction<SetKaizenSubmissionCommissionAssessmentStatusMutation, SetKaizenSubmissionCommissionAssessmentStatusMutationVariables>;

/**
 * __useSetKaizenSubmissionCommissionAssessmentStatusMutation__
 *
 * To run a mutation, you first call `useSetKaizenSubmissionCommissionAssessmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKaizenSubmissionCommissionAssessmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKaizenSubmissionCommissionAssessmentStatusMutation, { data, loading, error }] = useSetKaizenSubmissionCommissionAssessmentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetKaizenSubmissionCommissionAssessmentStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetKaizenSubmissionCommissionAssessmentStatusMutation, SetKaizenSubmissionCommissionAssessmentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetKaizenSubmissionCommissionAssessmentStatusMutation, SetKaizenSubmissionCommissionAssessmentStatusMutationVariables>(SetKaizenSubmissionCommissionAssessmentStatusDocument, options);
      }
export type SetKaizenSubmissionCommissionAssessmentStatusMutationHookResult = ReturnType<typeof useSetKaizenSubmissionCommissionAssessmentStatusMutation>;
export type SetKaizenSubmissionCommissionAssessmentStatusMutationResult = Apollo.MutationResult<SetKaizenSubmissionCommissionAssessmentStatusMutation>;
export type SetKaizenSubmissionCommissionAssessmentStatusMutationOptions = Apollo.BaseMutationOptions<SetKaizenSubmissionCommissionAssessmentStatusMutation, SetKaizenSubmissionCommissionAssessmentStatusMutationVariables>;
export const SetKaizenSubmissionSupervisorAssessmentStatusDocument = gql`
    mutation SetKaizenSubmissionSupervisorAssessmentStatus($input: ChangeSubmissionStatusInput!) {
  setKaizenSubmissionSupervisorAssessmentStatus(
    kaizenSubmissionStatusChangeInput: $input
  ) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type SetKaizenSubmissionSupervisorAssessmentStatusMutationFn = Apollo.MutationFunction<SetKaizenSubmissionSupervisorAssessmentStatusMutation, SetKaizenSubmissionSupervisorAssessmentStatusMutationVariables>;

/**
 * __useSetKaizenSubmissionSupervisorAssessmentStatusMutation__
 *
 * To run a mutation, you first call `useSetKaizenSubmissionSupervisorAssessmentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKaizenSubmissionSupervisorAssessmentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKaizenSubmissionSupervisorAssessmentStatusMutation, { data, loading, error }] = useSetKaizenSubmissionSupervisorAssessmentStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetKaizenSubmissionSupervisorAssessmentStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetKaizenSubmissionSupervisorAssessmentStatusMutation, SetKaizenSubmissionSupervisorAssessmentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetKaizenSubmissionSupervisorAssessmentStatusMutation, SetKaizenSubmissionSupervisorAssessmentStatusMutationVariables>(SetKaizenSubmissionSupervisorAssessmentStatusDocument, options);
      }
export type SetKaizenSubmissionSupervisorAssessmentStatusMutationHookResult = ReturnType<typeof useSetKaizenSubmissionSupervisorAssessmentStatusMutation>;
export type SetKaizenSubmissionSupervisorAssessmentStatusMutationResult = Apollo.MutationResult<SetKaizenSubmissionSupervisorAssessmentStatusMutation>;
export type SetKaizenSubmissionSupervisorAssessmentStatusMutationOptions = Apollo.BaseMutationOptions<SetKaizenSubmissionSupervisorAssessmentStatusMutation, SetKaizenSubmissionSupervisorAssessmentStatusMutationVariables>;
export const UpdateKaizenSubmissionDocument = gql`
    mutation UpdateKaizenSubmission($input: UpdateKaizenSubmissionInput!, $problemFiles: [Upload!], $solutionFiles: [Upload!]) {
  updateKaizenSubmission(
    updateKaizenSubmissionInput: $input
    problemFiles: $problemFiles
    solutionFiles: $solutionFiles
  ) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;
export type UpdateKaizenSubmissionMutationFn = Apollo.MutationFunction<UpdateKaizenSubmissionMutation, UpdateKaizenSubmissionMutationVariables>;

/**
 * __useUpdateKaizenSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateKaizenSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKaizenSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKaizenSubmissionMutation, { data, loading, error }] = useUpdateKaizenSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      problemFiles: // value for 'problemFiles'
 *      solutionFiles: // value for 'solutionFiles'
 *   },
 * });
 */
export function useUpdateKaizenSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKaizenSubmissionMutation, UpdateKaizenSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKaizenSubmissionMutation, UpdateKaizenSubmissionMutationVariables>(UpdateKaizenSubmissionDocument, options);
      }
export type UpdateKaizenSubmissionMutationHookResult = ReturnType<typeof useUpdateKaizenSubmissionMutation>;
export type UpdateKaizenSubmissionMutationResult = Apollo.MutationResult<UpdateKaizenSubmissionMutation>;
export type UpdateKaizenSubmissionMutationOptions = Apollo.BaseMutationOptions<UpdateKaizenSubmissionMutation, UpdateKaizenSubmissionMutationVariables>;
export const UpdateSettlementPeriodDocument = gql`
    mutation UpdateSettlementPeriod($input: UpdateSettlementPeriodInput!) {
  updateSettlementPeriod(updateSettlementPeriodInput: $input) {
    ...KaizenSettlementPeriod
  }
}
    ${KaizenSettlementPeriodFragmentDoc}`;
export type UpdateSettlementPeriodMutationFn = Apollo.MutationFunction<UpdateSettlementPeriodMutation, UpdateSettlementPeriodMutationVariables>;

/**
 * __useUpdateSettlementPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateSettlementPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettlementPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettlementPeriodMutation, { data, loading, error }] = useUpdateSettlementPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSettlementPeriodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettlementPeriodMutation, UpdateSettlementPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettlementPeriodMutation, UpdateSettlementPeriodMutationVariables>(UpdateSettlementPeriodDocument, options);
      }
export type UpdateSettlementPeriodMutationHookResult = ReturnType<typeof useUpdateSettlementPeriodMutation>;
export type UpdateSettlementPeriodMutationResult = Apollo.MutationResult<UpdateSettlementPeriodMutation>;
export type UpdateSettlementPeriodMutationOptions = Apollo.BaseMutationOptions<UpdateSettlementPeriodMutation, UpdateSettlementPeriodMutationVariables>;
export const FindAllSubmissionsDocument = gql`
    query FindAllSubmissions($input: FindAllSubmissionsInput!) {
  findAllKaizenSubmissions(findAllKaizenSubmissionsInput: $input) {
    total
    items {
      ...KaizenSubmissionFragment
    }
  }
}
    ${KaizenSubmissionFragmentFragmentDoc}`;

/**
 * __useFindAllSubmissionsQuery__
 *
 * To run a query within a React component, call `useFindAllSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllSubmissionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<FindAllSubmissionsQuery, FindAllSubmissionsQueryVariables> & ({ variables: FindAllSubmissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllSubmissionsQuery, FindAllSubmissionsQueryVariables>(FindAllSubmissionsDocument, options);
      }
export function useFindAllSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllSubmissionsQuery, FindAllSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllSubmissionsQuery, FindAllSubmissionsQueryVariables>(FindAllSubmissionsDocument, options);
        }
export function useFindAllSubmissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAllSubmissionsQuery, FindAllSubmissionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAllSubmissionsQuery, FindAllSubmissionsQueryVariables>(FindAllSubmissionsDocument, options);
        }
export type FindAllSubmissionsQueryHookResult = ReturnType<typeof useFindAllSubmissionsQuery>;
export type FindAllSubmissionsLazyQueryHookResult = ReturnType<typeof useFindAllSubmissionsLazyQuery>;
export type FindAllSubmissionsSuspenseQueryHookResult = ReturnType<typeof useFindAllSubmissionsSuspenseQuery>;
export type FindAllSubmissionsQueryResult = Apollo.QueryResult<FindAllSubmissionsQuery, FindAllSubmissionsQueryVariables>;
export const FindDefaultSubmissionInfoDocument = gql`
    query FindDefaultSubmissionInfo($input: FindKaizenSubmissionInput!) {
  findSingleSubmission(findKaizenSubmissionInput: $input) {
    id
    name
    version
    organizationUnitId
    organizationUnit {
      name
    }
    problem {
      description
      files {
        fileName
        fileExtension
        fileUrl
        id
        organizationId
      }
    }
    submissionKey
    status
    solution {
      description
      files {
        fileName
        fileExtension
        fileUrl
        id
        organizationId
      }
    }
    authors {
      id
      firstName
      lastName
      email
      organizationUnit {
        name
      }
      profilePicture
    }
  }
}
    `;

/**
 * __useFindDefaultSubmissionInfoQuery__
 *
 * To run a query within a React component, call `useFindDefaultSubmissionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDefaultSubmissionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDefaultSubmissionInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindDefaultSubmissionInfoQuery(baseOptions: Apollo.QueryHookOptions<FindDefaultSubmissionInfoQuery, FindDefaultSubmissionInfoQueryVariables> & ({ variables: FindDefaultSubmissionInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindDefaultSubmissionInfoQuery, FindDefaultSubmissionInfoQueryVariables>(FindDefaultSubmissionInfoDocument, options);
      }
export function useFindDefaultSubmissionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindDefaultSubmissionInfoQuery, FindDefaultSubmissionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindDefaultSubmissionInfoQuery, FindDefaultSubmissionInfoQueryVariables>(FindDefaultSubmissionInfoDocument, options);
        }
export function useFindDefaultSubmissionInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindDefaultSubmissionInfoQuery, FindDefaultSubmissionInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindDefaultSubmissionInfoQuery, FindDefaultSubmissionInfoQueryVariables>(FindDefaultSubmissionInfoDocument, options);
        }
export type FindDefaultSubmissionInfoQueryHookResult = ReturnType<typeof useFindDefaultSubmissionInfoQuery>;
export type FindDefaultSubmissionInfoLazyQueryHookResult = ReturnType<typeof useFindDefaultSubmissionInfoLazyQuery>;
export type FindDefaultSubmissionInfoSuspenseQueryHookResult = ReturnType<typeof useFindDefaultSubmissionInfoSuspenseQuery>;
export type FindDefaultSubmissionInfoQueryResult = Apollo.QueryResult<FindDefaultSubmissionInfoQuery, FindDefaultSubmissionInfoQueryVariables>;
export const FindKaizenModuleInfoDocument = gql`
    query FindKaizenModuleInfo($input: FindModuleInfoInput!) {
  findKaizenModuleInfo(findKaizenModuleInput: $input) {
    scoringSet {
      scoring
    }
    currentSettlementPeriod {
      id
      periodName
    }
    submissionFlow {
      id
    }
  }
}
    `;

/**
 * __useFindKaizenModuleInfoQuery__
 *
 * To run a query within a React component, call `useFindKaizenModuleInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindKaizenModuleInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindKaizenModuleInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindKaizenModuleInfoQuery(baseOptions: Apollo.QueryHookOptions<FindKaizenModuleInfoQuery, FindKaizenModuleInfoQueryVariables> & ({ variables: FindKaizenModuleInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindKaizenModuleInfoQuery, FindKaizenModuleInfoQueryVariables>(FindKaizenModuleInfoDocument, options);
      }
export function useFindKaizenModuleInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindKaizenModuleInfoQuery, FindKaizenModuleInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindKaizenModuleInfoQuery, FindKaizenModuleInfoQueryVariables>(FindKaizenModuleInfoDocument, options);
        }
export function useFindKaizenModuleInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindKaizenModuleInfoQuery, FindKaizenModuleInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindKaizenModuleInfoQuery, FindKaizenModuleInfoQueryVariables>(FindKaizenModuleInfoDocument, options);
        }
export type FindKaizenModuleInfoQueryHookResult = ReturnType<typeof useFindKaizenModuleInfoQuery>;
export type FindKaizenModuleInfoLazyQueryHookResult = ReturnType<typeof useFindKaizenModuleInfoLazyQuery>;
export type FindKaizenModuleInfoSuspenseQueryHookResult = ReturnType<typeof useFindKaizenModuleInfoSuspenseQuery>;
export type FindKaizenModuleInfoQueryResult = Apollo.QueryResult<FindKaizenModuleInfoQuery, FindKaizenModuleInfoQueryVariables>;
export const FindSingleKaizenReportDocument = gql`
    query findSingleKaizenReport($input: FindSingleReportInput!) {
  findSingleKaizenReport(findSingleKaizenReportInput: $input) {
    avgProcessingTime
    organizationId
    totalNumberOfSubmissions
    totalParticipantsNumber
    submissionProcessingTime {
      ...SubmissionProcessingTimeFragment
    }
    submissionStatusCount {
      ...SubmissionStatusCountFragment
    }
  }
}
    ${SubmissionProcessingTimeFragmentFragmentDoc}
${SubmissionStatusCountFragmentFragmentDoc}`;

/**
 * __useFindSingleKaizenReportQuery__
 *
 * To run a query within a React component, call `useFindSingleKaizenReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSingleKaizenReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSingleKaizenReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindSingleKaizenReportQuery(baseOptions: Apollo.QueryHookOptions<FindSingleKaizenReportQuery, FindSingleKaizenReportQueryVariables> & ({ variables: FindSingleKaizenReportQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSingleKaizenReportQuery, FindSingleKaizenReportQueryVariables>(FindSingleKaizenReportDocument, options);
      }
export function useFindSingleKaizenReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSingleKaizenReportQuery, FindSingleKaizenReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSingleKaizenReportQuery, FindSingleKaizenReportQueryVariables>(FindSingleKaizenReportDocument, options);
        }
export function useFindSingleKaizenReportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindSingleKaizenReportQuery, FindSingleKaizenReportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindSingleKaizenReportQuery, FindSingleKaizenReportQueryVariables>(FindSingleKaizenReportDocument, options);
        }
export type FindSingleKaizenReportQueryHookResult = ReturnType<typeof useFindSingleKaizenReportQuery>;
export type FindSingleKaizenReportLazyQueryHookResult = ReturnType<typeof useFindSingleKaizenReportLazyQuery>;
export type FindSingleKaizenReportSuspenseQueryHookResult = ReturnType<typeof useFindSingleKaizenReportSuspenseQuery>;
export type FindSingleKaizenReportQueryResult = Apollo.QueryResult<FindSingleKaizenReportQuery, FindSingleKaizenReportQueryVariables>;
export const FindKaizenSubmissionFlowDocument = gql`
    query FindKaizenSubmissionFlow($input: FindModuleInfoInput!) {
  findKaizenModuleInfo(findKaizenModuleInput: $input) {
    submissionFlow {
      id
      ACCEPTED
      ADJUSTMENT
      COMMISSION_ASSESSMENT
      DRAFT
      IMPLEMENTATION_ACCEPTED
      IMPLEMENTATION_REJECTED
      IMPLEMENTED
      IN_PROGRESS
      REJECTED
      SUPERVISOR_ASSESSMENT
      TODO
      organizationId
    }
  }
}
    `;

/**
 * __useFindKaizenSubmissionFlowQuery__
 *
 * To run a query within a React component, call `useFindKaizenSubmissionFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindKaizenSubmissionFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindKaizenSubmissionFlowQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindKaizenSubmissionFlowQuery(baseOptions: Apollo.QueryHookOptions<FindKaizenSubmissionFlowQuery, FindKaizenSubmissionFlowQueryVariables> & ({ variables: FindKaizenSubmissionFlowQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindKaizenSubmissionFlowQuery, FindKaizenSubmissionFlowQueryVariables>(FindKaizenSubmissionFlowDocument, options);
      }
export function useFindKaizenSubmissionFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindKaizenSubmissionFlowQuery, FindKaizenSubmissionFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindKaizenSubmissionFlowQuery, FindKaizenSubmissionFlowQueryVariables>(FindKaizenSubmissionFlowDocument, options);
        }
export function useFindKaizenSubmissionFlowSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindKaizenSubmissionFlowQuery, FindKaizenSubmissionFlowQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindKaizenSubmissionFlowQuery, FindKaizenSubmissionFlowQueryVariables>(FindKaizenSubmissionFlowDocument, options);
        }
export type FindKaizenSubmissionFlowQueryHookResult = ReturnType<typeof useFindKaizenSubmissionFlowQuery>;
export type FindKaizenSubmissionFlowLazyQueryHookResult = ReturnType<typeof useFindKaizenSubmissionFlowLazyQuery>;
export type FindKaizenSubmissionFlowSuspenseQueryHookResult = ReturnType<typeof useFindKaizenSubmissionFlowSuspenseQuery>;
export type FindKaizenSubmissionFlowQueryResult = Apollo.QueryResult<FindKaizenSubmissionFlowQuery, FindKaizenSubmissionFlowQueryVariables>;
export const FindSingleSubmissionInfoDocument = gql`
    query FindSingleSubmissionInfo($input: FindKaizenSubmissionInput!) {
  findSingleSubmission(findKaizenSubmissionInput: $input) {
    ...KaizenSubmission
  }
}
    ${KaizenSubmissionFragmentDoc}`;

/**
 * __useFindSingleSubmissionInfoQuery__
 *
 * To run a query within a React component, call `useFindSingleSubmissionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSingleSubmissionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSingleSubmissionInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindSingleSubmissionInfoQuery(baseOptions: Apollo.QueryHookOptions<FindSingleSubmissionInfoQuery, FindSingleSubmissionInfoQueryVariables> & ({ variables: FindSingleSubmissionInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSingleSubmissionInfoQuery, FindSingleSubmissionInfoQueryVariables>(FindSingleSubmissionInfoDocument, options);
      }
export function useFindSingleSubmissionInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSingleSubmissionInfoQuery, FindSingleSubmissionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSingleSubmissionInfoQuery, FindSingleSubmissionInfoQueryVariables>(FindSingleSubmissionInfoDocument, options);
        }
export function useFindSingleSubmissionInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindSingleSubmissionInfoQuery, FindSingleSubmissionInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindSingleSubmissionInfoQuery, FindSingleSubmissionInfoQueryVariables>(FindSingleSubmissionInfoDocument, options);
        }
export type FindSingleSubmissionInfoQueryHookResult = ReturnType<typeof useFindSingleSubmissionInfoQuery>;
export type FindSingleSubmissionInfoLazyQueryHookResult = ReturnType<typeof useFindSingleSubmissionInfoLazyQuery>;
export type FindSingleSubmissionInfoSuspenseQueryHookResult = ReturnType<typeof useFindSingleSubmissionInfoSuspenseQuery>;
export type FindSingleSubmissionInfoQueryResult = Apollo.QueryResult<FindSingleSubmissionInfoQuery, FindSingleSubmissionInfoQueryVariables>;
export const GetCommissionMembersDocument = gql`
    query GetCommissionMembers($input: GetCommissionMemberInput!) {
  getCommissionMembers(getCommissionMembersInput: $input) {
    items {
      ...CommissionMemberFragment
    }
    total
  }
}
    ${CommissionMemberFragmentFragmentDoc}`;

/**
 * __useGetCommissionMembersQuery__
 *
 * To run a query within a React component, call `useGetCommissionMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommissionMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommissionMembersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommissionMembersQuery(baseOptions: Apollo.QueryHookOptions<GetCommissionMembersQuery, GetCommissionMembersQueryVariables> & ({ variables: GetCommissionMembersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommissionMembersQuery, GetCommissionMembersQueryVariables>(GetCommissionMembersDocument, options);
      }
export function useGetCommissionMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommissionMembersQuery, GetCommissionMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommissionMembersQuery, GetCommissionMembersQueryVariables>(GetCommissionMembersDocument, options);
        }
export function useGetCommissionMembersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCommissionMembersQuery, GetCommissionMembersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCommissionMembersQuery, GetCommissionMembersQueryVariables>(GetCommissionMembersDocument, options);
        }
export type GetCommissionMembersQueryHookResult = ReturnType<typeof useGetCommissionMembersQuery>;
export type GetCommissionMembersLazyQueryHookResult = ReturnType<typeof useGetCommissionMembersLazyQuery>;
export type GetCommissionMembersSuspenseQueryHookResult = ReturnType<typeof useGetCommissionMembersSuspenseQuery>;
export type GetCommissionMembersQueryResult = Apollo.QueryResult<GetCommissionMembersQuery, GetCommissionMembersQueryVariables>;
export const GetKaizenRanksDocument = gql`
    query GetKaizenRanks($input: GetKaizenRanksInput!) {
  getKaizenRanks(getKaizenRanksInput: $input) {
    items {
      employee {
        profilePicture
        firstName
        lastName
        id
      }
      submissionNumber
      totalPoints
    }
    total
  }
}
    `;

/**
 * __useGetKaizenRanksQuery__
 *
 * To run a query within a React component, call `useGetKaizenRanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKaizenRanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKaizenRanksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetKaizenRanksQuery(baseOptions: Apollo.QueryHookOptions<GetKaizenRanksQuery, GetKaizenRanksQueryVariables> & ({ variables: GetKaizenRanksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKaizenRanksQuery, GetKaizenRanksQueryVariables>(GetKaizenRanksDocument, options);
      }
export function useGetKaizenRanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKaizenRanksQuery, GetKaizenRanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKaizenRanksQuery, GetKaizenRanksQueryVariables>(GetKaizenRanksDocument, options);
        }
export function useGetKaizenRanksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetKaizenRanksQuery, GetKaizenRanksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetKaizenRanksQuery, GetKaizenRanksQueryVariables>(GetKaizenRanksDocument, options);
        }
export type GetKaizenRanksQueryHookResult = ReturnType<typeof useGetKaizenRanksQuery>;
export type GetKaizenRanksLazyQueryHookResult = ReturnType<typeof useGetKaizenRanksLazyQuery>;
export type GetKaizenRanksSuspenseQueryHookResult = ReturnType<typeof useGetKaizenRanksSuspenseQuery>;
export type GetKaizenRanksQueryResult = Apollo.QueryResult<GetKaizenRanksQuery, GetKaizenRanksQueryVariables>;
export const GetSettlementPeriodSubmissionNumberDocument = gql`
    query getSettlementPeriodSubmissionNumber($input: GetSettlementPeriodSubmissionNumberInput!) {
  getSettlementPeriodSubmissionNumber(
    getSettlementPeriodSubmissionNumberInput: $input
  ) {
    total
  }
}
    `;

/**
 * __useGetSettlementPeriodSubmissionNumberQuery__
 *
 * To run a query within a React component, call `useGetSettlementPeriodSubmissionNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettlementPeriodSubmissionNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettlementPeriodSubmissionNumberQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSettlementPeriodSubmissionNumberQuery(baseOptions: Apollo.QueryHookOptions<GetSettlementPeriodSubmissionNumberQuery, GetSettlementPeriodSubmissionNumberQueryVariables> & ({ variables: GetSettlementPeriodSubmissionNumberQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettlementPeriodSubmissionNumberQuery, GetSettlementPeriodSubmissionNumberQueryVariables>(GetSettlementPeriodSubmissionNumberDocument, options);
      }
export function useGetSettlementPeriodSubmissionNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettlementPeriodSubmissionNumberQuery, GetSettlementPeriodSubmissionNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettlementPeriodSubmissionNumberQuery, GetSettlementPeriodSubmissionNumberQueryVariables>(GetSettlementPeriodSubmissionNumberDocument, options);
        }
export function useGetSettlementPeriodSubmissionNumberSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSettlementPeriodSubmissionNumberQuery, GetSettlementPeriodSubmissionNumberQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSettlementPeriodSubmissionNumberQuery, GetSettlementPeriodSubmissionNumberQueryVariables>(GetSettlementPeriodSubmissionNumberDocument, options);
        }
export type GetSettlementPeriodSubmissionNumberQueryHookResult = ReturnType<typeof useGetSettlementPeriodSubmissionNumberQuery>;
export type GetSettlementPeriodSubmissionNumberLazyQueryHookResult = ReturnType<typeof useGetSettlementPeriodSubmissionNumberLazyQuery>;
export type GetSettlementPeriodSubmissionNumberSuspenseQueryHookResult = ReturnType<typeof useGetSettlementPeriodSubmissionNumberSuspenseQuery>;
export type GetSettlementPeriodSubmissionNumberQueryResult = Apollo.QueryResult<GetSettlementPeriodSubmissionNumberQuery, GetSettlementPeriodSubmissionNumberQueryVariables>;
export const GetAllSettlementPeriodsDocument = gql`
    query getAllSettlementPeriods($input: FindAllSettlementPeriodsInput!) {
  findAllSettlementPeriods(findSettlementPeriodsInput: $input) {
    settlementPeriods {
      ...KaizenSettlementPeriod
    }
    organizationId
  }
}
    ${KaizenSettlementPeriodFragmentDoc}`;

/**
 * __useGetAllSettlementPeriodsQuery__
 *
 * To run a query within a React component, call `useGetAllSettlementPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSettlementPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSettlementPeriodsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllSettlementPeriodsQuery(baseOptions: Apollo.QueryHookOptions<GetAllSettlementPeriodsQuery, GetAllSettlementPeriodsQueryVariables> & ({ variables: GetAllSettlementPeriodsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSettlementPeriodsQuery, GetAllSettlementPeriodsQueryVariables>(GetAllSettlementPeriodsDocument, options);
      }
export function useGetAllSettlementPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSettlementPeriodsQuery, GetAllSettlementPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSettlementPeriodsQuery, GetAllSettlementPeriodsQueryVariables>(GetAllSettlementPeriodsDocument, options);
        }
export function useGetAllSettlementPeriodsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllSettlementPeriodsQuery, GetAllSettlementPeriodsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllSettlementPeriodsQuery, GetAllSettlementPeriodsQueryVariables>(GetAllSettlementPeriodsDocument, options);
        }
export type GetAllSettlementPeriodsQueryHookResult = ReturnType<typeof useGetAllSettlementPeriodsQuery>;
export type GetAllSettlementPeriodsLazyQueryHookResult = ReturnType<typeof useGetAllSettlementPeriodsLazyQuery>;
export type GetAllSettlementPeriodsSuspenseQueryHookResult = ReturnType<typeof useGetAllSettlementPeriodsSuspenseQuery>;
export type GetAllSettlementPeriodsQueryResult = Apollo.QueryResult<GetAllSettlementPeriodsQuery, GetAllSettlementPeriodsQueryVariables>;
export const CreateKpiChartDocument = gql`
    mutation CreateKpiChart($input: CreateKpiChartInput!) {
  createKpiChart(createKpiChartInput: $input) {
    ...Dashboard
  }
}
    ${DashboardFragmentDoc}`;
export type CreateKpiChartMutationFn = Apollo.MutationFunction<CreateKpiChartMutation, CreateKpiChartMutationVariables>;

/**
 * __useCreateKpiChartMutation__
 *
 * To run a mutation, you first call `useCreateKpiChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKpiChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKpiChartMutation, { data, loading, error }] = useCreateKpiChartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKpiChartMutation(baseOptions?: Apollo.MutationHookOptions<CreateKpiChartMutation, CreateKpiChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKpiChartMutation, CreateKpiChartMutationVariables>(CreateKpiChartDocument, options);
      }
export type CreateKpiChartMutationHookResult = ReturnType<typeof useCreateKpiChartMutation>;
export type CreateKpiChartMutationResult = Apollo.MutationResult<CreateKpiChartMutation>;
export type CreateKpiChartMutationOptions = Apollo.BaseMutationOptions<CreateKpiChartMutation, CreateKpiChartMutationVariables>;
export const CreateKpiRateDocument = gql`
    mutation createKpiRate($input: CreateKpiRateInput!) {
  createKpiRate(createKpiRateInput: $input) {
    ...KpiRate
  }
}
    ${KpiRateFragmentDoc}`;
export type CreateKpiRateMutationFn = Apollo.MutationFunction<CreateKpiRateMutation, CreateKpiRateMutationVariables>;

/**
 * __useCreateKpiRateMutation__
 *
 * To run a mutation, you first call `useCreateKpiRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKpiRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKpiRateMutation, { data, loading, error }] = useCreateKpiRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKpiRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateKpiRateMutation, CreateKpiRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKpiRateMutation, CreateKpiRateMutationVariables>(CreateKpiRateDocument, options);
      }
export type CreateKpiRateMutationHookResult = ReturnType<typeof useCreateKpiRateMutation>;
export type CreateKpiRateMutationResult = Apollo.MutationResult<CreateKpiRateMutation>;
export type CreateKpiRateMutationOptions = Apollo.BaseMutationOptions<CreateKpiRateMutation, CreateKpiRateMutationVariables>;
export const CreateKpiSettlementPeriodDocument = gql`
    mutation CreateKpiSettlementPeriod($input: CreateKpiSettlementPeriodInput!) {
  createKpiSettlementPeriod(createKpiSettlementPeriodInput: $input) {
    ...KpiSettlementPeriod
  }
}
    ${KpiSettlementPeriodFragmentDoc}`;
export type CreateKpiSettlementPeriodMutationFn = Apollo.MutationFunction<CreateKpiSettlementPeriodMutation, CreateKpiSettlementPeriodMutationVariables>;

/**
 * __useCreateKpiSettlementPeriodMutation__
 *
 * To run a mutation, you first call `useCreateKpiSettlementPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateKpiSettlementPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createKpiSettlementPeriodMutation, { data, loading, error }] = useCreateKpiSettlementPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateKpiSettlementPeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateKpiSettlementPeriodMutation, CreateKpiSettlementPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKpiSettlementPeriodMutation, CreateKpiSettlementPeriodMutationVariables>(CreateKpiSettlementPeriodDocument, options);
      }
export type CreateKpiSettlementPeriodMutationHookResult = ReturnType<typeof useCreateKpiSettlementPeriodMutation>;
export type CreateKpiSettlementPeriodMutationResult = Apollo.MutationResult<CreateKpiSettlementPeriodMutation>;
export type CreateKpiSettlementPeriodMutationOptions = Apollo.BaseMutationOptions<CreateKpiSettlementPeriodMutation, CreateKpiSettlementPeriodMutationVariables>;
export const RemoveKpiRateDocument = gql`
    mutation RemoveKpiRate($input: RemoveKpiInput!) {
  removeKpiRate(removeKpiInput: $input) {
    success
  }
}
    `;
export type RemoveKpiRateMutationFn = Apollo.MutationFunction<RemoveKpiRateMutation, RemoveKpiRateMutationVariables>;

/**
 * __useRemoveKpiRateMutation__
 *
 * To run a mutation, you first call `useRemoveKpiRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKpiRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKpiRateMutation, { data, loading, error }] = useRemoveKpiRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveKpiRateMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKpiRateMutation, RemoveKpiRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveKpiRateMutation, RemoveKpiRateMutationVariables>(RemoveKpiRateDocument, options);
      }
export type RemoveKpiRateMutationHookResult = ReturnType<typeof useRemoveKpiRateMutation>;
export type RemoveKpiRateMutationResult = Apollo.MutationResult<RemoveKpiRateMutation>;
export type RemoveKpiRateMutationOptions = Apollo.BaseMutationOptions<RemoveKpiRateMutation, RemoveKpiRateMutationVariables>;
export const ReportKpiRateEntryDocument = gql`
    mutation ReportKpiRateEntry($input: ReportKpiRateEntryInput!) {
  reportKpiRateEntry(reportKpiRateEntryInput: $input) {
    success
  }
}
    `;
export type ReportKpiRateEntryMutationFn = Apollo.MutationFunction<ReportKpiRateEntryMutation, ReportKpiRateEntryMutationVariables>;

/**
 * __useReportKpiRateEntryMutation__
 *
 * To run a mutation, you first call `useReportKpiRateEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportKpiRateEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportKpiRateEntryMutation, { data, loading, error }] = useReportKpiRateEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportKpiRateEntryMutation(baseOptions?: Apollo.MutationHookOptions<ReportKpiRateEntryMutation, ReportKpiRateEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportKpiRateEntryMutation, ReportKpiRateEntryMutationVariables>(ReportKpiRateEntryDocument, options);
      }
export type ReportKpiRateEntryMutationHookResult = ReturnType<typeof useReportKpiRateEntryMutation>;
export type ReportKpiRateEntryMutationResult = Apollo.MutationResult<ReportKpiRateEntryMutation>;
export type ReportKpiRateEntryMutationOptions = Apollo.BaseMutationOptions<ReportKpiRateEntryMutation, ReportKpiRateEntryMutationVariables>;
export const UpdateKpiRateDocument = gql`
    mutation updateKpiRate($input: UpdateKpiRateInput!) {
  updateKpiRate(updateKpiRateInput: $input) {
    ...KpiRate
  }
}
    ${KpiRateFragmentDoc}`;
export type UpdateKpiRateMutationFn = Apollo.MutationFunction<UpdateKpiRateMutation, UpdateKpiRateMutationVariables>;

/**
 * __useUpdateKpiRateMutation__
 *
 * To run a mutation, you first call `useUpdateKpiRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKpiRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKpiRateMutation, { data, loading, error }] = useUpdateKpiRateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKpiRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKpiRateMutation, UpdateKpiRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKpiRateMutation, UpdateKpiRateMutationVariables>(UpdateKpiRateDocument, options);
      }
export type UpdateKpiRateMutationHookResult = ReturnType<typeof useUpdateKpiRateMutation>;
export type UpdateKpiRateMutationResult = Apollo.MutationResult<UpdateKpiRateMutation>;
export type UpdateKpiRateMutationOptions = Apollo.BaseMutationOptions<UpdateKpiRateMutation, UpdateKpiRateMutationVariables>;
export const UpdateKpiSettlementPeriodDocument = gql`
    mutation UpdateKpiSettlementPeriod($input: UpdateKpiSettlementPeriodInput!) {
  updateKpiSettlementPeriod(updateKpiSettlementPeriodInput: $input) {
    ...KpiSettlementPeriod
  }
}
    ${KpiSettlementPeriodFragmentDoc}`;
export type UpdateKpiSettlementPeriodMutationFn = Apollo.MutationFunction<UpdateKpiSettlementPeriodMutation, UpdateKpiSettlementPeriodMutationVariables>;

/**
 * __useUpdateKpiSettlementPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateKpiSettlementPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKpiSettlementPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKpiSettlementPeriodMutation, { data, loading, error }] = useUpdateKpiSettlementPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateKpiSettlementPeriodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKpiSettlementPeriodMutation, UpdateKpiSettlementPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKpiSettlementPeriodMutation, UpdateKpiSettlementPeriodMutationVariables>(UpdateKpiSettlementPeriodDocument, options);
      }
export type UpdateKpiSettlementPeriodMutationHookResult = ReturnType<typeof useUpdateKpiSettlementPeriodMutation>;
export type UpdateKpiSettlementPeriodMutationResult = Apollo.MutationResult<UpdateKpiSettlementPeriodMutation>;
export type UpdateKpiSettlementPeriodMutationOptions = Apollo.BaseMutationOptions<UpdateKpiSettlementPeriodMutation, UpdateKpiSettlementPeriodMutationVariables>;
export const FindAllKpiSettlementPeriodsDocument = gql`
    query FindAllKpiSettlementPeriods($input: FindAllKpiSettlementPeriodsInput!) {
  findAllKpiSettlementPeriods(findAllKpiSettlementPeriodsInput: $input) {
    settlementPeriods {
      ...KpiSettlementPeriod
    }
  }
}
    ${KpiSettlementPeriodFragmentDoc}`;

/**
 * __useFindAllKpiSettlementPeriodsQuery__
 *
 * To run a query within a React component, call `useFindAllKpiSettlementPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllKpiSettlementPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllKpiSettlementPeriodsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllKpiSettlementPeriodsQuery(baseOptions: Apollo.QueryHookOptions<FindAllKpiSettlementPeriodsQuery, FindAllKpiSettlementPeriodsQueryVariables> & ({ variables: FindAllKpiSettlementPeriodsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllKpiSettlementPeriodsQuery, FindAllKpiSettlementPeriodsQueryVariables>(FindAllKpiSettlementPeriodsDocument, options);
      }
export function useFindAllKpiSettlementPeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllKpiSettlementPeriodsQuery, FindAllKpiSettlementPeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllKpiSettlementPeriodsQuery, FindAllKpiSettlementPeriodsQueryVariables>(FindAllKpiSettlementPeriodsDocument, options);
        }
export function useFindAllKpiSettlementPeriodsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAllKpiSettlementPeriodsQuery, FindAllKpiSettlementPeriodsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAllKpiSettlementPeriodsQuery, FindAllKpiSettlementPeriodsQueryVariables>(FindAllKpiSettlementPeriodsDocument, options);
        }
export type FindAllKpiSettlementPeriodsQueryHookResult = ReturnType<typeof useFindAllKpiSettlementPeriodsQuery>;
export type FindAllKpiSettlementPeriodsLazyQueryHookResult = ReturnType<typeof useFindAllKpiSettlementPeriodsLazyQuery>;
export type FindAllKpiSettlementPeriodsSuspenseQueryHookResult = ReturnType<typeof useFindAllKpiSettlementPeriodsSuspenseQuery>;
export type FindAllKpiSettlementPeriodsQueryResult = Apollo.QueryResult<FindAllKpiSettlementPeriodsQuery, FindAllKpiSettlementPeriodsQueryVariables>;
export const FindAllKpisDocument = gql`
    query FindAllKpis($input: FindKpiRateInput!) {
  findAllKpis(findAllKpisInput: $input) {
    items {
      ...KpiRate
    }
    total
  }
}
    ${KpiRateFragmentDoc}`;

/**
 * __useFindAllKpisQuery__
 *
 * To run a query within a React component, call `useFindAllKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllKpisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllKpisQuery(baseOptions: Apollo.QueryHookOptions<FindAllKpisQuery, FindAllKpisQueryVariables> & ({ variables: FindAllKpisQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllKpisQuery, FindAllKpisQueryVariables>(FindAllKpisDocument, options);
      }
export function useFindAllKpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllKpisQuery, FindAllKpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllKpisQuery, FindAllKpisQueryVariables>(FindAllKpisDocument, options);
        }
export function useFindAllKpisSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAllKpisQuery, FindAllKpisQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAllKpisQuery, FindAllKpisQueryVariables>(FindAllKpisDocument, options);
        }
export type FindAllKpisQueryHookResult = ReturnType<typeof useFindAllKpisQuery>;
export type FindAllKpisLazyQueryHookResult = ReturnType<typeof useFindAllKpisLazyQuery>;
export type FindAllKpisSuspenseQueryHookResult = ReturnType<typeof useFindAllKpisSuspenseQuery>;
export type FindAllKpisQueryResult = Apollo.QueryResult<FindAllKpisQuery, FindAllKpisQueryVariables>;
export const FindKpiUnitsDocument = gql`
    query findKpiUnits($input: FindAllKpiUnitsInput!) {
  findKpiUnits(findAllKpisUnitsInput: $input) {
    units {
      ...KpiUnitModel
    }
  }
}
    ${KpiUnitModelFragmentDoc}`;

/**
 * __useFindKpiUnitsQuery__
 *
 * To run a query within a React component, call `useFindKpiUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindKpiUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindKpiUnitsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindKpiUnitsQuery(baseOptions: Apollo.QueryHookOptions<FindKpiUnitsQuery, FindKpiUnitsQueryVariables> & ({ variables: FindKpiUnitsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindKpiUnitsQuery, FindKpiUnitsQueryVariables>(FindKpiUnitsDocument, options);
      }
export function useFindKpiUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindKpiUnitsQuery, FindKpiUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindKpiUnitsQuery, FindKpiUnitsQueryVariables>(FindKpiUnitsDocument, options);
        }
export function useFindKpiUnitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindKpiUnitsQuery, FindKpiUnitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindKpiUnitsQuery, FindKpiUnitsQueryVariables>(FindKpiUnitsDocument, options);
        }
export type FindKpiUnitsQueryHookResult = ReturnType<typeof useFindKpiUnitsQuery>;
export type FindKpiUnitsLazyQueryHookResult = ReturnType<typeof useFindKpiUnitsLazyQuery>;
export type FindKpiUnitsSuspenseQueryHookResult = ReturnType<typeof useFindKpiUnitsSuspenseQuery>;
export type FindKpiUnitsQueryResult = Apollo.QueryResult<FindKpiUnitsQuery, FindKpiUnitsQueryVariables>;
export const FindManyKpisDocument = gql`
    query FindManyKpis($input: FindManyRateInput!) {
  findManyKpis(findManyKpisInput: $input) {
    items {
      ...KpiRate
    }
  }
}
    ${KpiRateFragmentDoc}`;

/**
 * __useFindManyKpisQuery__
 *
 * To run a query within a React component, call `useFindManyKpisQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyKpisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyKpisQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindManyKpisQuery(baseOptions: Apollo.QueryHookOptions<FindManyKpisQuery, FindManyKpisQueryVariables> & ({ variables: FindManyKpisQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManyKpisQuery, FindManyKpisQueryVariables>(FindManyKpisDocument, options);
      }
export function useFindManyKpisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyKpisQuery, FindManyKpisQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManyKpisQuery, FindManyKpisQueryVariables>(FindManyKpisDocument, options);
        }
export function useFindManyKpisSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindManyKpisQuery, FindManyKpisQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindManyKpisQuery, FindManyKpisQueryVariables>(FindManyKpisDocument, options);
        }
export type FindManyKpisQueryHookResult = ReturnType<typeof useFindManyKpisQuery>;
export type FindManyKpisLazyQueryHookResult = ReturnType<typeof useFindManyKpisLazyQuery>;
export type FindManyKpisSuspenseQueryHookResult = ReturnType<typeof useFindManyKpisSuspenseQuery>;
export type FindManyKpisQueryResult = Apollo.QueryResult<FindManyKpisQuery, FindManyKpisQueryVariables>;
export const FindOrganizationSubunitsDocument = gql`
    query findOrganizationSubunits($input: FindOrganizationUnitSubunitsInput!) {
  findOrganizationSubunits(findOrganizationUnitSubunitsInput: $input) {
    ...FindAllSubUnitsStructureDtoFragment
  }
}
    ${FindAllSubUnitsStructureDtoFragmentFragmentDoc}`;

/**
 * __useFindOrganizationSubunitsQuery__
 *
 * To run a query within a React component, call `useFindOrganizationSubunitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrganizationSubunitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrganizationSubunitsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindOrganizationSubunitsQuery(baseOptions: Apollo.QueryHookOptions<FindOrganizationSubunitsQuery, FindOrganizationSubunitsQueryVariables> & ({ variables: FindOrganizationSubunitsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOrganizationSubunitsQuery, FindOrganizationSubunitsQueryVariables>(FindOrganizationSubunitsDocument, options);
      }
export function useFindOrganizationSubunitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOrganizationSubunitsQuery, FindOrganizationSubunitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOrganizationSubunitsQuery, FindOrganizationSubunitsQueryVariables>(FindOrganizationSubunitsDocument, options);
        }
export function useFindOrganizationSubunitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindOrganizationSubunitsQuery, FindOrganizationSubunitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindOrganizationSubunitsQuery, FindOrganizationSubunitsQueryVariables>(FindOrganizationSubunitsDocument, options);
        }
export type FindOrganizationSubunitsQueryHookResult = ReturnType<typeof useFindOrganizationSubunitsQuery>;
export type FindOrganizationSubunitsLazyQueryHookResult = ReturnType<typeof useFindOrganizationSubunitsLazyQuery>;
export type FindOrganizationSubunitsSuspenseQueryHookResult = ReturnType<typeof useFindOrganizationSubunitsSuspenseQuery>;
export type FindOrganizationSubunitsQueryResult = Apollo.QueryResult<FindOrganizationSubunitsQuery, FindOrganizationSubunitsQueryVariables>;
export const GetKpiRateDocument = gql`
    query getKpiRate($input: GetKpiRateInput!) {
  getKpiRate(getKpiRateInput: $input) {
    ...KpiRate
  }
}
    ${KpiRateFragmentDoc}`;

/**
 * __useGetKpiRateQuery__
 *
 * To run a query within a React component, call `useGetKpiRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKpiRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKpiRateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetKpiRateQuery(baseOptions: Apollo.QueryHookOptions<GetKpiRateQuery, GetKpiRateQueryVariables> & ({ variables: GetKpiRateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKpiRateQuery, GetKpiRateQueryVariables>(GetKpiRateDocument, options);
      }
export function useGetKpiRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKpiRateQuery, GetKpiRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKpiRateQuery, GetKpiRateQueryVariables>(GetKpiRateDocument, options);
        }
export function useGetKpiRateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetKpiRateQuery, GetKpiRateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetKpiRateQuery, GetKpiRateQueryVariables>(GetKpiRateDocument, options);
        }
export type GetKpiRateQueryHookResult = ReturnType<typeof useGetKpiRateQuery>;
export type GetKpiRateLazyQueryHookResult = ReturnType<typeof useGetKpiRateLazyQuery>;
export type GetKpiRateSuspenseQueryHookResult = ReturnType<typeof useGetKpiRateSuspenseQuery>;
export type GetKpiRateQueryResult = Apollo.QueryResult<GetKpiRateQuery, GetKpiRateQueryVariables>;
export const GetRelatedKpiChartsDocument = gql`
    query GetRelatedKpiCharts($input: FindRelatedResourcesInput!) {
  getRelatedKpiCharts(findRelatedResourcesInput: $input) {
    totalRelatedResources
    relatedResources {
      resourceId
      resourceName
    }
  }
}
    `;

/**
 * __useGetRelatedKpiChartsQuery__
 *
 * To run a query within a React component, call `useGetRelatedKpiChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedKpiChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedKpiChartsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRelatedKpiChartsQuery(baseOptions: Apollo.QueryHookOptions<GetRelatedKpiChartsQuery, GetRelatedKpiChartsQueryVariables> & ({ variables: GetRelatedKpiChartsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRelatedKpiChartsQuery, GetRelatedKpiChartsQueryVariables>(GetRelatedKpiChartsDocument, options);
      }
export function useGetRelatedKpiChartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRelatedKpiChartsQuery, GetRelatedKpiChartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRelatedKpiChartsQuery, GetRelatedKpiChartsQueryVariables>(GetRelatedKpiChartsDocument, options);
        }
export function useGetRelatedKpiChartsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRelatedKpiChartsQuery, GetRelatedKpiChartsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRelatedKpiChartsQuery, GetRelatedKpiChartsQueryVariables>(GetRelatedKpiChartsDocument, options);
        }
export type GetRelatedKpiChartsQueryHookResult = ReturnType<typeof useGetRelatedKpiChartsQuery>;
export type GetRelatedKpiChartsLazyQueryHookResult = ReturnType<typeof useGetRelatedKpiChartsLazyQuery>;
export type GetRelatedKpiChartsSuspenseQueryHookResult = ReturnType<typeof useGetRelatedKpiChartsSuspenseQuery>;
export type GetRelatedKpiChartsQueryResult = Apollo.QueryResult<GetRelatedKpiChartsQuery, GetRelatedKpiChartsQueryVariables>;
export const AddKeyUserToOrganizationDocument = gql`
    mutation AddKeyUserToOrganization($input: OrganizationKeyUserInput!) {
  addKeyUserToOrganization(addOrganizationKeyUserInput: $input) {
    success
  }
}
    `;
export type AddKeyUserToOrganizationMutationFn = Apollo.MutationFunction<AddKeyUserToOrganizationMutation, AddKeyUserToOrganizationMutationVariables>;

/**
 * __useAddKeyUserToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddKeyUserToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKeyUserToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKeyUserToOrganizationMutation, { data, loading, error }] = useAddKeyUserToOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddKeyUserToOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<AddKeyUserToOrganizationMutation, AddKeyUserToOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddKeyUserToOrganizationMutation, AddKeyUserToOrganizationMutationVariables>(AddKeyUserToOrganizationDocument, options);
      }
export type AddKeyUserToOrganizationMutationHookResult = ReturnType<typeof useAddKeyUserToOrganizationMutation>;
export type AddKeyUserToOrganizationMutationResult = Apollo.MutationResult<AddKeyUserToOrganizationMutation>;
export type AddKeyUserToOrganizationMutationOptions = Apollo.BaseMutationOptions<AddKeyUserToOrganizationMutation, AddKeyUserToOrganizationMutationVariables>;
export const CreateOrganisationDocument = gql`
    mutation CreateOrganisation($input: CreateOrganisationInput!, $file: Upload) {
  createOrganization(createOrganisationInput: $input, resourceImage: $file) {
    id
  }
}
    `;
export type CreateOrganisationMutationFn = Apollo.MutationFunction<CreateOrganisationMutation, CreateOrganisationMutationVariables>;

/**
 * __useCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationMutation, { data, loading, error }] = useCreateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useCreateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganisationMutation, CreateOrganisationMutationVariables>(CreateOrganisationDocument, options);
      }
export type CreateOrganisationMutationHookResult = ReturnType<typeof useCreateOrganisationMutation>;
export type CreateOrganisationMutationResult = Apollo.MutationResult<CreateOrganisationMutation>;
export type CreateOrganisationMutationOptions = Apollo.BaseMutationOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>;
export const DeleteSingleOrganizationDocument = gql`
    mutation DeleteSingleOrganization($input: RemoveOrganizationInput!) {
  removeOrganisation(removeOrganisationInput: $input) {
    success
  }
}
    `;
export type DeleteSingleOrganizationMutationFn = Apollo.MutationFunction<DeleteSingleOrganizationMutation, DeleteSingleOrganizationMutationVariables>;

/**
 * __useDeleteSingleOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteSingleOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSingleOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSingleOrganizationMutation, { data, loading, error }] = useDeleteSingleOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSingleOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSingleOrganizationMutation, DeleteSingleOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSingleOrganizationMutation, DeleteSingleOrganizationMutationVariables>(DeleteSingleOrganizationDocument, options);
      }
export type DeleteSingleOrganizationMutationHookResult = ReturnType<typeof useDeleteSingleOrganizationMutation>;
export type DeleteSingleOrganizationMutationResult = Apollo.MutationResult<DeleteSingleOrganizationMutation>;
export type DeleteSingleOrganizationMutationOptions = Apollo.BaseMutationOptions<DeleteSingleOrganizationMutation, DeleteSingleOrganizationMutationVariables>;
export const RemoveKeyUserFromOrganizationDocument = gql`
    mutation RemoveKeyUserFromOrganization($input: OrganizationKeyUserInput!) {
  removeKeyUserFromOrganization(removeOrganizationKeyUserInput: $input) {
    success
  }
}
    `;
export type RemoveKeyUserFromOrganizationMutationFn = Apollo.MutationFunction<RemoveKeyUserFromOrganizationMutation, RemoveKeyUserFromOrganizationMutationVariables>;

/**
 * __useRemoveKeyUserFromOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveKeyUserFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveKeyUserFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeKeyUserFromOrganizationMutation, { data, loading, error }] = useRemoveKeyUserFromOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveKeyUserFromOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveKeyUserFromOrganizationMutation, RemoveKeyUserFromOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveKeyUserFromOrganizationMutation, RemoveKeyUserFromOrganizationMutationVariables>(RemoveKeyUserFromOrganizationDocument, options);
      }
export type RemoveKeyUserFromOrganizationMutationHookResult = ReturnType<typeof useRemoveKeyUserFromOrganizationMutation>;
export type RemoveKeyUserFromOrganizationMutationResult = Apollo.MutationResult<RemoveKeyUserFromOrganizationMutation>;
export type RemoveKeyUserFromOrganizationMutationOptions = Apollo.BaseMutationOptions<RemoveKeyUserFromOrganizationMutation, RemoveKeyUserFromOrganizationMutationVariables>;
export const SetOrganizationPositionsDocument = gql`
    mutation SetOrganizationPositions($input: UpdateOrganizationInput!) {
  updateOrganisation(updateOrganisationInput: $input) {
    positions
  }
}
    `;
export type SetOrganizationPositionsMutationFn = Apollo.MutationFunction<SetOrganizationPositionsMutation, SetOrganizationPositionsMutationVariables>;

/**
 * __useSetOrganizationPositionsMutation__
 *
 * To run a mutation, you first call `useSetOrganizationPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOrganizationPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOrganizationPositionsMutation, { data, loading, error }] = useSetOrganizationPositionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOrganizationPositionsMutation(baseOptions?: Apollo.MutationHookOptions<SetOrganizationPositionsMutation, SetOrganizationPositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetOrganizationPositionsMutation, SetOrganizationPositionsMutationVariables>(SetOrganizationPositionsDocument, options);
      }
export type SetOrganizationPositionsMutationHookResult = ReturnType<typeof useSetOrganizationPositionsMutation>;
export type SetOrganizationPositionsMutationResult = Apollo.MutationResult<SetOrganizationPositionsMutation>;
export type SetOrganizationPositionsMutationOptions = Apollo.BaseMutationOptions<SetOrganizationPositionsMutation, SetOrganizationPositionsMutationVariables>;
export const UpdateOrganizationLogoDocument = gql`
    mutation UpdateOrganizationLogo($input: UpdateOrganizationInput!, $file: Upload) {
  updateOrganisation(updateOrganisationInput: $input, resourceImage: $file) {
    logo
  }
}
    `;
export type UpdateOrganizationLogoMutationFn = Apollo.MutationFunction<UpdateOrganizationLogoMutation, UpdateOrganizationLogoMutationVariables>;

/**
 * __useUpdateOrganizationLogoMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationLogoMutation, { data, loading, error }] = useUpdateOrganizationLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateOrganizationLogoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationLogoMutation, UpdateOrganizationLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationLogoMutation, UpdateOrganizationLogoMutationVariables>(UpdateOrganizationLogoDocument, options);
      }
export type UpdateOrganizationLogoMutationHookResult = ReturnType<typeof useUpdateOrganizationLogoMutation>;
export type UpdateOrganizationLogoMutationResult = Apollo.MutationResult<UpdateOrganizationLogoMutation>;
export type UpdateOrganizationLogoMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationLogoMutation, UpdateOrganizationLogoMutationVariables>;
export const UpdateOrganizationModulesDocument = gql`
    mutation UpdateOrganizationModules($input: UpdateOrganizationInput!) {
  updateOrganisation(updateOrganisationInput: $input) {
    modules
  }
}
    `;
export type UpdateOrganizationModulesMutationFn = Apollo.MutationFunction<UpdateOrganizationModulesMutation, UpdateOrganizationModulesMutationVariables>;

/**
 * __useUpdateOrganizationModulesMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationModulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationModulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationModulesMutation, { data, loading, error }] = useUpdateOrganizationModulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationModulesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationModulesMutation, UpdateOrganizationModulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationModulesMutation, UpdateOrganizationModulesMutationVariables>(UpdateOrganizationModulesDocument, options);
      }
export type UpdateOrganizationModulesMutationHookResult = ReturnType<typeof useUpdateOrganizationModulesMutation>;
export type UpdateOrganizationModulesMutationResult = Apollo.MutationResult<UpdateOrganizationModulesMutation>;
export type UpdateOrganizationModulesMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationModulesMutation, UpdateOrganizationModulesMutationVariables>;
export const UpdateOrganizationNameDocument = gql`
    mutation UpdateOrganizationName($input: UpdateOrganizationInput!) {
  updateOrganisation(updateOrganisationInput: $input) {
    name
    id
  }
}
    `;
export type UpdateOrganizationNameMutationFn = Apollo.MutationFunction<UpdateOrganizationNameMutation, UpdateOrganizationNameMutationVariables>;

/**
 * __useUpdateOrganizationNameMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationNameMutation, { data, loading, error }] = useUpdateOrganizationNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationNameMutation, UpdateOrganizationNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationNameMutation, UpdateOrganizationNameMutationVariables>(UpdateOrganizationNameDocument, options);
      }
export type UpdateOrganizationNameMutationHookResult = ReturnType<typeof useUpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationResult = Apollo.MutationResult<UpdateOrganizationNameMutation>;
export type UpdateOrganizationNameMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationNameMutation, UpdateOrganizationNameMutationVariables>;
export const UpdatePositionsDocument = gql`
    mutation UpdatePositions($input: UpdatePositionsInput!) {
  updatePositions(updatePositionsInput: $input) {
    id
    positions
  }
}
    `;
export type UpdatePositionsMutationFn = Apollo.MutationFunction<UpdatePositionsMutation, UpdatePositionsMutationVariables>;

/**
 * __useUpdatePositionsMutation__
 *
 * To run a mutation, you first call `useUpdatePositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePositionsMutation, { data, loading, error }] = useUpdatePositionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePositionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePositionsMutation, UpdatePositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePositionsMutation, UpdatePositionsMutationVariables>(UpdatePositionsDocument, options);
      }
export type UpdatePositionsMutationHookResult = ReturnType<typeof useUpdatePositionsMutation>;
export type UpdatePositionsMutationResult = Apollo.MutationResult<UpdatePositionsMutation>;
export type UpdatePositionsMutationOptions = Apollo.BaseMutationOptions<UpdatePositionsMutation, UpdatePositionsMutationVariables>;
export const FindAllOrganizationsDocument = gql`
    query FindAllOrganizations($paginationInput: FindAllOrganizationsInput!) {
  findAllOrganizations(paginationInput: $paginationInput) {
    items {
      id
      name
      createdAt
      url
      modules
      logo
    }
    total
  }
}
    `;

/**
 * __useFindAllOrganizationsQuery__
 *
 * To run a query within a React component, call `useFindAllOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllOrganizationsQuery({
 *   variables: {
 *      paginationInput: // value for 'paginationInput'
 *   },
 * });
 */
export function useFindAllOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<FindAllOrganizationsQuery, FindAllOrganizationsQueryVariables> & ({ variables: FindAllOrganizationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllOrganizationsQuery, FindAllOrganizationsQueryVariables>(FindAllOrganizationsDocument, options);
      }
export function useFindAllOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllOrganizationsQuery, FindAllOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllOrganizationsQuery, FindAllOrganizationsQueryVariables>(FindAllOrganizationsDocument, options);
        }
export function useFindAllOrganizationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindAllOrganizationsQuery, FindAllOrganizationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAllOrganizationsQuery, FindAllOrganizationsQueryVariables>(FindAllOrganizationsDocument, options);
        }
export type FindAllOrganizationsQueryHookResult = ReturnType<typeof useFindAllOrganizationsQuery>;
export type FindAllOrganizationsLazyQueryHookResult = ReturnType<typeof useFindAllOrganizationsLazyQuery>;
export type FindAllOrganizationsSuspenseQueryHookResult = ReturnType<typeof useFindAllOrganizationsSuspenseQuery>;
export type FindAllOrganizationsQueryResult = Apollo.QueryResult<FindAllOrganizationsQuery, FindAllOrganizationsQueryVariables>;
export const FindSingleOrganizationDocument = gql`
    query findSingleOrganization($input: FindOrganizationInput!) {
  findSingleOrganization(findSingleOrganization: $input) {
    name
    url
    id
    modules
    logo
  }
}
    `;

/**
 * __useFindSingleOrganizationQuery__
 *
 * To run a query within a React component, call `useFindSingleOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSingleOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSingleOrganizationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindSingleOrganizationQuery(baseOptions: Apollo.QueryHookOptions<FindSingleOrganizationQuery, FindSingleOrganizationQueryVariables> & ({ variables: FindSingleOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSingleOrganizationQuery, FindSingleOrganizationQueryVariables>(FindSingleOrganizationDocument, options);
      }
export function useFindSingleOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSingleOrganizationQuery, FindSingleOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSingleOrganizationQuery, FindSingleOrganizationQueryVariables>(FindSingleOrganizationDocument, options);
        }
export function useFindSingleOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindSingleOrganizationQuery, FindSingleOrganizationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindSingleOrganizationQuery, FindSingleOrganizationQueryVariables>(FindSingleOrganizationDocument, options);
        }
export type FindSingleOrganizationQueryHookResult = ReturnType<typeof useFindSingleOrganizationQuery>;
export type FindSingleOrganizationLazyQueryHookResult = ReturnType<typeof useFindSingleOrganizationLazyQuery>;
export type FindSingleOrganizationSuspenseQueryHookResult = ReturnType<typeof useFindSingleOrganizationSuspenseQuery>;
export type FindSingleOrganizationQueryResult = Apollo.QueryResult<FindSingleOrganizationQuery, FindSingleOrganizationQueryVariables>;
export const FindSingleOrganizationPositionsDocument = gql`
    query findSingleOrganizationPositions($input: FindOrganizationInput!) {
  findSingleOrganization(findSingleOrganization: $input) {
    positions
  }
}
    `;

/**
 * __useFindSingleOrganizationPositionsQuery__
 *
 * To run a query within a React component, call `useFindSingleOrganizationPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSingleOrganizationPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSingleOrganizationPositionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindSingleOrganizationPositionsQuery(baseOptions: Apollo.QueryHookOptions<FindSingleOrganizationPositionsQuery, FindSingleOrganizationPositionsQueryVariables> & ({ variables: FindSingleOrganizationPositionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSingleOrganizationPositionsQuery, FindSingleOrganizationPositionsQueryVariables>(FindSingleOrganizationPositionsDocument, options);
      }
export function useFindSingleOrganizationPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSingleOrganizationPositionsQuery, FindSingleOrganizationPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSingleOrganizationPositionsQuery, FindSingleOrganizationPositionsQueryVariables>(FindSingleOrganizationPositionsDocument, options);
        }
export function useFindSingleOrganizationPositionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindSingleOrganizationPositionsQuery, FindSingleOrganizationPositionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindSingleOrganizationPositionsQuery, FindSingleOrganizationPositionsQueryVariables>(FindSingleOrganizationPositionsDocument, options);
        }
export type FindSingleOrganizationPositionsQueryHookResult = ReturnType<typeof useFindSingleOrganizationPositionsQuery>;
export type FindSingleOrganizationPositionsLazyQueryHookResult = ReturnType<typeof useFindSingleOrganizationPositionsLazyQuery>;
export type FindSingleOrganizationPositionsSuspenseQueryHookResult = ReturnType<typeof useFindSingleOrganizationPositionsSuspenseQuery>;
export type FindSingleOrganizationPositionsQueryResult = Apollo.QueryResult<FindSingleOrganizationPositionsQuery, FindSingleOrganizationPositionsQueryVariables>;
export const FindSingleOrganizationModulesDocument = gql`
    query findSingleOrganizationModules($input: FindOrganizationInput!) {
  findSingleOrganization(findSingleOrganization: $input) {
    modules
  }
}
    `;

/**
 * __useFindSingleOrganizationModulesQuery__
 *
 * To run a query within a React component, call `useFindSingleOrganizationModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSingleOrganizationModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSingleOrganizationModulesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindSingleOrganizationModulesQuery(baseOptions: Apollo.QueryHookOptions<FindSingleOrganizationModulesQuery, FindSingleOrganizationModulesQueryVariables> & ({ variables: FindSingleOrganizationModulesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSingleOrganizationModulesQuery, FindSingleOrganizationModulesQueryVariables>(FindSingleOrganizationModulesDocument, options);
      }
export function useFindSingleOrganizationModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSingleOrganizationModulesQuery, FindSingleOrganizationModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSingleOrganizationModulesQuery, FindSingleOrganizationModulesQueryVariables>(FindSingleOrganizationModulesDocument, options);
        }
export function useFindSingleOrganizationModulesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FindSingleOrganizationModulesQuery, FindSingleOrganizationModulesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindSingleOrganizationModulesQuery, FindSingleOrganizationModulesQueryVariables>(FindSingleOrganizationModulesDocument, options);
        }
export type FindSingleOrganizationModulesQueryHookResult = ReturnType<typeof useFindSingleOrganizationModulesQuery>;
export type FindSingleOrganizationModulesLazyQueryHookResult = ReturnType<typeof useFindSingleOrganizationModulesLazyQuery>;
export type FindSingleOrganizationModulesSuspenseQueryHookResult = ReturnType<typeof useFindSingleOrganizationModulesSuspenseQuery>;
export type FindSingleOrganizationModulesQueryResult = Apollo.QueryResult<FindSingleOrganizationModulesQuery, FindSingleOrganizationModulesQueryVariables>;
export const CreateOrganizationUnitDocument = gql`
    mutation createOrganizationUnit($createOrganizationUnitInput: CreateOrganizationUnitInput!) {
  createOrganizationUnit(
    createOrganizationUnitInput: $createOrganizationUnitInput
  ) {
    ...OrganizationUnitFragment
  }
}
    ${OrganizationUnitFragmentFragmentDoc}`;
export type CreateOrganizationUnitMutationFn = Apollo.MutationFunction<CreateOrganizationUnitMutation, CreateOrganizationUnitMutationVariables>;

/**
 * __useCreateOrganizationUnitMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationUnitMutation, { data, loading, error }] = useCreateOrganizationUnitMutation({
 *   variables: {
 *      createOrganizationUnitInput: // value for 'createOrganizationUnitInput'
 *   },
 * });
 */
export function useCreateOrganizationUnitMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationUnitMutation, CreateOrganizationUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationUnitMutation, CreateOrganizationUnitMutationVariables>(CreateOrganizationUnitDocument, options);
      }
export type CreateOrganizationUnitMutationHookResult = ReturnType<typeof useCreateOrganizationUnitMutation>;
export type CreateOrganizationUnitMutationResult = Apollo.MutationResult<CreateOrganizationUnitMutation>;
export type CreateOrganizationUnitMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationUnitMutation, CreateOrganizationUnitMutationVariables>;
export const DeleteOrganizationUnitDocument = gql`
    mutation deleteOrganizationUnit($deleteOrganizationUnitInput: DeleteOrganizationInput!) {
  deleteOrganizationUnit(
    deleteOrganizationUnitInput: $deleteOrganizationUnitInput
  ) {
    success
    children {
      employees
      organizationUnits
    }
  }
}
    `;
export type DeleteOrganizationUnitMutationFn = Apollo.MutationFunction<DeleteOrganizationUnitMutation, DeleteOrganizationUnitMutationVariables>;

/**
 * __useDeleteOrganizationUnitMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationUnitMutation, { data, loading, error }] = useDeleteOrganizationUnitMutation({
 *   variables: {
 *      deleteOrganizationUnitInput: // value for 'deleteOrganizationUnitInput'
 *   },
 * });
 */
export function useDeleteOrganizationUnitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganizationUnitMutation, DeleteOrganizationUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrganizationUnitMutation, DeleteOrganizationUnitMutationVariables>(DeleteOrganizationUnitDocument, options);
      }
export type DeleteOrganizationUnitMutationHookResult = ReturnType<typeof useDeleteOrganizationUnitMutation>;
export type DeleteOrganizationUnitMutationResult = Apollo.MutationResult<DeleteOrganizationUnitMutation>;
export type DeleteOrganizationUnitMutationOptions = Apollo.BaseMutationOptions<DeleteOrganizationUnitMutation, DeleteOrganizationUnitMutationVariables>;
export const UpdateOrganizationUnitDocument = gql`
    mutation updateOrganizationUnit($updateOrganizationUnitInput: UpdateOrganizationUnitDto!) {
  updateOrganizationUnit(
    updateOrganizationUnitInput: $updateOrganizationUnitInput
  ) {
    name
    parentGuid
    id
  }
}
    `;
export type UpdateOrganizationUnitMutationFn = Apollo.MutationFunction<UpdateOrganizationUnitMutation, UpdateOrganizationUnitMutationVariables>;

/**
 * __useUpdateOrganizationUnitMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationUnitMutation, { data, loading, error }] = useUpdateOrganizationUnitMutation({
 *   variables: {
 *      updateOrganizationUnitInput: // value for 'updateOrganizationUnitInput'
 *   },
 * });
 */
export function useUpdateOrganizationUnitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationUnitMutation, UpdateOrganizationUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationUnitMutation, UpdateOrganizationUnitMutationVariables>(UpdateOrganizationUnitDocument, options);
      }
export type UpdateOrganizationUnitMutationHookResult = ReturnType<typeof useUpdateOrganizationUnitMutation>;
export type UpdateOrganizationUnitMutationResult = Apollo.MutationResult<UpdateOrganizationUnitMutation>;
export type UpdateOrganizationUnitMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationUnitMutation, UpdateOrganizationUnitMutationVariables>;
export const GetCheckboxOrganizationUnitStructureDocument = gql`
    query getCheckboxOrganizationUnitStructure($input: GetOrganizationUnitInput!) {
  getOrganizationUnitsStructure(getOrganizationUnitStructureInput: $input) {
    children
    id
    name
    parentGuid
  }
}
    `;

/**
 * __useGetCheckboxOrganizationUnitStructureQuery__
 *
 * To run a query within a React component, call `useGetCheckboxOrganizationUnitStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckboxOrganizationUnitStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckboxOrganizationUnitStructureQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCheckboxOrganizationUnitStructureQuery(baseOptions: Apollo.QueryHookOptions<GetCheckboxOrganizationUnitStructureQuery, GetCheckboxOrganizationUnitStructureQueryVariables> & ({ variables: GetCheckboxOrganizationUnitStructureQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckboxOrganizationUnitStructureQuery, GetCheckboxOrganizationUnitStructureQueryVariables>(GetCheckboxOrganizationUnitStructureDocument, options);
      }
export function useGetCheckboxOrganizationUnitStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckboxOrganizationUnitStructureQuery, GetCheckboxOrganizationUnitStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckboxOrganizationUnitStructureQuery, GetCheckboxOrganizationUnitStructureQueryVariables>(GetCheckboxOrganizationUnitStructureDocument, options);
        }
export function useGetCheckboxOrganizationUnitStructureSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCheckboxOrganizationUnitStructureQuery, GetCheckboxOrganizationUnitStructureQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCheckboxOrganizationUnitStructureQuery, GetCheckboxOrganizationUnitStructureQueryVariables>(GetCheckboxOrganizationUnitStructureDocument, options);
        }
export type GetCheckboxOrganizationUnitStructureQueryHookResult = ReturnType<typeof useGetCheckboxOrganizationUnitStructureQuery>;
export type GetCheckboxOrganizationUnitStructureLazyQueryHookResult = ReturnType<typeof useGetCheckboxOrganizationUnitStructureLazyQuery>;
export type GetCheckboxOrganizationUnitStructureSuspenseQueryHookResult = ReturnType<typeof useGetCheckboxOrganizationUnitStructureSuspenseQuery>;
export type GetCheckboxOrganizationUnitStructureQueryResult = Apollo.QueryResult<GetCheckboxOrganizationUnitStructureQuery, GetCheckboxOrganizationUnitStructureQueryVariables>;
export const GetOrganizationUnitChildrenNameDocument = gql`
    query getOrganizationUnitChildrenName($getOrganizationUnitInput: GetOrganizationUnitInput!) {
  getOrganizationUnitsStructure(
    getOrganizationUnitStructureInput: $getOrganizationUnitInput
  ) {
    id
    name
    children
  }
}
    `;

/**
 * __useGetOrganizationUnitChildrenNameQuery__
 *
 * To run a query within a React component, call `useGetOrganizationUnitChildrenNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationUnitChildrenNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationUnitChildrenNameQuery({
 *   variables: {
 *      getOrganizationUnitInput: // value for 'getOrganizationUnitInput'
 *   },
 * });
 */
export function useGetOrganizationUnitChildrenNameQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationUnitChildrenNameQuery, GetOrganizationUnitChildrenNameQueryVariables> & ({ variables: GetOrganizationUnitChildrenNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationUnitChildrenNameQuery, GetOrganizationUnitChildrenNameQueryVariables>(GetOrganizationUnitChildrenNameDocument, options);
      }
export function useGetOrganizationUnitChildrenNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationUnitChildrenNameQuery, GetOrganizationUnitChildrenNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationUnitChildrenNameQuery, GetOrganizationUnitChildrenNameQueryVariables>(GetOrganizationUnitChildrenNameDocument, options);
        }
export function useGetOrganizationUnitChildrenNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationUnitChildrenNameQuery, GetOrganizationUnitChildrenNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationUnitChildrenNameQuery, GetOrganizationUnitChildrenNameQueryVariables>(GetOrganizationUnitChildrenNameDocument, options);
        }
export type GetOrganizationUnitChildrenNameQueryHookResult = ReturnType<typeof useGetOrganizationUnitChildrenNameQuery>;
export type GetOrganizationUnitChildrenNameLazyQueryHookResult = ReturnType<typeof useGetOrganizationUnitChildrenNameLazyQuery>;
export type GetOrganizationUnitChildrenNameSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationUnitChildrenNameSuspenseQuery>;
export type GetOrganizationUnitChildrenNameQueryResult = Apollo.QueryResult<GetOrganizationUnitChildrenNameQuery, GetOrganizationUnitChildrenNameQueryVariables>;
export const GetOrganizationUnitStructureDocument = gql`
    query getOrganizationUnitStructure($getOrganizationUnitInput: GetOrganizationUnitInput!) {
  getOrganizationUnitsStructure(
    getOrganizationUnitStructureInput: $getOrganizationUnitInput
  ) {
    children
    id
    name
    parentGuid
    members {
      firstName
      lastName
      position
      profilePicture
      systemRole
    }
  }
}
    `;

/**
 * __useGetOrganizationUnitStructureQuery__
 *
 * To run a query within a React component, call `useGetOrganizationUnitStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationUnitStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationUnitStructureQuery({
 *   variables: {
 *      getOrganizationUnitInput: // value for 'getOrganizationUnitInput'
 *   },
 * });
 */
export function useGetOrganizationUnitStructureQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationUnitStructureQuery, GetOrganizationUnitStructureQueryVariables> & ({ variables: GetOrganizationUnitStructureQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationUnitStructureQuery, GetOrganizationUnitStructureQueryVariables>(GetOrganizationUnitStructureDocument, options);
      }
export function useGetOrganizationUnitStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationUnitStructureQuery, GetOrganizationUnitStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationUnitStructureQuery, GetOrganizationUnitStructureQueryVariables>(GetOrganizationUnitStructureDocument, options);
        }
export function useGetOrganizationUnitStructureSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationUnitStructureQuery, GetOrganizationUnitStructureQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationUnitStructureQuery, GetOrganizationUnitStructureQueryVariables>(GetOrganizationUnitStructureDocument, options);
        }
export type GetOrganizationUnitStructureQueryHookResult = ReturnType<typeof useGetOrganizationUnitStructureQuery>;
export type GetOrganizationUnitStructureLazyQueryHookResult = ReturnType<typeof useGetOrganizationUnitStructureLazyQuery>;
export type GetOrganizationUnitStructureSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationUnitStructureSuspenseQuery>;
export type GetOrganizationUnitStructureQueryResult = Apollo.QueryResult<GetOrganizationUnitStructureQuery, GetOrganizationUnitStructureQueryVariables>;
export const ModifyPermissionsDocument = gql`
    mutation ModifyPermissions($input: ModifyPermissionInput!) {
  modifyPermissions(modifyPermissionInput: $input) {
    success
  }
}
    `;
export type ModifyPermissionsMutationFn = Apollo.MutationFunction<ModifyPermissionsMutation, ModifyPermissionsMutationVariables>;

/**
 * __useModifyPermissionsMutation__
 *
 * To run a mutation, you first call `useModifyPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyPermissionsMutation, { data, loading, error }] = useModifyPermissionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyPermissionsMutation(baseOptions?: Apollo.MutationHookOptions<ModifyPermissionsMutation, ModifyPermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyPermissionsMutation, ModifyPermissionsMutationVariables>(ModifyPermissionsDocument, options);
      }
export type ModifyPermissionsMutationHookResult = ReturnType<typeof useModifyPermissionsMutation>;
export type ModifyPermissionsMutationResult = Apollo.MutationResult<ModifyPermissionsMutation>;
export type ModifyPermissionsMutationOptions = Apollo.BaseMutationOptions<ModifyPermissionsMutation, ModifyPermissionsMutationVariables>;