import { useMemo } from 'react';

import { checkSystemRolesPermission } from '@core/utils';

import { DashboardTypesEnum, SystemRoles } from '@/__generated__/graphql';
import { useUserStore } from '@/stores';

export const useWidgetMenuPermission = (type: DashboardTypesEnum) => {
  const { userGroups } = useUserStore();

  const { UnitAdmin, Administrator, User, KeyUser } = SystemRoles;

  const {
    KpiEmployeeDashboard,
    KpiOrganizationUnitDashboard,
    KpiOrganizationDashboard,
  } = DashboardTypesEnum;

  const isUserPermittedToEdit = useMemo(() => {
    switch (type) {
      case KpiOrganizationDashboard:
        return checkSystemRolesPermission(userGroups, [
          KeyUser,
          Administrator,
          UnitAdmin,
        ]);

      case KpiOrganizationUnitDashboard:
        return checkSystemRolesPermission(userGroups, [
          UnitAdmin,
          Administrator,
          KeyUser,
        ]);

      case KpiEmployeeDashboard:
        return checkSystemRolesPermission(userGroups, [
          User,
          Administrator,
          UnitAdmin,
        ]);

      default:
        return false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return { isUserPermittedToEdit };
};
