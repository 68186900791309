import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { NavbarUserMenuComponent } from '@/core/components/NavbarCore/NavbarUserMenuComponent';
import { useKaizenRedirects } from '@/core/redirects';
import {
  NavbarApps,
  NavbarLinks,
  NavbarMainContainer,
  NavbarNavLink,
  NavbarNavigation,
} from '@/core/styles/Navbar';

export const KaizenNavbar = () => {
  const { t } = useTranslation('kaizen');

  const { companyName } = useParams();

  const { submissionsPath, rankingPath, reportPath } = useKaizenRedirects();

  return (
    <NavbarMainContainer>
      <NavbarLinks>
        <NavbarApps to={`/${companyName!}`}>
          <img
            style={{
              width: 300,
              scale: 3,
              display: 'block',
            }}
            src="/assets/digital-factory-kaizen.svg"
            alt="Logo"
          />
        </NavbarApps>
        <NavbarNavigation>
          {/* Use the custom NavLink component */}
          <NavbarNavLink
            to={submissionsPath}
            className={({ isActive }) => {
              return isActive ? 'active' : '';
            }}
          >
            {t('navigation.dashboard')}
          </NavbarNavLink>
          <NavbarNavLink
            to={rankingPath}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {t('navigation.ranking')}
          </NavbarNavLink>
          <NavbarNavLink
            to={reportPath}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {t('navigation.reports')}
          </NavbarNavLink>
        </NavbarNavigation>
      </NavbarLinks>

      <NavbarUserMenuComponent />
    </NavbarMainContainer>
  );
};
