import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { NavbarUserMenuComponent } from '@/core/components/NavbarCore/NavbarUserMenuComponent';
import {
  NavbarApps,
  NavbarLinks,
  NavbarMainContainer,
  NavbarNavLink,
  NavbarNavigation,
} from '@/core/styles/Navbar';

import { ReportModal } from '../ReportModal';

export const KpiNavbar = () => {
  const { t } = useTranslation('kpi');

  const { companyName } = useParams();

  return (
    <NavbarMainContainer>
      <NavbarLinks>
        <NavbarApps to={`/${companyName!}`}>
          <img
            style={{
              width: 300,
              scale: 3,
              display: 'block',
            }}
            src="/assets/digital-factory-kpi.svg"
            alt="Logo"
          />
        </NavbarApps>
        <NavbarNavigation>
          {/* Use the custom NavLink component */}
          <NavbarNavLink
            to={'dashboard'}
            className={({ isActive }) => {
              return isActive ? 'active' : '';
            }}
          >
            {t('navigation.dashboard')}
          </NavbarNavLink>
          <NavbarNavLink
            to={'indicators'}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {t('navigation.indicators')}
          </NavbarNavLink>
          <ReportModal />
        </NavbarNavigation>
      </NavbarLinks>

      <NavbarUserMenuComponent />
    </NavbarMainContainer>
  );
};
