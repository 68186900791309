import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId } from '@core/hooks';

import {
  ChartFragmentDoc,
  CreateKpiChartInput,
  useCreateKpiChartMutation,
  useUpdateKpiChartMutationMutation,
} from '@/__generated__/graphql.ts';
import { client } from '@/apollo.ts';

export type ChartNetworkingParams = {
  chartMode: 'add' | 'update';
  dashboardId: string;
  inputValues: Pick<
    CreateKpiChartInput,
    'kpiIds' | 'displayOptions' | 'chartType'
  >;
  close: () => void;
  chartId?: string;
  resetValues: () => void;
};

export const useChartNetworking = ({
  chartMode,
  dashboardId,
  inputValues,
  close,
  resetValues,
  chartId,
}: ChartNetworkingParams) => {
  const { openSnackbar } = useSnackbarContext();

  const { t } = useTranslation('charts');

  const { companyId } = useCompanyId();

  const [createKpiChart] = useCreateKpiChartMutation();
  const [updateKpiChart] = useUpdateKpiChartMutationMutation();

  const onSubmit = async (data: { name: string }) => {
    if (chartMode === 'add') {
      return createNewChart(data.name);
    }
    if (chartMode === 'update') {
      return updateChart(data.name);
    }
  };

  const createNewChart = async (chartName: string) => {
    try {
      await createKpiChart({
        variables: {
          input: {
            chartName,
            organizationId: companyId,
            dashboardId,
            ...inputValues,
          },
        },
      });

      openSnackbar(t('chartCreated'));
    } catch (error) {
      captureException(error);
      openSnackbar(t('chartCreationError'));
    } finally {
      close();
      resetValues();
    }
  };

  const updateChart = async (chartName: string) => {
    try {
      const { data } = await updateKpiChart({
        variables: {
          input: {
            chartId: chartId!,
            chartName,
            organizationId: companyId,
            ...inputValues,
          },
        },
      });

      client.writeFragment({
        fragment: ChartFragmentDoc,
        id: 'Chart:' + chartId,
        data: {
          ...data?.updateKpiChart,
          __typename: 'Chart',
        },
      });

      openSnackbar(t('chartUpdated'));
    } catch (error) {
      captureException(error);
      openSnackbar(t('chartUpdateError'));
    } finally {
      close();
      resetValues();
    }
  };

  return {
    onSubmit,
  };
};
