import { useTranslation } from 'react-i18next';

import { KpiChartWidget } from '@core/modules/DashbordModule/components/widgets/Chart/KpiChartWidget.tsx';

import {
  DashboardWidgetDto,
  SupportedWidgetsEnum,
} from '@/__generated__/graphql.ts';

export const Widget = (props: DashboardWidgetDto) => {
  const { t } = useTranslation('dashboard');

  if (props.widgetType === SupportedWidgetsEnum.KpiChart) {
    return <KpiChartWidget {...props} />;
  }
  return <span>{t('widgets.unsupported')}</span>;
};
