import { useEffect, useState } from 'react';

import { useCreateDashboard } from '@core/modules/DashbordModule/hooks/useCreateDashboard.tsx';
import { useDashboardLayout } from '@core/modules/DashbordModule/hooks/useDashboardLayout.tsx';
import { useFetchDashboard } from '@core/modules/DashbordModule/hooks/useFetchDashboard.tsx';
import { useRemoveItem } from '@core/modules/DashbordModule/hooks/useRemoveItem.tsx';
import { useCreateDashboardType } from '@core/modules/DashbordModule/types/useCreateDashboard.type.ts';

import { DashboardFragment } from '@/__generated__/graphql.ts';

import { useChangeWidgetVisibility } from './WidgetHooks';

export const useDashboard = (props: useCreateDashboardType) => {
  const [dashboard, setDashboard] = useState<DashboardFragment | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const manageLayout = useDashboardLayout(
    setDashboard,
    dashboard?.id as string,
  );
  const {
    fetchDashboard,
    isLoading: isDashboardFetchingInProgress,
    reFetchDashboard,
  } = useFetchDashboard(setDashboard);

  const removeItem = useRemoveItem(setDashboard, dashboard);
  const changeVisibility = useChangeWidgetVisibility(
    setDashboard,
    dashboard?.id,
  );

  useEffect(() => {
    setIsLoading(isDashboardFetchingInProgress);
  }, [isDashboardFetchingInProgress]);

  useEffect(() => {
    void fetchDashboard(props.dashboardData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboardData]);

  const { createDashboard } = useCreateDashboard(
    setDashboard,
    props.dashboardData,
  );

  const addOnEmpty = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      if (!dashboard) {
        const createdDashboard = await createDashboard();
        if (createdDashboard) {
          if (props.addOnEmpty) props.addOnEmpty(createdDashboard.id);
          await reFetchDashboard();
        }
      } else {
        if (props.addOnEmpty) props.addOnEmpty(dashboard.id);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    dashboard,
    isLoading,
    ...removeItem,
    ...manageLayout,
    ...changeVisibility,
    createDashboardData: props.dashboardData,
    emptyDashboardComponent: props.emptyDashboardComponent,
    addOnEmpty,
    setDashboard,
    fetchDashboard,
    createDashboard,
    reFetchDashboard,
    dashboardType: props.dashboardData.type,
    settlementPeriodId: props.dashboardData.settlementPeriodId,
  };
};
