import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { BarChart as MuiBarChart } from '@mui/x-charts';

import { ChartWrapper } from '@core/components';
import { BarChartTypes } from '@core/components/Statistica/BarChart/BarChart.types';

import { useBarChart } from './useBarChart';

export const BarChart: FC<BarChartTypes> = ({
  width,
  height,
  isLoading,
  title,
  noDataMessage,
  isDataValid = true,
  ...cartProps
}) => {
  const { t } = useTranslation();

  const { reactContainerRef, getLegendData, chartWidth } =
    useBarChart(cartProps);

  if (isLoading) {
    return (
      <Stack spacing={0}>
        <Skeleton variant="rectangular" width={width} height={height} />
      </Stack>
    );
  }

  if (cartProps.series.length === 0) {
    return (
      <StyledCard>
        <CardContent>
          <Typography variant="h4">{title}</Typography>
          <Typography variant="h6">{noDataMessage}</Typography>
        </CardContent>
      </StyledCard>
    );
  }

  if (!isDataValid) {
    return (
      <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
        <Typography variant={'h6'}>{t('common.invalidData')}</Typography>
      </Box>
    );
  }

  return (
    <ChartWrapper title={title} ref={reactContainerRef}>
      <MuiBarChart
        width={chartWidth - 50}
        height={300}
        {...cartProps}
        slotProps={{ legend: { hidden: true } }}
      />

      <Stack display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
        {getLegendData().map((item) => {
          return (
            <Stack display={'flex'} flexDirection={'row'} margin={'7px'}>
              <StyledBox sx={{ backgroundColor: item.color }} />
              <Typography variant="bodyRegular">{item.name}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </ChartWrapper>
  );
};

const StyledBox = styled(Box)({
  width: '20px',
  height: '20px',
  marginRight: '5px',
  borderRadius: '5px',
});
const StyledCard = styled(Card)({
  gap: '16px',
  padding: '24px',
  borderRadius: '8px',
  width: '49%',
  alignItems: 'end',
});
