import { isPercentage } from '@kpi/common/isPercentage.utils';

import { ChartTypes, KpiRateDto } from '@/__generated__/graphql';

export class ManageChartOptionAvailability {
  static availableOptions = {
    ascendingOrder: false,
    dataAsPercentage: false,
    showTarget: false,
    sumIndicators: false,
  };

  public static getAvailableOptions(
    selectedKpis: KpiRateDto[],
    selectedChartType: ChartTypes | null,
  ) {
    /**
     * Reset the options
     */
    ManageChartOptionAvailability.availableOptions = {
      ascendingOrder: true,
      dataAsPercentage: true,
      showTarget: true,
      sumIndicators: true,
    };

    if (!selectedChartType || selectedKpis.length === 0)
      return ManageChartOptionAvailability.availableOptions;

    if (ManageChartOptionAvailability.hasTarget(selectedKpis)) {
      ManageChartOptionAvailability.availableOptions.showTarget = true;
    } else {
      ManageChartOptionAvailability.availableOptions.showTarget = false;
    }

    if (isPercentage(selectedKpis[0].unit)) {
      ManageChartOptionAvailability._checkIfPercentage(
        selectedKpis,
        selectedChartType,
      );
      return ManageChartOptionAvailability.availableOptions;
    }

    ManageChartOptionAvailability._checkIfOtherUnits(
      selectedKpis,
      selectedChartType,
    );
    return ManageChartOptionAvailability.availableOptions;
  }

  static hasTarget(selectedKpis: KpiRateDto[]) {
    return selectedKpis.every((kpi) => kpi.hasTarget);
  }

  static isSingle(selectedKpis: KpiRateDto[]) {
    return selectedKpis.length <= 1;
  }

  static isLineOrBar(selectedChartType: ChartTypes | null) {
    return (
      selectedChartType === ChartTypes.LineChart ||
      selectedChartType === ChartTypes.BarChart
    );
  }

  static isBarOrPie(selectedChartType: ChartTypes | null) {
    return (
      selectedChartType === ChartTypes.BarChart ||
      selectedChartType === ChartTypes.PieChart
    );
  }

  static isPie(selectedChartType: ChartTypes | null) {
    return selectedChartType === ChartTypes.PieChart;
  }

  /**
   * Check if the selected KPIs are in percentage
   */
  static _checkIfPercentage(
    selectedKpis: KpiRateDto[],
    selectedChartType: ChartTypes,
  ) {
    /**
     * If the selected KPIs are in percentage and there is a target
     */
    if (ManageChartOptionAvailability.hasTarget(selectedKpis)) {
      /**
       * Is available when a chart type is line or bar
       */
      ManageChartOptionAvailability.availableOptions.showTarget =
        ManageChartOptionAvailability.isLineOrBar(selectedChartType);

      /**
       * Is available when a chart type is bar or pie
       */
      ManageChartOptionAvailability.availableOptions.ascendingOrder =
        ManageChartOptionAvailability.isBarOrPie(selectedChartType);
    }
  }

  /**
   * Check if the selected KPIs are not in percentage
   */
  static _checkIfOtherUnits(
    selectedKpis: KpiRateDto[],
    selectedChartType: ChartTypes,
  ) {
    if (ManageChartOptionAvailability.hasTarget(selectedKpis)) {
      ManageChartOptionAvailability.availableOptions.dataAsPercentage = true;

      if (ManageChartOptionAvailability.isSingle(selectedKpis)) {
        /**
         * Is available for single KPI
         * when a chart type is line or bar
         * and there is a target
         */
        ManageChartOptionAvailability.availableOptions.showTarget = true;

        /**
         * Is available for single KPI
         * when a chart type is bar or pie
         * and there is a target
         */
        ManageChartOptionAvailability.availableOptions.ascendingOrder =
          ManageChartOptionAvailability.isBarOrPie(selectedChartType);

        return;
      }

      /**
       * Is available for multiple KPIs
       */
      ManageChartOptionAvailability.availableOptions.sumIndicators = true;

      ManageChartOptionAvailability.availableOptions.showTarget =
        ManageChartOptionAvailability.isLineOrBar(selectedChartType);

      ManageChartOptionAvailability.availableOptions.ascendingOrder =
        ManageChartOptionAvailability.isBarOrPie(selectedChartType);

      return;
    }

    /**
     * When KPIs don't have a target
     */
    if (ManageChartOptionAvailability.isSingle(selectedKpis)) return;

    ManageChartOptionAvailability.availableOptions.dataAsPercentage =
      ManageChartOptionAvailability.isPie(selectedChartType);

    ManageChartOptionAvailability.availableOptions.sumIndicators =
      ManageChartOptionAvailability.isBarOrPie(selectedChartType);
  }
}
