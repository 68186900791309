import { Menu } from '@mui/material';

import { useWidgetMenu } from '@core/modules/DashbordModule/hooks';
import { WidgetMenuTypes } from '@core/modules/DashbordModule/types/WidgetMenu.types.ts';

export const WidgetMenu = (props: WidgetMenuTypes) => {
  const { renderMenuVariant } = useWidgetMenu(
    props.widgetData,
    props.widgetContent,
    props.handleMenuClose,
  );

  return (
    <Menu
      anchorEl={props.anchorEl}
      onClose={props.handleMenuClose}
      open={props.isMenuOpen}
    >
      {renderMenuVariant()}
    </Menu>
  );
};
