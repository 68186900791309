/* eslint-disable @typescript-eslint/naming-convention */
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Fade, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LinkToOrganizationSettings } from '@/core/components';
import { AvatarMenu } from '@/core/components/AvatarMenu/AvatarMenu';
import { CustomTooltip, NavbarApps } from '@/core/styles';
import { NavbarMainContainer, NavbarUserMenu } from '@/core/styles/Navbar';
import { useOrganizationStore, useUserStore } from '@/stores';
import { theme } from '@/theme';
import { userIsPermitted } from '@/utils';

export const Navbar = () => {
  const { t } = useTranslation();
  const params = useParams();

  const { organization } = useOrganizationStore();
  const { userGroups, organizationId } = useUserStore();
  const { companyName } = useParams();

  return (
    <NavbarMainContainer>
      <NavbarLinks>
        <NavbarApps to={`/${params.companyName ? params.companyName : ''}`}>
          <img
            style={{
              width: 200,
              scale: 3,
              display: 'block',
            }}
            src="/assets/ecps-logo.svg"
            alt="Apps"
          />
        </NavbarApps>

        <NavbarNavigation>
          {/* Use the custom NavLink component */}
          <NavbarNavLink
            to="structure"
            className={({ isActive }) => {
              return isActive ? 'active' : '';
            }}
          >
            {t('structure.title')}
          </NavbarNavLink>
          <NavbarNavLink
            to="organization"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {t('organizationTree.title')}
          </NavbarNavLink>
          <NavbarNavLink
            to="employees"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            {t('employees.title')}
          </NavbarNavLink>
        </NavbarNavigation>
      </NavbarLinks>

      <NavbarUserMenu>
        {userIsPermitted(
          userGroups,
          organizationId!,
          organization[companyName!],
        ) && (
          <LinkToOrganizationSettings
            organizationName={params.companyName ? params.companyName : ''}
          >
            <CustomTooltip
              title={t('organizationSettings.organizationSettings')}
              placement="bottom-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton>
                <SettingsOutlinedIcon
                  sx={{
                    color: theme.palette.grey[900],
                  }}
                />
              </IconButton>
            </CustomTooltip>
          </LinkToOrganizationSettings>
        )}
        <AvatarMenu />
      </NavbarUserMenu>
    </NavbarMainContainer>
  );
};

const NavbarLinks = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const NavbarNavigation = styled('div')({
  marginLeft: 48,
  display: 'flex',
  columnGap: 32,
});

const NavbarNavLink = styled(NavLink)(({ theme }) => ({
  color: `var(--text-text-primary, ${theme.palette.text.primary})`,
  fontFamily: 'Inter',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  textDecoration: 'none',
  borderRadius: 'var(--radius-basic, 8px)',
  display: 'flex',
  padding: '6px 12px',
  alignItems: 'center',
  gap: '4px',
  '&.active': {
    background: `var(--primary-primary-50, ${theme.palette.primary[50]})`,
  },
}));
