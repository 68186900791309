import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useManageCacheKpiReport } from '@kpi/hooks';
import dayjs, { Dayjs } from 'dayjs';

import { useSnackbarContext } from '@core/contexts';
import { useCompanyId, useGetCurrentUserDetails } from '@core/hooks';
import { validateEntry } from '@core/utils';

import {
  KpiRateDto,
  useReportKpiRateEntryMutation,
} from '@/__generated__/graphql';

export const useSingleReport = (
  id: string,
  close: () => void,
  kpi: KpiRateDto,
  defaultDate?: string,
) => {
  const { t } = useTranslation('kpi');
  const { companyId } = useCompanyId();
  const { user } = useGetCurrentUserDetails();
  const { openSnackbar } = useSnackbarContext();

  const [isUpdating, setIsUpdating] = useState(false);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [entryValue, setEntryValue] = useState('');
  const [isValid, setIsValid] = useState(false);

  const { updateEntriesCache } = useManageCacheKpiReport();

  const [updateEntry] = useReportKpiRateEntryMutation();

  useEffect(() => {
    if (defaultDate) {
      const newDate = dayjs(new Date(defaultDate)).format('MMMM.YYYY');
      setDate(dayjs(newDate));
    } else {
      const newDate = dayjs(new Date()).format('MMMM.YYYY');
      setDate(dayjs(newDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!date || !kpi?.entries) return;
    const entry = kpi.entries[date.toDate().getMonth() + 1];

    if (typeof entry?.entry === 'number') setEntryValue(entry?.entry + '');
    else setEntryValue('');
  }, [date, kpi]);

  useEffect(() => {
    setIsValid(validateEntry(entryValue));
  }, [entryValue]);

  const onSubmit = async () => {
    if (!companyId && !user) return;
    setIsUpdating(true);

    const input = {
      entries: [
        {
          entry: entryValue !== '' ? parseFloat(entryValue) : 0,
          month: date!.toDate().getMonth() + 1,
          rateId: id,
        },
      ],
      organizationId: companyId,
      organizationUnitId: user!.organizationUnitGuid,
    };

    try {
      const result = await updateEntry({
        variables: {
          input,
        },
      });

      if (result.data) {
        updateEntriesCache(input);
        openSnackbar(t('report.snackBarSuccessMessage'));
        close();
      }
    } catch (err) {
      openSnackbar(t('report.snackBarFailMessage'));
      close();
    }
    setIsUpdating(false);
  };

  return {
    isUpdating,
    date,
    setDate,
    data: kpi,
    entryValue,
    setEntryValue,
    onSubmit,
    close,
    isValid,
    setIsValid,
  };
};
