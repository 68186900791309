import { useEffect, useState } from 'react';

import { isArray } from 'lodash';

import { useCompanyId } from '@core/hooks';

import {
  SingleSubunitDto,
  SystemRoles,
  useFindOrganizationSubunitsLazyQuery,
} from '@/__generated__/graphql';
import { useUserStore } from '@/stores';

import { OrganizationUnitData } from './OraganizationUnitSelect.types';

export const useOrganizationUnitSelect = (
  multiple: boolean,
  onChange: (unitId?: string | string[], loadDefault?: boolean) => void,
  organizationUnitId?: string,
  defaultValue?: SingleSubunitDto | SingleSubunitDto[],
) => {
  const { companyId } = useCompanyId();
  const { userGroups } = useUserStore();

  const [unitData, setUnitData] = useState<OrganizationUnitData[]>([]);
  const [selectValue, setSelectValue] = useState<
    SingleSubunitDto | SingleSubunitDto[] | null
  >(multiple ? [] : null);

  const [isInitialDefaultValueSet, setIsInitialDefaultValueSet] =
    useState(true);

  const [loadOrganizationUnits, { data, loading: isLoading }] =
    useFindOrganizationSubunitsLazyQuery();

  useEffect(() => {
    if (defaultValue && isInitialDefaultValueSet) {
      setIsInitialDefaultValueSet(false);

      const unit = multiple
        ? defaultValue
        : (defaultValue as SingleSubunitDto[])[0];
      onSelectChange(unit, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const loadData = async () => {
    if (!companyId) return;

    await loadOrganizationUnits({
      variables: {
        input: {
          organizationId: companyId,
          ...(organizationUnitId && { organizationUnitId }),
        },
      },
    });
  };

  useEffect(() => {
    void loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    if (data) {
      const newData = [...data.findOrganizationSubunits.subunits];
      newData.push(data.findOrganizationSubunits.rootUnit);

      const userUnit = newData.find(
        (subunit) => subunit.id == organizationUnitId,
      );

      if (userGroups.includes(SystemRoles.User)) {
        if (userUnit) setUnitData([userUnit]);
      } else {
        setUnitData(newData);
      }
    }
  }, [data, organizationUnitId, userGroups]);

  const onSelectChange = (
    unit: SingleSubunitDto | SingleSubunitDto[] | null,
    loadDefault?: boolean,
  ) => {
    setSelectValue(unit);
    const isUnitIdArray = isArray(unit);

    if (isUnitIdArray)
      return onChange(!unit ? [] : unit.map((unit) => unit.id), loadDefault);

    return onChange(unit?.id, loadDefault);
  };

  return { selectValue, unitData, isLoading, onSelectChange, loadData };
};
