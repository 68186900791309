import { captureException } from '@sentry/react';
import { Dispatch, SetStateAction } from 'react';

import {
  DashboardFragment,
  FindDashboardInput,
  useGetDashboardLazyQuery,
} from '@/__generated__/graphql.ts';

export const useFetchDashboard = (
  setDashboard: Dispatch<SetStateAction<DashboardFragment | undefined>>,
) => {
  const [loadData, { loading: isLoading, refetch }] = useGetDashboardLazyQuery({
    initialFetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const fetchDashboard = async (findDashboardInput: FindDashboardInput) => {
    try {
      if (
        !findDashboardInput.organizationId ||
        !findDashboardInput.type ||
        !findDashboardInput.settlementPeriodId
      ) {
        return;
      }

      const { data } = await loadData({
        variables: { input: findDashboardInput },
      });

      if (data) {
        setDashboard(data.getKpiDashboard);
      } else {
        setDashboard(undefined);
      }
    } catch (error) {
      captureException(error);
    }
  };

  return {
    isLoading,
    fetchDashboard,
    reFetchDashboard: refetch,
  };
};
