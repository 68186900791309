import { Ref, forwardRef } from 'react';

import { Card, Typography, styled } from '@mui/material';

import { ChartWrapperProps } from './ChartWrapper.types';

export const ChartWrapper = forwardRef<HTMLDivElement, ChartWrapperProps>(
  ({ title, children }, ref: Ref<HTMLDivElement>) => {
    return (
      <Container ref={ref}>
        <Typography variant="h4">{title}</Typography>
        {children}
      </Container>
    );
  },
);

const Container = styled(Card)({
  flex: 1,
  gap: '16px',
  padding: '24px',
  borderRadius: '8px',
});
