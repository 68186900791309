import { ReactNode, createContext, useContext } from 'react';

import { useCreateDashboardType } from '@core/modules/DashbordModule/types/useCreateDashboard.type.ts';

import { useDashboard } from '@/core/modules/DashbordModule/hooks/useDashboard.tsx';

type DashboardContextProps = ReturnType<typeof useDashboard>;

const DashboardContext = createContext<DashboardContextProps | undefined>(
  undefined,
);

export const DashboardProvider = ({
  children,
  ...props
}: {
  children: ReactNode;
} & useCreateDashboardType) => {
  const dashboard = useDashboard(props);

  return (
    <DashboardContext.Provider value={dashboard}>
      {children}
    </DashboardContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useDashboardContext = (): DashboardContextProps => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error(
      'useDashboardContext must be used within a DashboardProvider',
    );
  }
  return context;
};
