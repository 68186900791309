import { Outlet } from 'react-router-dom';

import { KpiNavbar } from '@kpi/components';

export const KpiLayout = () => {
  return (
    <div style={{ height: '100vh', maxHeight: '100vh' }}>
      <KpiNavbar />

      <Outlet />
    </div>
  );
};
