import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { signOut } from '@aws-amplify/auth';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Fade, IconButton, styled } from '@mui/material';

import { OrganizationModules } from '@/__generated__/graphql';
import {
  AvatarMenu,
  LinkToOrganizationSettings,
  MainPageTile,
} from '@/core/components';
import { useGlobalRedirects } from '@/core/redirects';
import { CustomTooltip, NavbarUserMenu } from '@/core/styles';
import { useUserStore } from '@/stores';
import { useOrganizationStore } from '@/stores/useOrganizationStore/useOrganizationStore';
import { theme } from '@/theme';
import { userIsPermitted } from '@/utils';

export const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyName } = useParams();

  const { eSchemaPath, eFlowPath, kaizenPath, aiPath, kpiPath } =
    useGlobalRedirects();

  const {
    resetStorage: resetOrganizationStore,
    organization,
    organizationModules,
  } = useOrganizationStore();
  const {
    resetStorage: resetUserStore,
    userGroups,
    organizationId,
  } = useUserStore();

  return (
    <MainPageContainer>
      <MainPageNavbar>
        <img
          style={{
            width: 350,
          }}
          src="/assets/ecps-logo.svg"
        />
        <Link
          to=""
          color="inherit"
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          onClick={async () => {
            await signOut();
            resetOrganizationStore();
            resetUserStore();
            navigate('/logged-out');
          }}
        ></Link>
        <NavbarUserMenu>
          {userIsPermitted(
            userGroups,
            organizationId!,
            organization[companyName!],
          ) && (
            <LinkToOrganizationSettings
              organizationName={companyName ? companyName : ''}
            >
              <CustomTooltip
                title={t('organizationSettings.organizationSettings')}
                placement="bottom-start"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
              >
                <IconButton>
                  <SettingsOutlinedIcon
                    sx={{
                      color: theme.palette.grey[900],
                    }}
                  />
                </IconButton>
              </CustomTooltip>
            </LinkToOrganizationSettings>
          )}
          <AvatarMenu />
        </NavbarUserMenu>
      </MainPageNavbar>
      <MainContainer>
        {/*e-schema*/}

        <MainPageTile
          to={eSchemaPath}
          disabled={!organizationModules.includes(OrganizationModules.ESchema)}
        >
          {t('tiles.eschema')}
        </MainPageTile>
        {/*flow*/}
        <MainPageTile
          to={eFlowPath}
          disabled={
            !organizationModules.includes(OrganizationModules.EFlowAnalize)
          }
        >
          {t('tiles.eflowAnalysis')}
        </MainPageTile>
        {/*kaizen*/}
        <MainPageTile
          to={kaizenPath}
          disabled={!organizationModules.includes(OrganizationModules.Kaizen)}
        >
          {t('tiles.kaizen')}
        </MainPageTile>

        {/*AI*/}
        <MainPageTile to={aiPath}>{t('tiles.AI')}</MainPageTile>
        {/*KPI*/}
        <MainPageTile to={kpiPath} target="_blank">
          {t('tiles.KPI')}
        </MainPageTile>
        {/*Audyt*/}
        <MainPageTile target="_blank" to={'https://www.panel.luqam.com/'}>
          {t('tiles.Audyt')}
        </MainPageTile>
        {/*opexity*/}
        <MainPageTile
          to={'https://www.opexity.com/panel/general'}
          target={'_blank'}
        >
          {t('tiles.Opexity')}
        </MainPageTile>
        {/*TiMES*/}
        <MainPageTile
          to={
            'https://digitalfactory-times.atlassian.net/jira/apps/13c0f93a-1fff-4106-a191-60ec1497fda2/578f5b67-6761-4ee2-96f2-093bf6f118ae'
          }
          target="_blank"
        >
          {t('tiles.TiMES')}
        </MainPageTile>
        {/*// Digital tween*/}
        <MainPageTile to={'https://youtu.be/4XxqFDsr4Qs'} target="_blank">
          {t('tiles.DigitalTween')}
        </MainPageTile>
      </MainContainer>
    </MainPageContainer>
  );
};

const MainPageContainer = styled('div')({
  width: '100vw',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '10px',
});

const MainPageNavbar = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '56px',
  padding: '8px 24px 8px 24px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#FFF',
});

const MainContainer = styled('div')({
  marginTop: 80,
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
  justifyContent: 'center',
  flexDirection: 'row',
  maxWidth: '900px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '> *': {
    flex: '0 0 20%',
    maxWidth: '240px',
  },
});
